import { useSuspenseQuery } from "@apollo/client";
import { GET_USER_ACTIVITY_HISTORY } from "api/graphql/user";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHead,
  TableCell,
} from "components/ui/table";
import dayjs from "dayjs";

const sortByDate = (items) =>
  items
    .slice()
    .sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1));

const prepareDate = (createdAt) => {
  const createdDaysAgo = dayjs().diff(createdAt, "days");
  const dateFormat = createdDaysAgo >= 2 ? "MMM DD YYYY" : "MMM DD YYYY, hh:mm";

  return dayjs(createdAt).format(dateFormat);
};

export default function ActivityHistory({ userId }) {
  const { error, data } = useSuspenseQuery(GET_USER_ACTIVITY_HISTORY, {
    variables: {
      userId,
    },
  });

  const sortedActivityHistory = sortByDate(
    data.getUserActivityHistory.items || []
  );

  return (
    <section className="max-h-96 h-96 overflow-y-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Action</TableHead>
            <TableHead>Message</TableHead>
            <TableHead>Date</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedActivityHistory.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.eventName}</TableCell>
              <TableCell>{item.message}</TableCell>
              <TableCell>{prepareDate(item.createdAt)}</TableCell>
            </TableRow>
          ))}
          {sortedActivityHistory.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                This user has no activity history
              </TableCell>
            </TableRow>
          )}
          {error && (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                An error occurred fetching the activity history. Try refreshing
                the page. If error persists, contact Torc Support.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </section>
  );
}
