import React, { useContext, useMemo } from "react";
import { Configure, InstantSearch } from "react-instantsearch-dom";

import {
  JOB_APPLICATION_MATCH_STATUS,
  SEARCH_BY_ATTRIBUTES,
  USER_STATUS,
} from "lookup";
import { MatchContext, SearchContext } from "context/providers";

import ResultsContainer from "./Results";
import { customSearchClient } from "utils/helpers/search";
import { convertMilesToMeters } from "utils/common";

const ALGOLIA_FILTERS = `status:${USER_STATUS.ACTIVE}`;

const SearchContainer = ({ children }) => {
  const {
    searchState,
    setSearchState,
    hideSkipped,
    hideRejected,
    hideMatchedAndApplied,
  } = useContext(SearchContext);
  const { matches } = useContext(MatchContext);

  const filteredObjectIDs = useMemo(() => {
    const skippedMatchesUserIDs = matches
      .filter((match) => match.status === JOB_APPLICATION_MATCH_STATUS.SKIPPED)
      .map((match) => match.userId);

    const rejectedMatchesUserIDs = matches
      .filter(
        (match) =>
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER ||
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER ||
          match.status === JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER
      )
      .map((match) => match.userId);

    const matchedAppliedMatchesUserIDs = matches
      .filter(
        (match) =>
          match.status === JOB_APPLICATION_MATCH_STATUS.MATCHED ||
          match.status === JOB_APPLICATION_MATCH_STATUS.APPLIED
      )
      .map((match) => match.userId);

    const filt = [];
    if (hideSkipped) filt.push(...skippedMatchesUserIDs);
    if (hideRejected) filt.push(...rejectedMatchesUserIDs);
    if (hideMatchedAndApplied) filt.push(...matchedAppliedMatchesUserIDs);

    return filt;
  }, [matches, hideSkipped, hideRejected, hideMatchedAndApplied]);

  const geoLocation = {};

  if (
    searchState.locationFilter?.aroundLatLng &&
    searchState.locationFilter?.aroundRadius &&
    searchState.locationFilter?.cityName
  ) {
    geoLocation.aroundLatLng = searchState.locationFilter?.aroundLatLng;

    geoLocation.aroundRadius = convertMilesToMeters(
      searchState.locationFilter.aroundRadius
    );
  }

  // when we are searching skills by text search with AI skills feature
  // we want to include the optional words in the query behind the scenes
  // so function will return a query string including the optional words
  // we added customSearchQuery and custom input
  const getSearchStateQuery = (searchState) => {
    return [
      searchState?.configProps?.optionalWords || "",
      searchState?.customSearchQuery || "",
    ]
      .filter((e) => e)
      .join(" ");
  };

  return (
    <InstantSearch
      searchClient={customSearchClient}
      indexName={process.env.REACT_APP_INDEX_NAME}
      onSearchStateChange={setSearchState}
      searchState={searchState}
    >
      <ResultsContainer>{children}</ResultsContainer>

      <Configure
        filters={`${ALGOLIA_FILTERS}${
          !searchState.locationFilter?.aroundRadius
            ? searchState.locationFilterStr
            : ""
        }${searchState.skillsStrFilter}${searchState.placementFilterStr}`}
        facets={["objectID"]}
        facetsExcludes={{
          objectID: filteredObjectIDs,
        }}
        restrictSearchableAttributes={SEARCH_BY_ATTRIBUTES[0].attributes}
        attributesToHighlight={SEARCH_BY_ATTRIBUTES[0].attributes}
        {...geoLocation}
        {...searchState.configProps}
        query={getSearchStateQuery(searchState)}
      />
    </InstantSearch>
  );
};

export default SearchContainer;
