import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";
import Chip from "./Chip";
import SearchLocationsWidget from "components/SearchLocationWidget";
import { LOCATION_FILTER_ACTIONS } from "lookup";
import { JobsContext, SearchContext } from "context/providers";

const SearchLocations = ({ handleLocationChange }) => {
  return (
    <div className="mb-2">
      <SearchLocationsWidget
        locationChange={(location) => {
          handleLocationChange(LOCATION_FILTER_ACTIONS.ADD, { ...location });
        }}
      />
    </div>
  );
};

const SpecificLocationRefinement = () => {
  const { locationFilter, locationFilterChange, setProximitySearch } =
    useContext(SearchContext);

  const { jobOpp } = useContext(JobsContext);

  const [chips, setChips] = useState([]);
  const [radius, setRadius] = useState();

  const handleLocationChange = (action, location) => {
    locationFilterChange(action, location);
  };

  useEffect(() => {
    if (
      radius &&
      locationFilter.cityName &&
      radius !== locationFilter?.aroundRadius
    ) {
      const timeout = setTimeout(() => {
        setProximitySearch(radius);
      }, 400);

      return () => clearTimeout(timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [radius]);

  // job has been set we need to set the default aroundRadius value from the context location filter
  useEffect(() => {
    if (
      jobOpp?.id &&
      typeof locationFilter?.aroundRadius === "number" &&
      radius !== locationFilter?.aroundRadius
    ) {
      setRadius(locationFilter?.aroundRadius);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOpp?.id, locationFilter?.aroundRadius]);

  function getChips(location) {
    const activeLocations = [];

    if (location.countryName) {
      activeLocations.push({
        label: `Country: ${location.countryName}`,
        attribute: "countryName",
      });
    }

    if (location.stateName) {
      activeLocations.push({
        label: `State: ${location.stateName}`,
        attribute: "stateName",
      });
    }

    if (location.cityName) {
      activeLocations.push({
        label: `City: ${location.cityName}`,
        attribute: "cityName",
      });
    }

    return activeLocations;
  }

  useEffect(() => {
    const locationChips = getChips(locationFilter);
    setChips([...locationChips]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationFilter]);

  const handleDeleteAll = () => {
    handleLocationChange(LOCATION_FILTER_ACTIONS.REMOVE, "countryName");
  };

  return (
    <>
      {!!handleLocationChange && (
        <>
          <SearchLocations
            handleLocationChange={handleLocationChange}
            setProximitySearch={setProximitySearch}
          />
        </>
      )}
      <div
        className={classNames("flex flex-wrap gap-1", {
          "my-4": chips?.length > 0,
        })}
      >
        <br />
        {chips.map(({ label, attribute }) => (
          <Chip
            key={label}
            value={label}
            onClose={() => {
              handleLocationChange(LOCATION_FILTER_ACTIONS.REMOVE, attribute);
            }}
            isActive
            className="!bg-sky-600"
          />
        ))}

        {chips.length > 1 && (
          <Chip
            value="Clear all"
            onClick={handleDeleteAll}
            className="!bg-sky-600"
            isActive
          />
        )}
        <br />
      </div>

      {locationFilter.cityName && (
        <div className="flex">
          <input
            id="radius"
            type="checkbox"
            checked={typeof locationFilter.aroundRadius === "number"}
            onChange={() => {
              if (typeof locationFilter.aroundRadius === "number") {
                setRadius(null);
                setProximitySearch(null);
                return;
              }

              setRadius(50);
              setProximitySearch(50);
            }}
          />
          <label className="ml-3" htmlFor="radius">
            Expand radius
          </label>
        </div>
      )}
      {locationFilter.cityName &&
        typeof locationFilter.aroundRadius === "number" && (
          <div className="flex pl-7 items-center">
            <label htmlFor="radius">Miles</label>
            <input
              style={{
                maxWidth: "100px",
              }}
              id="radius"
              type="text"
              placeholder="radius"
              className="border-2 border-sky-600 rounded-md p-1 max-w-20 ml-2"
              value={radius}
              onInput={({ target }) => {
                const inputValue = target.value.replace(/\D/g, "");

                if (inputValue !== "" && Number(inputValue) > 0) {
                  setRadius(Number(inputValue));
                } else {
                  setRadius("");
                }
              }}
            />
          </div>
        )}
    </>
  );
};

export default SpecificLocationRefinement;
