import dayjs from "dayjs";

export const JOB_OPPORTUNITY_STATUSES = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  FULFILLED: "FULFILLED",
  DELETED: "DELETED",
  DRAFT: "DRAFT",
  PENDINGAPPROVAL: "PENDINGAPPROVAL",
};

export const JOB_OPPORTUNITY_TIME_COMMITMENT = {
  FULLTIME: "FULLTIME",
  PARTTIME: "PARTTIME",
};

export const SOCIAL_LINK_TYPES = {
  BADGR: "BADGR",
  CALENDAR: "CALENDAR",
  DEV: "DEV",
  FACEBOOK: "FACEBOOK",
  GITHUB: "GITHUB",
  HASHNODE: "HASHNODE",
  INSTAGRAM: "INSTAGRAM",
  LINKEDIN: "LINKEDIN",
  PORTFOLIO: "PORTFOLIO",
  STACKOVERFLOW: "STACKOVERFLOW",
  TWITTER: "TWITTER",
};

export const CUSTOM_LINK_TYPES = {
  EXTERNALLINK: "EXTERNALLINK",
  GENERAL: "GENERAL",
  RESULT: "RESULT",
  RESUME: "RESUME",
  OTHER: "OTHER",
};

export const JOB_APPLICATION_MATCH_STATUS_FORMATTED = {
  ACCEPTED: "Accepted",
  APPLIED: "Applied",
  MATCHED: "Matched",
  PASSEDON: "Passed On",
  REJECTEDBYCUSTOMER: "Rejected (Customer)",
  REJECTEDBYMATCHER: "Rejected (Matcher)",
  REJECTEDBYMEMBER: "Rejected (Member)",
  SHORTLISTED: "Shortlisted",
  MOREINFO: "More Info",
  INTERESTED: "Interested",
  INTERESTEDFASTTRACK: "Interested Fasttrack",
};

export const JOB_APPLICATION_MATCH_STATUS = {
  ACCEPTED: "ACCEPTED",
  APPLIED: "APPLIED",
  MATCHED: "MATCHED",
  PASSEDON: "PASSEDON",
  REJECTEDBYCUSTOMER: "REJECTEDBYCUSTOMER",
  REJECTEDBYMATCHER: "REJECTEDBYMATCHER",
  REJECTEDBYMEMBER: "REJECTEDBYMEMBER",
  SHORTLISTED: "SHORTLISTED",
  PRESHORTLISTED: "PRESHORTLISTED",
  MOREINFO: "MOREINFO",
  SKIPPED: "SKIPPED",
  DELETED: "DELETED",
  INTERESTED: "INTERESTED",
  INTERESTEDFASTTRACK: "INTERESTEDFASTTRACK",
};

export const JOB_APPLICATION_MATCH_SUB_STATUS = {
  FINALIST: "FINALIST",
  NULL: null,
};
export const JOB_APPLICATION_MATCH_SUB_STATUS_FORMATTED = {
  FINALIST: "Only Finalists",
  CALIBRATION: "Only Calibrations",
};

export const JOB_APPLICATION_MATCH_RATINGS = {
  VERYUNDESIRABLE: "VERYUNDESIRABLE",
  UNDESIRABLE: "UNDESIRABLE",
  NEUTRAL: "NEUTRAL",
  DESIRABLE: "DESIRABLE",
  VERYDESIRABLE: "VERYDESIRABLE",
};

export const JOB_APPLICATION_MATCH_RATINGS_FORMATTED = {
  VERYUNDESIRABLE: "Very Undesirable",
  UNDESIRABLE: "Undesirable",
  NEUTRAL: "Neutral",
  DESIRABLE: "Desirable",
  VERYDESIRABLE: "Very Desirable",
};

export const MATCH_RATING_REASONS_FORMATTED = {
  RATE: "Rate",
  LOCATION: "Location",
  TIMEZONE: "Timezone",
  INSUFFICIENTSKILLS: "Insufficient Skills",
  INSUFFICIENTEXPERIENCE: "Insufficient Experience",
  EXPERIENCENOTRELEVANT: "Experience Not Relevant",
};

export const CUSTOM_LINK_TITLES_BY_TYPE = {
  [CUSTOM_LINK_TYPES.EXTERNALLINK]: "External link",
  [CUSTOM_LINK_TYPES.GENERAL]: "General link",
  [CUSTOM_LINK_TYPES.OTHER]: "Other link",
  [CUSTOM_LINK_TYPES.RESULT]: "Result link",
  [CUSTOM_LINK_TYPES.RESUME]: "Resume link",
};

export const USER_TYPES = {
  FREELANCER: "FREELANCER",
  CUSTOMER: "CUSTOMER",
  ADMIN: "ADMIN",
};

export const CUSTOM_PANEL_DEFAULT_OPERATOR = {
  "skills.name": "and",
  activeJobTypeTitles: "or",
  "educationRecords.degreeType": "and",
  "knownLanguages.language": "and",
  "assessments.testName": "and",
};

export const USER_STATUS = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  SUSPENDED: "SUSPENDED",
};

export const DEFAULT_RATE_RANGE = { min: 0, max: 200 };
export const DEFAULT_SALARY_RANGE = { min: 0, max: 416000 };

export const GEOGRAPHICAL_REGIONS = [
  {
    regionName: "North America",
    countryNames: ["United States", "Canada", "Mexico"],
  },
  {
    regionName: "Asia",
    countryNames: ["India", "China", "Japan"],
  },
  {
    regionName: "Europe",
    countryNames: ["France", "Germany", "Italy"],
  },
];

export const APPLICATION_TAB_NAMES = {
  TOPCANDIDATES: "Top Candidates",
  MATCHED: "Matched",
  CALIBRATION: "Calibration",
  SHORTLISTED: "Shortlisted",
  SKIPPED: "Skipped",
  BESTFIT: "Best Fit",
  IDEAL: "Ideal Fit",
};

export const SEARCH_BY_ATTRIBUTES = [
  {
    title: "Default",
    attributes: [
      "skills.name",
      "skills_str",
      "given_name",
      "family_name",
      "email",
      "username",
      "identity_username",
      "company",
      "bio",
      "referralCode",
      "referrerCode",
      "tagline",
      "location",
      "activeJobTypeTitles",
      "id",
      "knownLanguages_str",
      "careers.title",
      "careers.stack",
      "careers.description",
      "projectsCaseStudies.title",
      "projectsCaseStudies.stack",
      "projectsCaseStudies.description",
      "parsedResume.content",
      "resumeWorkHistory.title",
      "resumeWorkHistory.stack",
      "resumeWorkHistory.description",
    ],
  },
];

export const NOTE_TYPES = {
  CALIBRATION: "CALIBRATION",
  GENERAL: "GENERAL",
  JOBFEEDBACK: "JOBFEEDBACK",
};

export const NOTE_TYPES_MAPPED_ICON = {
  JOBFEEDBACK: { icon: "jobFeedback", label: "Job Feedback" },
};

export const LANGUAGE_KEY_NAMES = {
  language: "knownLanguages.language",
  level: "knownLanguages.level",
};

export const SKILL_KEY_NAMES = {
  name: "skills.name",
};

export const SKILL_STR_FORMATTED_OPTIONS = [
  { label: "5+y", value: "high" },
  { label: "2-5y", value: "medium" },
  { label: "0-2y", value: "low" },
];

// Not multiplying by 1000 since the field was stored as Int
export const ENGAGEMENT_STATUS_RANGE_DATES_ITEMS = [
  {
    label: "Free (Currently Not Engaged)",
    start: null, // There is some custom filtering for this field connector needs a defined value
  },
  {
    label: "Not Free (Currently Engaged)",
    start: dayjs().startOf("day").unix(),
  },
  {
    label: "Engagement Ending < 2 weeks",
    start: dayjs().startOf("day").unix(),
    end: dayjs().add(2, "week").endOf("day").unix(),
  },
  {
    label: "Engagement Ending < 4 weeks",
    start: dayjs().startOf("day").unix(),
    end: dayjs().add(4, "week").endOf("day").unix(),
  },
];

export const PLACEMENT_FILTERS = {
  notHasActivePlacements: " AND (NOT hasActivePlacements:true)",
};

// Not multiplying by 1000 since the field was stored as Int
export const PREVIOUS_ENGAGEMENT_RANGE_DATES_ITEMS = [
  {
    label: "Previously Placed",
    end: dayjs().startOf("day").unix(),
  },
];

export const LOCATION_FILTER_ACTIONS = {
  ADD: "ADD",
  REMOVE: "REMOVE",
};

export const MEMBER_REJECTION_REASONS = {
  LACKEXPERIENCE: "Do not have required technology skill level",
  STACKNOTDESIRED: "Prefer to work in other technology stacks",
  LOCATIONNOTFEASIBLE: "Unable to work required time zone",
  NOTAVAILABLE: "Not available for new jobs",
  RATELOW: "Rate is too low",
  OTHER: "Other",
};

export const MATCH_REJECTION_TEXT = {
  ENGLISHLANGDEFICIT: "Poor verbal or written English language skills",
  INADEQUATESKILLLVL: "Inadequate skill level",
  LACKEXPERIENCE: "Lack of relevant experience",
  LACKKNOWLEDGE: "Unable to clearly explain concepts or approaches",
  LOCATIONNOTFEASIBLE: "Location or time zone overlap is not a fit",
  MISSINGSKILL: "Missing one or more required skills",
  OTHER: "Other",
  RATEHIGH: "Rate is too high",
};

export const EDUCATION_DEGREE_TYPES = {
  ASSOCIATES_OR_EQUIVALENT: "ASSOCIATES_OR_EQUIVALENT",
  BACHELORS_OR_EQUIVALENT: "BACHELORS_OR_EQUIVALENT",
  MASTERS_OR_EQUIVALENT: "MASTERS_OR_EQUIVALENT",
  DOCTORAL_OR_EQUIVALENT: "DOCTORAL_OR_EQUIVALENT",
  UPPER_SECONDARY_EDUCATION: "UPPER_SECONDARY_EDUCATION",
  DEGREE_TYPE_UNSPECIFIED: "DEGREE_TYPE_UNSPECIFIED",
};

export const EDUCATION_DEGREE_TYPES_TEXT = {
  ASSOCIATES_OR_EQUIVALENT: "Associates",
  BACHELORS_OR_EQUIVALENT: "Bachelors",
  MASTERS_OR_EQUIVALENT: "Masters",
  DOCTORAL_OR_EQUIVALENT: "PhD",
  UPPER_SECONDARY_EDUCATION: "High School",
  DEGREE_TYPE_UNSPECIFIED: "Unspecified",
};

export const REJECTION_REASONS = [
  {
    title: MATCH_REJECTION_TEXT.ENGLISHLANGDEFICIT,
    value: "ENGLISHLANGDEFICIT",
  },
  {
    title: MATCH_REJECTION_TEXT.INADEQUATESKILLLVL,
    value: "INADEQUATESKILLLVL",
  },
  {
    title: MATCH_REJECTION_TEXT.LACKEXPERIENCE,
    value: "LACKEXPERIENCE",
  },
  {
    title: MATCH_REJECTION_TEXT.LACKKNOWLEDGE,
    value: "LACKKNOWLEDGE",
  },
  {
    title: MATCH_REJECTION_TEXT.LOCATIONNOTFEASIBLE,
    value: "LOCATIONNOTFEASIBLE",
  },
  {
    title: MATCH_REJECTION_TEXT.MISSINGSKILL,
    value: "MISSINGSKILL",
  },
  {
    title: MATCH_REJECTION_TEXT.OTHER,
    value: "OTHER",
  },
  {
    title: MATCH_REJECTION_TEXT.RATEHIGH,
    value: "RATEHIGH",
  },
];

export const PROJECT_CASE_STUDY_WORK_TYPES_TEXT = {
  PROJECT: "Project",
  CASESTUDY: "Case Study",
};

export const JOB_TYPES_TEXT = {
  FULLTIME: "Full Time",
  PARTTIME: "Part Time",
  SELFEMPLOYED: "Self employed",
  CONTRACT: "Contract",
};

export const IDEAL_FIT_SEARCH_TYPES = {
  ADVANCED: "Advanced",
  BASIC: "Basic",
};

export const EMPLOYMENT_TYPES = {
  CONTRACT: "Contract",
  PERMPLACEMENT: "Permanent Placement",
  CONTRACTTOPERM: "Contract to Permanent Placement",
  SOLUTION: "Solution Position",
  IC: "Independent Contractor (IC)",
};
