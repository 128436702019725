import classNames from "classnames";
import { ReactComponent as Upload } from "images/upload_icon.svg";

const UploadButton = ({
  onClick,
  loadingWidget,
  uploadInProgress,
  label,
  className,
}) => {
  return (
    <button
      className={classNames(
        "text-gray-800 flex items-center mb-1 pl-2 gap-x-1",
        className
      )}
      type="button"
      disabled={loadingWidget}
      loading={uploadInProgress}
      onClick={onClick}
    >
      <Upload />
      {loadingWidget ? "Loading..." : uploadInProgress ? "Uploading..." : label}
    </button>
  );
};

export default UploadButton;
