import SelectDropDown from "components/Applications/components/SelectDropDown";
import {
  JOB_APPLICATION_MATCH_STATUS,
  MEMBER_REJECTION_REASONS,
  REJECTION_REASONS,
} from "lookup";
import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import Button from "components/Button";
import TextArea from "components/base/TextArea";
import { MatchContext } from "context/providers";

const TYPES_OF_REJECTION = [
  {
    title: "Please select an option...",
    value: "",
  },
  {
    title: "Customer",
    value: JOB_APPLICATION_MATCH_STATUS.REJECTEDBYCUSTOMER,
  },
  {
    title: "Member",
    value: JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER,
  },
  {
    title: "Matcher",
    value: JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER,
  },
];

const options = [
  ...Object.values(REJECTION_REASONS).map(({ title, value }) => ({
    value,
    label: title,
  })),
];

const memberOptions = [
  ...Object.keys(MEMBER_REJECTION_REASONS).map((key) => ({
    value: key,
    label: MEMBER_REJECTION_REASONS[key],
  })),
];

const showLabel = (text, isMandatory) => {
  return (
    <label className="font-bold text-base">
      {text}
      {isMandatory ? <span className="text-red-600"> *</span> : null}
    </label>
  );
};

const RejectionForm = ({
  applicationId,
  jobOpportunityId,
  isVisible,
  onClose,
}) => {
  const [feedback, setFeedback] = useState("");
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [typeOfRejection, setTypeOfRejection] = useState(TYPES_OF_REJECTION[0]);
  const { updateMatch } = useContext(MatchContext);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const submitEnabled = !!selectedReasons?.length;

  useEffect(() => {
    if (isVisible) {
      resetForm();
    }
  }, [isVisible]);

  const resetForm = () => {
    setSelectedReasons([]);
    setFeedback("");
    setTypeOfRejection(TYPES_OF_REJECTION[0]);
  };

  const handleSelectTypeOfRejection = (el) => {
    setSelectedReasons([]);
    setTypeOfRejection(el);
  };

  const handleChangeReasons = (selectedOptions) => {
    setSelectedReasons(selectedOptions);
  };

  const handleFeedbackChange = (e) => {
    setFeedback(e.target.value);
  };

  const handleSubmit = async (e) => {
    setIsSubmiting(true);
    const reasonForRejection = selectedReasons.map((el) => el.value);
    const data = {
      status: typeOfRejection.value,
      reasonForRejection,
      rejectionByCustomer: feedback.trim(),
    };
    await updateMatch(applicationId, jobOpportunityId, data);
    onClose();
    setIsSubmiting(false);
  };

  return (
    <div
      style={{
        minHeight: "500px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: "4px",
        }}
      >
        {showLabel("Rejected by", true)}
        <SelectDropDown
          options={TYPES_OF_REJECTION}
          selectedOption={typeOfRejection}
          action={handleSelectTypeOfRejection}
        />
      </div>

      <div>
        {showLabel("Reasons for Rejection", true)}
        <div className="block mt-2">
          {typeOfRejection.value ===
          JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMEMBER ? (
            <Select
              value={selectedReasons}
              options={memberOptions}
              isMulti
              className="block mt-2 border rounded border-black w-full p-0 md:p-1"
              onChange={handleChangeReasons}
            />
          ) : (
            <Select
              value={selectedReasons}
              options={options}
              isMulti
              className="block mt-2 border rounded border-black w-full p-0 md:p-1"
              onChange={handleChangeReasons}
              isDisabled={!typeOfRejection?.value}
            />
          )}
        </div>
      </div>
      {showLabel("Optional feedback")}
      <TextArea
        className="-mt-4"
        value={feedback}
        onChange={(e) => handleFeedbackChange(e)}
      />
      <div className="flex">
        <Button
          onClick={handleSubmit}
          isReadOnly={!submitEnabled || isSubmiting}
        >
          {isSubmiting ? "Submiting..." : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default RejectionForm;
