import * as alert from "context/constants/alert";
import * as jobs from "context/constants/jobs";
import * as search from "context/constants/search";
import * as notes from "context/constants/notes";
import * as auth from "context/constants/auth";
import * as match from "context/constants/match";
import * as modal from "context/constants/modal";
import * as common from "context/constants/common";

const contextConstants = {
  alert,
  jobs,
  search,
  notes,
  auth,
  match,
  modal,
  common,
};

export { contextConstants };
