import produce from "immer";

import { USER_TYPES } from "lookup";
import { contextConstants } from "context/constants";

export const initialState = {
  searchState: {
    refinementList: {
      userType: [USER_TYPES.FREELANCER],
      "skills.name": [],
    },
    skillsStr: [],
    skillsStrFilter: "",
    placementFilterStr: "",
    locationFilter: {},
    locationFilterStr: "",
    configProps: {},
    customSearchQuery: "",
  },
  hitsMatchAnalysis: {},
  hits: [],
  hideSkipped: true,
  hideRejected: true,
  hideMatchedAndApplied: true,
  operators: {
    "skills.name": "and",
    activeJobTypeTitles: "and",
    "educationRecords.degreeType": "and",
    "knownLanguages.language": "and",
    "assessments.testName": "and",
  },
  disjunctiveFacets: [],
  AISkillSearchConfig: {
    enabled: false,
    loading: false,
    data: {},
  },
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.search.SEARCH_STATE_CHANGED: {
      draft.searchState = payload.searchState;
      break;
    }

    case contextConstants.search.HITS_LOADED: {
      draft.hits = payload.hits;
      break;
    }

    case contextConstants.search.DISJUNCTIVE_FACETS_CHANGED: {
      draft.disjunctiveFacets = payload.disjunctiveFacets;
      break;
    }

    case contextConstants.search.OPERATOR_UPDATED: {
      draft.operators = payload.operators;
      break;
    }

    case contextConstants.search.HIDE_SKIPPED_CHANGED: {
      draft.hideSkipped = payload.hideSkipped;
      break;
    }

    case contextConstants.search.HITS_MATCH_ANALYSIS_CHANGED: {
      draft.hitsMatchAnalysis[payload.key] = payload.value;
      break;
    }

    case contextConstants.search.HITS_MATCH_ANALYSIS_REMOVED: {
      delete draft.hitsMatchAnalysis[payload.key];
      break;
    }

    case contextConstants.search.HITS_MATCH_ANALYSIS_CLEARED: {
      draft.hitsMatchAnalysis = {};
      break;
    }

    case contextConstants.search.HIDE_REJECTED_CHANGED: {
      draft.hideRejected = payload.hideRejected;
      break;
    }

    case contextConstants.search.HIDE_MATCHED_CHANGED: {
      draft.hideMatchedAndApplied = payload.hideMatchedAndApplied;
      break;
    }

    case contextConstants.search.GEOGRAPHICAL_REGION_CHANGED: {
      draft.geographicalRegions = payload.geographicalRegions;
      break;
    }

    case contextConstants.search.LOCATION_FILTER_CHANGED: {
      draft.searchState.locationFilter = payload.locationFilter;
      draft.searchState.locationFilterStr = payload.locationFilterStr;
      break;
    }

    case contextConstants.search.COUNTRY_REFINEMENT_CHANGED: {
      draft.searchState.refinementList["location.countryName"] =
        payload.countries;
      break;
    }

    case contextConstants.search.AI_SKILL_SEARCH_CONFIG_CHANGED: {
      draft.AISkillSearchConfig = payload.AISkillSearchConfig;
      break;
    }

    default:
      break;
  }
});
