import React from "react";

import SvgButton from "components/base/SvgButton";
import MatchAnalysis from "components/MatchAnalysis";
import Modal from "components/Modals";

const MatchAnalysisModal = ({ onClose, user, subStatus }) => {
  return (
    <Modal
      isVisible
      title="Finalist"
      subTitle="Review Match Summary"
      onClose={onClose}
      className="max-h-[90vh] border-2 overflow-x-hidden xl:!w-7/12 md:!w-10/12 !w-[95%]"
    >
      <div className="absolute top-2 !right-3 w-[25px] h-[25px] opacity-50 cursor-pointer hover:opacity-100 z-50">
        <SvgButton icon="cross" onClick={onClose} />
      </div>

      <MatchAnalysis
        match={user}
        matchAnalysis={{
          customerSummary:
            user?.customerSummary ||
            user?.localCustomerSummary ||
            user?.matchAnalysis?.customerSummary,
          analysis: {
            score:
              user?.analysis?.score ??
              user?.localAnalysis?.score ??
              user?.matchAnalysis?.score,
            report:
              user?.analysis?.report ||
              user?.localAnalysis?.report ||
              user?.matchAnalysis?.report,
          },
        }}
        hideInternalUse
        argsToUpdate={{ subStatus }}
        updated={() => onClose()}
        actionButtonType="finalist"
        actionButtonIcon="checkBadge"
        hideEdit
      />
    </Modal>
  );
};

export default MatchAnalysisModal;
