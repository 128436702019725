import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { Auth } from "aws-amplify";
import awsConfig from "./aws-exports";

const httpLink = createHttpLink({
  uri: awsConfig.aws_appsync_graphqlEndpoint,
});

const authLink = setContext(async (_, { headers }) => {
  const response = await Auth.currentSession();
  const jwt = response.getAccessToken().getJwtToken();

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${jwt}`,
    },
  };
});

export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
