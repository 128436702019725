import { useContext, useEffect, useState } from "react";

import { SearchContext } from "context/providers";
import { getPurifiedParsedMarkDown } from "utils/markdown";

const ParsedResume = ({
  hit,
  parsedResumeHasHighLight,
  resumeHasLocalHighLight,
  localHighlightBasedOnQuery,
}) => {
  const [viewAll, setViewAll] = useState(true);

  useEffect(() => {
    setViewAll(!parsedResumeHasHighLight && !resumeHasLocalHighLight);
  }, [hit, parsedResumeHasHighLight, resumeHasLocalHighLight]);

  const { searchState } = useContext(SearchContext);

  let parsedResume = hit.parsedResume || [];

  const searchStateQuery =
    searchState?.configure?.query || searchState?.customSearchQuery;

  if (!viewAll) {
    if (parsedResumeHasHighLight) {
      parsedResume =
        hit._highlightResult?.parsedResume
          .map((hc) => {
            let value;
            if (localHighlightBasedOnQuery && searchStateQuery && hc.content) {
              value = localHighlightBasedOnQuery(
                hc.content.value,
                searchStateQuery
              );
            }

            if (
              hc.content &&
              (hc.content?.matchLevel !== "none" || /<\/?mark>/g.test(value))
            ) {
              return {
                content: value || hc.content.value,
              };
            }

            return null;
          })
          ?.filter((e) => e) || [];
    }

    if (resumeHasLocalHighLight) {
      parsedResume = hit.parsedResume?.filter(
        ({ localHighlighted }) =>
          localHighlighted && /<\/?mark>/g.test(localHighlighted)
      );
    }
  }

  return (
    <div className="flex flex-col gap-2 pl-4">
      {(parsedResumeHasHighLight || resumeHasLocalHighLight) &&
        hit.parsedResume?.length > 1 && (
          <p
            className="cursor-pointer text-blue-600 underline"
            onClick={() => setViewAll((prev) => !prev)}
          >
            View {!viewAll ? "All Content" : "Only High Lights"}
          </p>
        )}

      {parsedResume.map((chunkResume, index) => {
        return (
          <p
            key={`${hit.id}_parsedResume_${index}`}
            className="prose break-words"
            dangerouslySetInnerHTML={{
              __html: getPurifiedParsedMarkDown(
                (chunkResume.localHighlighted || chunkResume.content)
                  .replace(/<ais-highlight-0000000000>/g, "<mark>")
                  .replace(/<\/ais-highlight-0000000000>/g, "</mark>")
              ),
            }}
          />
        );
      })}
    </div>
  );
};

export default ParsedResume;
