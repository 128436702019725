import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { capitalize } from "lodash";
import { nanoid } from "nanoid";
import dayjs from "dayjs";
import MatchAnalysis from "components/MatchAnalysis";
import { Dialog, DialogTrigger } from "@radix-ui/react-dialog";
import UserInfoModalV2 from "containers/ModalContainer/modals/UserInfoModalv2";

import {
  getIconForSocialType,
  getSocialProfileUrl,
  mapSkills,
} from "components/UserCardList/helpers/userCard";

import {
  APPLICATION_TAB_NAMES,
  CUSTOM_LINK_TITLES_BY_TYPE,
  CUSTOM_LINK_TYPES,
  EDUCATION_DEGREE_TYPES_TEXT,
  JOB_TYPES_TEXT,
  MATCH_RATING_REASONS_FORMATTED,
  PROJECT_CASE_STUDY_WORK_TYPES_TEXT,
} from "lookup";

import {
  JobsContext,
  ModalContext,
  NotesContext,
  SearchContext,
} from "context/providers";

import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHead,
  TableCell,
} from "components/ui/table";
import Collapsible from "components/Collapsible";
import SvgIcon from "components/base/SvgIcon";
import { Chip } from "components/SearchFilters/components";
import Ratings from "./Ratings";
import Note from "components/Notes/components/Note";
import MatchedJobs from "./MatchedJobs";
import api from "apiSingleton";
import { placementIsActive } from "utils/validation";
import { customComparator } from "utils/common";
import TextContainer from "components/TextContainer";
import ParsedResume from "./ParsedResume";
import { getPurifiedParsedMarkDown } from "utils/markdown";
import { getUserName } from "utils/helpers/users";

const SKILL_EXP_INFO = {
  low: { className: "!text-red-600", info: "0-2y" },
  medium: { className: "!text-amber-600", info: "2-5y" },
  high: { className: "!text-green-500", info: "5+y" },
};

const CollapsibleSection = ({ activeTabName, hit, allowLocalHightLight }) => {
  const [seeAssessments, setSeeAssessments] = useState(false);
  const [seeCalibrationNotes, setSeeCalibrationNotes] = useState(false);
  const [isLoadingPlacements, setIsLoadingPlacements] = useState(true);

  const { matchedNotes, match: initMatchNotes } = useContext(NotesContext);

  const { jobOpp, jobs } = useContext(JobsContext);
  const { showModal } = useContext(ModalContext);
  const { searchState, hitsMatchAnalysis } = useContext(SearchContext);

  const searchStateQuery =
    searchState?.configure?.query || searchState?.customSearchQuery || "";

  useEffect(() => {
    (async () => {
      if (
        activeTabName === APPLICATION_TAB_NAMES.CALIBRATION &&
        !matchedNotes[hit.match?.applicationId]
      ) {
        await initMatchNotes(
          { id: hit.match?.applicationId },
          { id: jobOpp.id }
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hit.match?.applicationId]);

  function localHighlightBasedOnQuery(text, query, fullWords = true) {
    if (!query?.trim() || !allowLocalHightLight || !text) {
      return text || "";
    }

    // Match words inside quotes or single words outside of quotes
    let words = query.match(/"[^"]+"|[^,\s|;:/\\\-&_+]+/g) || []; // Match quoted phrases or single words

    words = words
      .map((word) => word.replace(/"/g, "")) // Remove double quotes
      .filter((word) => word.trim() !== ",") // Exclude the ',' symbol
      .map((word) => word.replace(/[.*#+?^${}()|[\]\\]/g, "\\$&"))
      .filter((e) => e); // Escape special regex characters

    // case insensitive
    const fullWordsType = fullWords
      ? `(\\b(${words.join("|")})\\b)`
      : `(${words.join("|")})`;
    const regex = new RegExp(fullWordsType, "gi");

    // Replace matched terms with highlighted versions using <mark>
    return text.replace(regex, "<mark>$1</mark>");
  }

  const matchNotes = useMemo(() => {
    return (
      matchedNotes[hit.match?.applicationId]
        ?.filter((n) => n.noteType === "CALIBRATION")
        .sort((a, b) =>
          dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
        ) || []
    );
  }, [matchedNotes, hit.match?.applicationId]);

  const mappedSkills = useMemo(
    () =>
      mapSkills(
        jobOpp.skills || [],
        hit.skills || [],
        hit.resumeWorkHistory
          ?.map((e) => e.skills)
          .filter((e) => e)
          .flat() || []
      ),
    [jobOpp.skills, hit.skills, hit.resumeWorkHistory]
  );

  const mappedOptionalSkills = useMemo(
    () =>
      mapSkills(
        jobOpp.optionalSkills || [],
        hit.skills || [],
        hit.resumeWorkHistory
          ?.map((e) => e.skills)
          .filter((e) => e)
          .flat() || []
      ),
    [jobOpp.optionalSkills, hit.skills, hit.resumeWorkHistory]
  );

  const { rating, reasonsForRating } = useMemo(
    () => ({
      rating: hit.match?.rating || null,
      reasonsForRating: hit.match?.reasonsForRating || [],
    }),
    [hit]
  );

  const assessments = useMemo(() => {
    return hit.assessments || [];
  }, [hit]);

  const educationRecords = useMemo(() => {
    return (hit.educationRecords || []).sort((a, b) => {
      return new Date(b.endDate) - new Date(a.endDate);
    });
  }, [hit]);

  const projectsCaseStudies = useMemo(() => {
    return (hit.projectsCaseStudies || []).sort(customComparator);
  }, [hit]);

  const careers = useMemo(() => {
    return (hit.careers || []).sort(customComparator);
  }, [hit]);

  const resumeWorkHistory = useMemo(() => {
    return (hit.resumeWorkHistory || []).sort(customComparator);
  }, [hit]);

  const prepareScore = (item) => {
    return `${item.finalScore || 0} (Code - ${item.codeScore || 0}, MCQ - ${
      item.multipleChoiceScore || 0
    })`;
  };

  const renderCalibrationNotes = (items) => {
    return items.map((note, index) => {
      return (
        <div key={note.id} className="text-sm">
          {index > 0 && <hr className="border-t w-full" />}

          <Note key={note.createdAt} creator={note.creator} {...note} />
        </div>
      );
    });
  };

  const JobTitle = ({ jobId }) => {
    const [jobTitle, setJobTitle] = useState("Loading...");

    useEffect(() => {
      (async () => {
        const job = jobs?.find(({ id }) => id === jobId);
        if (job?.title) {
          setJobTitle(job.title);
          return;
        }
        const { data } = await api.jobs.get({ id: jobId });
        setJobTitle(data.getJobOpportunity?.title || "-");
      })();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return jobTitle === "-" || jobTitle === "Loading..." ? (
      <p>{jobTitle}</p>
    ) : (
      <a target="_blank" rel="noreferrer" href={`/#/jobOpps/${jobId}/details`}>
        <p className="font-bold text-base text-sky-500 underline">{jobTitle}</p>
      </a>
    );
  };

  const renderPlacements = (items) => {
    return items.map((placement, index) => {
      return (
        <div
          className="flex flex-col font-rubik-medium text-sm w-full"
          key={placement.id}
        >
          {index > 0 && <hr className="border-t my-1 w-full" />}

          <div className="flex space-x-2.5">
            <span className="flex-1">{placement.companyName ?? "-"}</span>

            <span className="flex-1">
              Start Date:{" "}
              {dayjs(placement.startDate)
                .format("MM DD YYYY")
                .split(" ")
                .join("/")}
            </span>

            <span
              className="flex-1"
              title={placement.actualEndDate ? "Actual End Date" : "End Date"}
            >
              End Date:{" "}
              {placement.actualEndDate
                ? dayjs(placement.actualEndDate)
                    .format("MM DD YYYY")
                    .split(" ")
                    .join("/")
                : dayjs(placement.endDate)
                    .format("MM DD YYYY")
                    .split(" ")
                    .join("/")}
              {placement.endReason && (
                <>
                  <br />
                  <span>End Reason: {placement.endReason}</span>
                </>
              )}
            </span>

            <span
              className={classNames({
                "flex-1 text-center": !placement.jobOpportunityId,
              })}
            >
              {placementIsActive(placement.actualEndDate ?? placement.endDate)
                ? "Currently Engaged"
                : "Previous Engagement"}
            </span>

            {placement.jobOpportunityId && (
              <span className="flex-1">
                <JobTitle jobId={placement.jobOpportunityId} />
              </span>
            )}
          </div>
        </div>
      );
    });
  };

  const renderAssessments = (items) => {
    return items.map((assessment, index) => {
      return (
        <div
          className="flex flex-col font-rubik-medium text-sm w-full"
          key={assessment.id}
        >
          {index > 0 && <hr className="border-t my-1 w-full" />}
          <div className="flex gap-2 justify-between">
            <a
              href={assessment.reportLink}
              target="_blank"
              rel="noreferrer"
              title={assessment.testName}
              className={classNames(
                "min-w-[50%] max-w-[50%] whitespace-nowrap overflow-ellipsis overflow-hidden",
                { "text-blue-500": !!assessment.reportLink }
              )}
            >
              {assessment.testName}
            </a>

            <p className="w-1/2 whitespace-nowrap overflow-ellipsis overflow-hidden">
              {assessment.status}
            </p>

            <p
              title={prepareScore(assessment)}
              className="w-auto whitespace-nowrap"
            >
              {assessment.finalScore}
            </p>
          </div>
        </div>
      );
    });
  };

  const getSKillTitle = (skill, nameHighLight = "") => {
    nameHighLight = nameHighLight
      ?.replace(/<ais-highlight-0000000000>/g, "<mark>")
      ?.replace(/<\/ais-highlight-0000000000>/g, "</mark>");

    if (skill.experience) {
      return `${nameHighLight || skill.name}: ${capitalize(skill.experience)}`;
    }

    return nameHighLight || skill.name;
  };

  const renderJobSkillInfo = ({
    experience,
    hitHasJobSkill,
    hitWorkHistoryHasJobSkill,
  }) => {
    if (!hitHasJobSkill && !hitWorkHistoryHasJobSkill) {
      return <p className="text-red-500">Missing Skill</p>;
    }

    const workHistorySpan = (
      <span className="text-xs font-medium">{"(work history skill)"}</span>
    );

    if (!experience) {
      return (
        <p className="overflow-hidden text-ellipsis">
          Unknown Experience Level{" "}
          {hitWorkHistoryHasJobSkill && workHistorySpan}
        </p>
      );
    }

    return (
      <p className="text-sm">
        <span
          className={classNames(
            "capitalize",
            SKILL_EXP_INFO[experience].className
          )}
        >
          {experience}
        </span>{" "}
        {SKILL_EXP_INFO[experience].info}{" "}
        {hitWorkHistoryHasJobSkill && workHistorySpan}
      </p>
    );
  };

  const renderTorcRatings = (rating) => {
    const rows = rating.skills.map((skill, index) => {
      return (
        <div
          className="flex flex-col font-rubik-medium text-sm w-full"
          key={skill.id}
        >
          {index > 0 && <hr className="border-t my-1 w-full" />}

          <div className="flex space-x-2.5">
            <span className="flex-1">{skill.name}</span>

            <span className="flex-1">{skill.rating * 100} %</span>
          </div>
        </div>
      );
    });

    rows.push(
      <div
        className="flex flex-col font-rubik-medium text-sm w-full"
        key="overall"
      >
        <hr className="border-t my-1 w-full" />

        <div className="flex space-x-2.5">
          <span className="flex-1">Overall</span>

          <span className="flex-1">{rating.overall * 100} %</span>
        </div>
      </div>
    );

    return rows;
  };

  const getHighlightResult = (highlightResult, sourceValue, childField) => {
    const fieldResult = highlightResult?.find(
      (hc) =>
        hc[childField]?.matchLevel !== "none" &&
        sourceValue[childField] ===
          hc[childField]?.value.replace(/<\/?ais-highlight-0000000000>/g, "")
    );

    return fieldResult?.[childField]?.value
      ?.replace(/<ais-highlight-0000000000>/g, "<mark>")
      ?.replace(/<\/ais-highlight-0000000000>/g, "</mark>");
  };

  const experienceHasHighLight = useMemo(() => {
    return (
      hit?._highlightResult?.careers?.some((hc) => {
        return (
          (hc.stack && hc.stack?.matchLevel !== "none") ||
          (hc.description && hc.description?.matchLevel !== "none")
        );
      }) ||
      hit.careers?.some((e) => {
        const localStackHighLight = localHighlightBasedOnQuery(
          e.stack,
          searchStateQuery
        );

        const localDescriptionHighLight = localHighlightBasedOnQuery(
          e.description,
          searchStateQuery
        );

        return (
          /<\/?mark>/g.test(localStackHighLight) ||
          /<\/?mark>/g.test(localDescriptionHighLight)
        );
      }) ||
      hit?._highlightResult?.projectsCaseStudies?.some((hc) => {
        return (
          (hc.stack && hc.stack?.matchLevel !== "none") ||
          (hc.description && hc.description?.matchLevel !== "none")
        );
      }) ||
      hit.projectsCaseStudies?.some((e) => {
        const localStackHighLight = localHighlightBasedOnQuery(
          e.stack,
          searchStateQuery
        );

        const localDescriptionHighLight = localHighlightBasedOnQuery(
          e.description,
          searchStateQuery
        );

        return (
          /<\/?mark>/g.test(localStackHighLight) ||
          /<\/?mark>/g.test(localDescriptionHighLight)
        );
      }) ||
      hit?._highlightResult?.resumeWorkHistory?.some((hc) => {
        return (
          (hc.stack && hc.stack?.matchLevel !== "none") ||
          (hc.description && hc.description?.matchLevel !== "none")
        );
      }) ||
      hit.resumeWorkHistory?.some((e) => {
        const localStackHighLight = localHighlightBasedOnQuery(
          e.stack,
          searchStateQuery
        );

        const localDescriptionHighLight = localHighlightBasedOnQuery(
          e.description,
          searchStateQuery
        );

        return (
          /<\/?mark>/g.test(localStackHighLight) ||
          /<\/?mark>/g.test(localDescriptionHighLight)
        );
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hit, searchStateQuery]);

  const parsedResumeHasHighLight = useMemo(() => {
    return hit?._highlightResult?.parsedResume?.some((hc) => {
      return hc.content && hc.content?.matchLevel !== "none";
    });
  }, [hit]);

  hit.parsedResume = useMemo(() => {
    if (!searchStateQuery) {
      return hit.parsedResume;
    }

    return hit.parsedResume?.map((e) => {
      const localHighlighted = localHighlightBasedOnQuery(
        e.content,
        searchStateQuery
      );

      return {
        ...e,
        localHighlighted,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hit, searchStateQuery]);

  const resumeHasLocalHighLight = hit.parsedResume?.some(
    ({ localHighlighted }) =>
      localHighlighted && /<\/?mark>/g.test(localHighlighted)
  );

  const hitBio = useMemo(() => {
    let highlightedBio = "";

    if (
      hit._highlightResult?.bio?.matchLevel !== "none" &&
      hit.bio ===
        hit._highlightResult?.bio?.value.replace(
          /<\/?ais-highlight-0000000000>/g,
          ""
        )
    ) {
      highlightedBio = hit._highlightResult?.bio?.value
        ?.replace(/<ais-highlight-0000000000>/g, "<mark>")
        ?.replace(/<\/ais-highlight-0000000000>/g, "</mark>");
    }

    return (
      highlightedBio ||
      (hit.bio
        ? localHighlightBasedOnQuery(hit.bio, searchStateQuery)
        : "-" || "-")
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hit.bio, hit._highlightResult?.bio, searchStateQuery]);

  const bioHasHighLight = hitBio && /<\/?mark>/g.test(hitBio);

  // not dropping for now may be useful later on...
  /*
  const renderHighLights = (snippets, attributes, title) => {
    const hasHighlights = attributes.some((e) =>
      e.childAttributes.some((childAttribute) =>
        snippets[e.parentAttribute]?.some(
          (snippet) =>
            snippet[childAttribute] &&
            snippet[childAttribute].matchLevel !== "none"
        )
      )
    );
    if (hasHighlights) {
      return (
        <Collapsible label={title} className="!text-sm" isVisible>
          {attributes.map(
            ({ childAttributes, parentAttribute, title }, parentIndex) => {
              return (
                <div key={`highlights_${parentIndex}`} className="mt-2">
                  <p className="text-sm font-semibold">{title}</p>
                  {childAttributes.map((childAttribute, childIndex) => {
                    const filteredHighlights =
                      snippets[parentAttribute]?.filter(
                        (snippet) =>
                          snippet[childAttribute] &&
                          snippet[childAttribute].matchLevel !== "none"
                      ) || [];

                    if (filteredHighlights.length > 0) {
                      return (
                        <div
                          key={`${parentIndex}_${childIndex}`}
                          className="mt-1"
                        >
                          <p className="capitalize text-xs text-gray-500">
                            {childAttribute}:
                          </p>
                          <div className="flex flex-wrap gap-2">
                            {filteredHighlights.map((e, index) => (
                              <p
                                key={`${parentIndex}_${childIndex}_${index}`}
                                dangerouslySetInnerHTML={{
                                  __html: e[childAttribute].value,
                                }}
                              />
                            ))}
                          </div>
                        </div>
                      );
                    }
                    return <></>;
                  })}
                </div>
              );
            }
          )}
        </Collapsible>
      );
    }
  };
  */

  const match = useMemo(() => {
    return hit?.match ?? hit.associatedMatch;
  }, [hit.associatedMatch, hit?.match]);

  const skillsHasHighLights = useMemo(() => {
    for (const skill of mappedSkills) {
      if (
        !skill.isJobSkill ||
        (skill.isJobSkill &&
          (skill.hitHasJobSkill || skill.hitWorkHistoryHasJobSkill))
      ) {
        const nameHighLight = getHighlightResult(
          hit._highlightResult?.skills,
          skill,
          "name"
        );

        const localHighLight = localHighlightBasedOnQuery(
          skill.name,
          searchStateQuery
        );

        const hasLocalHigLight = /<\/?mark>/g.test(localHighLight);

        if (hasLocalHigLight || nameHighLight) {
          return true;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mappedSkills, hit?._highlightResult?.skills, searchStateQuery]);

  const renderJobSkills = (skills, title) => {
    return (
      <div className="flex flex-col gap-2">
        {title && (
          <p className="ml-1 text-base opacity-40 font-bold">{title}:</p>
        )}
        {skills.map((skill) => {
          let skillTitle = "";
          let nameHighLight = "";
          let hasLocalHigLight = false;

          if (skill.hitHasJobSkill) {
            nameHighLight = getHighlightResult(
              hit._highlightResult?.skills,
              skill,
              "name"
            );

            const localHighLight = localHighlightBasedOnQuery(
              skill.name,
              searchStateQuery
            );

            hasLocalHigLight = /<\/?mark>/g.test(localHighLight);

            skillTitle = getSKillTitle(
              skill,
              skill.hitHasJobSkill ? nameHighLight || localHighLight : ""
            );
          } else {
            skillTitle = getSKillTitle(skill);
          }

          return (
            <div
              key={`job-skill-${skill.name}`}
              className={
                (skill.isJobSkill && skill.hitHasJobSkill) ||
                (skill.isJobSkill && !skill.hitHasJobSkill)
                  ? "flex gap-2 items-center"
                  : "hidden"
              }
            >
              <Chip
                value={
                  nameHighLight || hasLocalHigLight ? (
                    <span
                      className="prose"
                      dangerouslySetInnerHTML={{
                        __html: skillTitle,
                      }}
                    />
                  ) : (
                    skillTitle
                  )
                }
                className={classNames(
                  "!h-[28px] !py-4 !border",
                  {
                    "!border-sky-600 !text-sky-600 font-normal":
                      skill.isJobSkill &&
                      (skill.hitHasJobSkill || skill.hitWorkHistoryHasJobSkill),
                  },
                  {
                    "!border-red-600 !text-red-600":
                      skill.isJobSkill && !skill.hitHasJobSkill,
                  },
                  {
                    "!border-amber-600 !text-amber-600":
                      !skill.isJobSkill && !skill.hitHasJobSkill,
                  }
                )}
              />
              <div className="font-semibold text-sm flex whitespace-nowrap max-w-full">
                {skill.isJobSkill && renderJobSkillInfo(skill)}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="w-full flex flex-col gap-4 mt-6">
      {activeTabName === APPLICATION_TAB_NAMES.CALIBRATION && (
        <Collapsible isVisible label="Calibration">
          <div className="flex flex-col gap-y-4">
            {rating && <Ratings rating={rating} />}
            {reasonsForRating?.length > 0 && (
              <div>
                Reasons:
                <div className="flex flex-wrap gap-2">
                  {reasonsForRating.map((reason) => (
                    <Chip
                      key={`modal-view-${reason}`}
                      value={MATCH_RATING_REASONS_FORMATTED[reason]}
                      className={classNames(
                        "!h-[28px] !py-4 !border !border-amber-600 !text-gray-500"
                      )}
                    />
                  ))}
                </div>
              </div>
            )}
            <div className="flex gap-x-2">
              Notes:
              <button
                onClick={() =>
                  showModal({
                    type: "note",
                    title: `${getUserName(hit)}'s calibration comments`,
                    applicationId:
                      hit.associatedMatch?.applicationId ||
                      hit.match?.applicationId,
                    isCalibration: true,
                    isAllowedPublicNote: true,
                  })
                }
              >
                <SvgIcon type="note" className="w-[20px] h-[20px]" />
              </button>
            </div>
            <div>
              {renderCalibrationNotes(matchNotes?.slice(0, 2) || [])}
              {matchNotes.length > 2 && (
                <>
                  <div className="relative">
                    <button
                      className="text-blue hover:text-blue-600 text-sm"
                      onClick={() => setSeeCalibrationNotes((prev) => !prev)}
                    >
                      {seeCalibrationNotes ? "Show less" : "Show more"}
                    </button>
                  </div>
                  {seeCalibrationNotes &&
                    renderCalibrationNotes(matchNotes?.slice(2) || [])}
                </>
              )}
            </div>
          </div>
        </Collapsible>
      )}

      {!!hit.bio && (
        <Collapsible label="Bio" highLightLabel={bioHasHighLight}>
          <p
            className="prose break-words"
            dangerouslySetInnerHTML={{
              __html: getPurifiedParsedMarkDown(hitBio),
            }}
          />
        </Collapsible>
      )}

      {!!hit.socialLinks?.length && (
        <Collapsible label="Social links">
          <div className="flex gap-x-3">
            {hit.socialLinks.map((s) => {
              return (
                <a
                  key={nanoid()}
                  className="flex transform transition duration-500 hover:scale-110"
                  href={getSocialProfileUrl(s.type, s.value)}
                  target="_blank"
                  rel="noreferrer"
                >
                  <SvgIcon
                    type={getIconForSocialType(s.type)}
                    className="!fill-sky-600"
                  />
                </a>
              );
            })}
          </div>
        </Collapsible>
      )}

      {hit.otherLinks && (
        <Collapsible label="User links">
          {hit.otherLinks && (
            <div className="flex flex-wrap gap-x-3 gap-y-1">
              {hit.otherLinks.map((link) => {
                const { name, description, value, type } = link;

                return (
                  <a
                    key={nanoid()}
                    href={value}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer flex text-sky-600"
                    title={`${CUSTOM_LINK_TITLES_BY_TYPE[type]}, ${name}${
                      description && ", " + description
                    }`}
                  >
                    <SvgIcon
                      type={CUSTOM_LINK_TYPES[type]}
                      className="transform transition duration-500 scale-90 hover:scale-100 min-w-[25px] w-[25px] mr-1 !fill-sky-600"
                    />
                    {name}
                  </a>
                );
              })}
            </div>
          )}
        </Collapsible>
      )}
      {(!!mappedSkills.length || !!mappedOptionalSkills.length) && (
        <Collapsible
          label="Skills"
          isVisible
          highLightLabel={skillsHasHighLights}
        >
          {renderJobSkills(
            mappedSkills,
            jobOpp.optionalSkills?.length > 0 ? "Required Skills" : ""
          )}

          {jobOpp.optionalSkills?.length > 0 && (
            <>
              <br />
              {renderJobSkills(mappedOptionalSkills, "Optional Skills")}
            </>
          )}
          <br />
          <div className="flex flex-wrap gap-2">
            {mappedSkills.map((skill) => {
              const nameHighLight = getHighlightResult(
                hit._highlightResult?.skills,
                skill,
                "name"
              );

              const localHighLight = localHighlightBasedOnQuery(
                skill.name,
                searchStateQuery
              );

              const hasLocalHigLight = /<\/?mark>/g.test(localHighLight);

              const skillTitle = getSKillTitle(
                skill,
                nameHighLight || localHighLight
              );

              return (
                <div
                  key={`modal-view-${skill.id}`}
                  className={
                    (skill.isJobSkill && skill.hitHasJobSkill) ||
                    (skill.isJobSkill && !skill.hitHasJobSkill)
                      ? "hidden"
                      : "flex gap-2 items-center"
                  }
                >
                  <Chip
                    value={
                      nameHighLight || hasLocalHigLight ? (
                        <span
                          className="prose"
                          dangerouslySetInnerHTML={{
                            __html: skillTitle,
                          }}
                        />
                      ) : (
                        skillTitle
                      )
                    }
                    className={classNames(
                      "!h-[28px] !py-4 !border",
                      {
                        "!border-sky-600 !text-sky-600 font-normal":
                          skill.isJobSkill && skill.hitHasJobSkill,
                      },
                      {
                        "!border-red-600 !text-red-600":
                          skill.isJobSkill && !skill.hitHasJobSkill,
                      },
                      {
                        "!border-amber-600 !text-amber-600":
                          !skill.isJobSkill && !skill.hitHasJobSkill,
                      }
                    )}
                  />
                  <div className="font-semibold text-sm flex whitespace-nowrap max-w-full">
                    {skill.isJobSkill && renderJobSkillInfo(skill)}
                  </div>
                </div>
              );
            })}
          </div>
        </Collapsible>
      )}

      {(!!careers.length ||
        !!projectsCaseStudies.length ||
        !!resumeWorkHistory.length) && (
        <Collapsible
          label="Experience & Projects"
          highLightLabel={experienceHasHighLight}
        >
          {!!resumeWorkHistory.length && (
            <Table className="-ml-4">
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <p className="text-2xl">Resume Experience</p>
                  </TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {resumeWorkHistory.map((rwh) => {
                  const highlightedDescription = getHighlightResult(
                    hit._highlightResult?.resumeWorkHistory,
                    rwh,
                    "description"
                  );

                  const resumeWorkHistoryDescription =
                    highlightedDescription ||
                    (rwh.description
                      ? localHighlightBasedOnQuery(
                          rwh.description,
                          searchStateQuery
                        )
                      : "-" || "-");

                  const highlightedStack = getHighlightResult(
                    hit._highlightResult?.resumeWorkHistory,
                    rwh,
                    "stack"
                  );

                  const resumeWorkHistoryStack =
                    highlightedStack ||
                    (rwh.stack
                      ? localHighlightBasedOnQuery(rwh.stack, searchStateQuery)
                      : "-" || "-");

                  return (
                    <TableRow key={rwh.id}>
                      <TableCell className="min-w-[145px]">
                        <div className="flex min-w-[75px]  gap-x-1">
                          {rwh.companyName && <p>{rwh.companyName}</p>}
                          {JOB_TYPES_TEXT[rwh.format] && (
                            <p> &middot; {JOB_TYPES_TEXT[rwh.format]}</p>
                          )}
                        </div>
                        {rwh.title && (
                          <p className="min-w-[75px] font-medium">
                            {rwh.title}
                          </p>
                        )}
                        {dayjs(rwh.startDate).format("YYYY")} -{" "}
                        {rwh.endDate
                          ? dayjs(rwh.endDate).format("YYYY")
                          : "Present"}
                      </TableCell>

                      <TableCell>
                        <div className="flex flex-col gap-y-2">
                          {rwh.stack && (
                            <div>
                              Stack:
                              <TextContainer
                                text={resumeWorkHistoryStack}
                                dangerously={resumeWorkHistoryStack}
                                maxLength={250}
                              />
                            </div>
                          )}
                          {rwh.skills?.length > 0 && (
                            <div>
                              Skills:
                              <TextContainer
                                text={rwh.skills.map((e) => e.name).join(", ")}
                                dangerously={highlightedStack}
                                maxLength={250}
                              />
                            </div>
                          )}
                          <div className="min-w-[300px] max-w-md">
                            Description:
                            <TextContainer
                              text={resumeWorkHistoryDescription}
                              dangerously={resumeWorkHistoryDescription}
                              maxLength={250}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}

          {!!careers.length && (
            <Table className="-ml-4">
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <p className="text-2xl">Experience</p>
                  </TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {careers.map((career) => {
                  const highlightedDescription = getHighlightResult(
                    hit._highlightResult?.careers,
                    career,
                    "description"
                  );

                  const careerDescription =
                    highlightedDescription ||
                    (career.description
                      ? localHighlightBasedOnQuery(
                          career.description,
                          searchStateQuery
                        )
                      : "-" || "-");

                  const highlightedStack = getHighlightResult(
                    hit._highlightResult?.careers,
                    career,
                    "stack"
                  );

                  const careerStack =
                    highlightedStack ||
                    (career.stack
                      ? localHighlightBasedOnQuery(
                          career.stack,
                          searchStateQuery
                        )
                      : "-" || "-");

                  return (
                    <TableRow key={career.id}>
                      <TableCell className="min-w-[145px]">
                        <div className="flex min-w-[75px]  gap-x-1">
                          {career.companyName && <p>{career.companyName}</p>}
                          {JOB_TYPES_TEXT[career.format] && (
                            <p> &middot; {JOB_TYPES_TEXT[career.format]}</p>
                          )}
                        </div>
                        {career.title && (
                          <p className="min-w-[75px] font-medium">
                            {career.title}
                          </p>
                        )}
                        {dayjs(career.startDate).format("YYYY")} -{" "}
                        {career.endDate
                          ? dayjs(career.endDate).format("YYYY")
                          : "Present"}
                      </TableCell>

                      <TableCell>
                        <div className="flex flex-col gap-y-2">
                          {career.stack && (
                            <div>
                              Stack:
                              <TextContainer
                                text={careerStack}
                                dangerously={careerStack}
                                maxLength={250}
                              />
                            </div>
                          )}

                          {career.skills?.length > 0 && (
                            <div>
                              Skills:
                              <TextContainer
                                text={career.skills
                                  .map((e) => e.name)
                                  .join(", ")}
                                dangerously={highlightedStack}
                                maxLength={250}
                              />
                            </div>
                          )}
                          <div className="min-w-[300px] max-w-md">
                            Description:
                            <TextContainer
                              text={careerDescription}
                              dangerously={careerDescription}
                              maxLength={250}
                            />
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}

          {!!projectsCaseStudies.length && (
            <Table className="-ml-4">
              <TableHeader>
                <TableRow>
                  <TableHead>
                    <p className="text-2xl">Projects</p>
                  </TableHead>
                  <TableHead></TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {projectsCaseStudies.map((project) => {
                  const highlightedDescription = getHighlightResult(
                    hit._highlightResult?.projectsCaseStudies,
                    project,
                    "description"
                  );

                  const projectDescription =
                    highlightedDescription ||
                    (project.description
                      ? localHighlightBasedOnQuery(
                          project.description,
                          searchStateQuery
                        )
                      : "-" || "-");

                  const highlightedStack = getHighlightResult(
                    hit._highlightResult?.projectsCaseStudies,
                    project,
                    "stack"
                  );

                  const projectStack =
                    highlightedStack ||
                    (project.stack
                      ? localHighlightBasedOnQuery(
                          project.stack,
                          searchStateQuery
                        )
                      : "-" || "-");

                  return (
                    <TableRow key={project.id}>
                      <TableCell className="min-w-[145px]">
                        <div className="flex min-w-[75px] gap-x-1">
                          {project.client && <p>{project.client} </p>}
                          {PROJECT_CASE_STUDY_WORK_TYPES_TEXT[
                            project.workType
                          ] && (
                            <p>
                              &middot;{" "}
                              {
                                PROJECT_CASE_STUDY_WORK_TYPES_TEXT[
                                  project.workType
                                ]
                              }
                            </p>
                          )}
                        </div>
                        {project.client && (
                          <p className="min-w-[75px] font-medium">
                            {project.client}
                          </p>
                        )}
                        {project.startDate ? (
                          <>{dayjs(project.startDate).format("YYYY")} - </>
                        ) : (
                          <></>
                        )}
                        {project.endDate
                          ? dayjs(project.endDate).format("YYYY")
                          : "Present"}
                      </TableCell>

                      <TableCell>
                        <div className="flex flex-col gap-y-2">
                          {project.stack && (
                            <div>
                              Stack:
                              <TextContainer
                                text={projectStack}
                                dangerously={projectStack}
                                maxLength={250}
                              />
                            </div>
                          )}
                          <div className="min-w-[300px] max-w-md">
                            Description:
                            <TextContainer
                              text={projectDescription}
                              dangerously={projectDescription}
                              maxLength={250}
                            />
                          </div>
                          {project.link && (
                            <div className="mt-2">
                              <a
                                className="text-sky-500 underline flex gap-x-1 items-center"
                                href={project.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <SvgIcon
                                  type={"GENERAL"}
                                  className="fill-sky-500 w-[15px] h-[15px]"
                                />
                                {project.link}
                              </a>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </Collapsible>
      )}

      {!!educationRecords.length && (
        <Collapsible label="Education">
          <Table className="-ml-4">
            <TableHeader>
              <TableRow>
                <TableHead>School</TableHead>
                <TableHead>Degree Type</TableHead>
                <TableHead>Degree</TableHead>
                <TableHead>Field Of Study</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {educationRecords.map((item) => (
                <TableRow key={item.id}>
                  <TableCell>{item.school}</TableCell>
                  <TableCell>
                    {item.degreeType ? (
                      EDUCATION_DEGREE_TYPES_TEXT[item.degreeType] ??
                      item.degreeType
                    ) : (
                      <>-</>
                    )}
                  </TableCell>
                  <TableCell>{item.degreeName || <>-</>}</TableCell>
                  <TableCell>{item.fieldOfStudy || <>-</>}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Collapsible>
      )}

      <MatchedJobs hit={hit} activeTabName={activeTabName} key={hit.id} />

      {!!assessments.length && (
        <Collapsible label="Assessments">
          {renderAssessments(assessments?.slice(0, 3) || [])}

          {assessments.length > 3 && (
            <div className="relative w-full mt-4">
              <button
                className="text-blue hover:text-blue-600 text-sm"
                onMouseOver={() => setSeeAssessments(true)}
              >
                See all Assessments
              </button>
              {seeAssessments && (
                <div
                  className="h-fit max-h-96 min-w-full bg-white p-4 rounded-lg absolute -top-1 shadow-lg z-30"
                  onMouseLeave={() => setSeeAssessments(false)}
                >
                  <div className="flex flex-wrap">
                    {renderAssessments(assessments || [])}
                  </div>
                </div>
              )}
            </div>
          )}
        </Collapsible>
      )}

      {!!hit.ratings?.torc && (
        <Collapsible label="Torc Ratings">
          {renderTorcRatings(hit.ratings.torc)}
        </Collapsible>
      )}

      {hit.placements?.length > 0 && (
        <Collapsible
          label="Placements"
          onToggleCollapse={(e) => setIsLoadingPlacements(e)}
        >
          {!isLoadingPlacements &&
            renderPlacements(
              hit.placements?.sort(
                (a, b) =>
                  ((b.actualEndDate && new Date(b.actualEndDate)) ??
                    new Date(b.endDate)) -
                  ((a.actualEndDate && new Date(a.actualEndDate)) ??
                    new Date(a.endDate))
              ) || []
            )}
        </Collapsible>
      )}

      {jobOpp?.id && (
        <Collapsible
          label="Match Analysis Report"
          className="my-2 -ml-2 per-2"
          unMountChildrenWhenNotVisible
          isVisible={
            !!(
              match?.analysis?.report ||
              match?.localAnalysis?.report ||
              hitsMatchAnalysis[hit?.id]?.analysis?.report
            )
          }
          actionComponent={
            !!match && (
              <div className="absolute top-0 -right-10">
                <Dialog>
                  <DialogTrigger>
                    <SvgIcon
                      type="info"
                      className="fill-gray-500 !min-w-[30px] !min-h-[30px] !max-w-[30px] !max-h-[30px] lg:min-w-[35px] lg:min-h-[35px] lg:max-w-[35px] lg:max-h-[35px] p-[5px] box-border hover:bg-gray-300 transition-all rounded-full flex justify-center items-center"
                      tooltip="Go to Match Analysis (AI)"
                    />
                  </DialogTrigger>

                  <UserInfoModalV2
                    match={match}
                    user={hit}
                    tabDefaultValue="match-analysis-AI"
                  />
                </Dialog>
              </div>
            )
          }
        >
          <div className="p-4 pt-0">
            <MatchAnalysis
              hit={hit}
              match={match}
              matchAnalysis={{
                customerSummary:
                  match?.customerSummary ||
                  match?.localCustomerSummary ||
                  hitsMatchAnalysis[hit?.id]?.customerSummary,
                analysis: {
                  score:
                    match?.analysis?.score ??
                    match?.localAnalysis?.score ??
                    hitsMatchAnalysis[hit?.id]?.analysis?.score,
                  report:
                    match?.analysis?.report ||
                    match?.localAnalysis?.report ||
                    hitsMatchAnalysis[hit?.id]?.analysis?.report,
                },
              }}
              defaultEditValue={false}
              hideCustomerSummary
              fetchData={match?.analysis?.report}
            />
          </div>
        </Collapsible>
      )}

      {hit.parsedResume?.length > 0 && (
        <Collapsible
          label="Resume Text"
          highLightLabel={parsedResumeHasHighLight || resumeHasLocalHighLight}
        >
          <ParsedResume
            hit={hit}
            parsedResumeHasHighLight={parsedResumeHasHighLight}
            resumeHasLocalHighLight={resumeHasLocalHighLight}
            localHighlightBasedOnQuery={localHighlightBasedOnQuery}
          />
        </Collapsible>
      )}
    </div>
  );
};

CollapsibleSection.propTypes = {
  hit: PropTypes.object,
  activeTabName: PropTypes.string,
};

CollapsibleSection.defaultPropt = {
  hit: {},
  activeTabName: "TOPCANDIDATES",
};

export default CollapsibleSection;
