import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { nanoid } from "nanoid";

import { SearchContext } from "context/providers";
import { validateSearchCriteriaTitle } from "utils/validation";

import Input from "components/base/Input";
import Button from "components/Button";
import Collapsible from "./Collapsible";

const SAVED_CRITERIA_KEY = "SEARCH_CRITERIA";

const SavedSearches = () => {
  const {
    searchState,
    setSearchState,
    AISkillSearchConfig,
    AISkillsConfigSearchChange,
    clearAISkillsSearchConfig,
  } = useContext(SearchContext);
  const [inputs, setInputs] = useState({ criteriaTitle: "" });
  const [errors, setErrors] = useState({ criteriaTitle: "" });

  const getSavedSearches = () =>
    JSON.parse(localStorage.getItem(SAVED_CRITERIA_KEY) || "[]");

  const restoreSearchCriteria = (id) => {
    const savedSearches = getSavedSearches();

    const savedSearch = savedSearches.find((el) => el.id === id);

    setSearchState(savedSearch?.criteria);

    if (savedSearch?.AISkillSearchConfig?.enabled) {
      AISkillsConfigSearchChange(savedSearch.AISkillSearchConfig);
    } else if (AISkillSearchConfig?.enabled) {
      clearAISkillsSearchConfig();
    }
  };

  const renderSavedSearch = () => {
    const savedSearch = getSavedSearches();

    if (savedSearch.length === 0) {
      return;
    }

    return (
      <div className="mb-3">
        <p className="uppercase font-bold whitespace-nowrap mb-2">
          Saved searches
        </p>

        {savedSearch.map((el, index) => {
          return (
            <p
              key={el.id}
              className="text-blue-600 cursor-pointer opacity-70 hover:opacity-100 transition-all"
              onClick={() => restoreSearchCriteria(el.id)}
            >{`${index + 1}. ${el.title}`}</p>
          );
        })}
      </div>
    );
  };

  const validateTitle = (e) => {
    e.preventDefault();

    validateSearchCriteriaTitle({
      data: inputs,
      onSuccess: (validData) => saveSearchCriteria({ ...validData }),
      onError: (errorsData) => {
        setErrors({ ...errorsData });
      },
    });
  };

  const saveSearchCriteria = () => {
    try {
      const savedCriteria = JSON.parse(
        localStorage.getItem(SAVED_CRITERIA_KEY) || "[]"
      );

      savedCriteria.push({
        title: inputs.criteriaTitle,
        criteria: searchState,
        AISkillSearchConfig,
        id: nanoid(),
      });

      localStorage.setItem(SAVED_CRITERIA_KEY, JSON.stringify(savedCriteria));

      setInputs((prev) => ({ ...prev, criteriaTitle: "" }));

      toast("Search criteria was saved");
    } catch (error) {
      console.warn(`saveSearchCriteria error: ${error}`);
    }
  };

  const handleCriteriaTitleChange = ({ value, valueKey }) => {
    setInputs((prev) => ({ ...prev, [valueKey]: value }));
    setErrors((prev) => ({ ...prev, [valueKey]: "" }));
  };

  return (
    <>
      {renderSavedSearch()}

      <Collapsible label="Save criteria">
        <form onSubmit={validateTitle}>
          <Input
            label="Name"
            value={inputs.criteriaTitle}
            valueKey="criteriaTitle"
            errorMessage={errors.criteriaTitle}
            placeholder="Search Criteria Name"
            onChange={handleCriteriaTitleChange}
          />

          <Button className="mb-3" type="submit">
            save
          </Button>
        </form>
      </Collapsible>
    </>
  );
};

export default SavedSearches;
