import Base from "./Base.js";

import {
  getJobOpportunity,
  getJobOpportunityAISkillsForSearch,
  getJobOpportunityEvents,
  getNextJobOpportunityMatches,
  listJobOpportunityMatches,
  listJobOpportunitys,
  listSimilarJobOpportunities,
} from "api/graphql/queries.js";
import { updateJobOpportunity } from "./graphql/mutations.js";

export default class JobsAPI extends Base {
  list(payload) {
    return this.apiClient.request({ query: listJobOpportunitys, payload });
  }

  get(payload) {
    return this.apiClient.request({ query: getJobOpportunity, payload });
  }

  update(payload) {
    return this.apiClient.request({ query: updateJobOpportunity, payload });
  }

  getEvents(payload) {
    return this.apiClient.request({ query: getJobOpportunityEvents, payload });
  }

  getNextMatches(payload) {
    return this.apiClient.request({
      query: getNextJobOpportunityMatches,
      payload,
    });
  }

  listSimilarJobs(payload) {
    return this.apiClient.request({
      query: listSimilarJobOpportunities,
      payload,
    });
  }

  listMatches(payload) {
    return this.apiClient.request({
      query: listJobOpportunityMatches,
      payload,
    });
  }

  getAISkillsForSearch(payload) {
    return this.apiClient.request({
      query: getJobOpportunityAISkillsForSearch,
      payload,
    });
  }
}
