import { gql } from "@apollo/client";

export const GET_USER_ACTIVITY_HISTORY = gql`
  query GetUserActivityHistory(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUserActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserActivityHistory(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eventName
        message
        createdAt
      }
      nextToken
    }
  }
`;
