import React, { useContext, useState, useEffect } from "react";

import { RefinementList, Panel } from "react-instantsearch-dom";
import classNames from "classnames";

import {
  CustomPanel,
  SpecificLocationRefinement,
} from "components/SearchFilters/components";
import GeographicalRegion from "components/SearchFilters/components/GeographicalRegion";

import { JobsContext, SearchContext } from "context/providers";

const LOCATION_FILTER_TYPES = {
  SPECIFIC: "SPECIFIC",
  REGION: "REGION",
};

const LocationFiltersWrapper = () => {
  const [locationFilterType, setLocationFilterType] = useState(
    LOCATION_FILTER_TYPES.REGION
  );

  const {
    searchState,
    geographicalRegions,
    setGeographicalRegions,
    clearLocationFilters,
  } = useContext(SearchContext);

  const { jobOpp } = useContext(JobsContext);

  useEffect(() => {
    if (jobOpp?.id && jobOpp.location) {
      setLocationFilterType(LOCATION_FILTER_TYPES.SPECIFIC);
    } else {
      setLocationFilterType(LOCATION_FILTER_TYPES.REGION);
    }
  }, [jobOpp]);

  return (
    <div>
      <CustomPanel
        title="Location"
        checked={locationFilterType === LOCATION_FILTER_TYPES.SPECIFIC}
        toggle
        leftToggleLabel={LOCATION_FILTER_TYPES.REGION}
        rightToggleLabel={LOCATION_FILTER_TYPES.SPECIFIC}
        onChange={(value) => {
          clearLocationFilters();
          if (value) {
            setLocationFilterType(LOCATION_FILTER_TYPES.SPECIFIC);
          } else {
            setLocationFilterType(LOCATION_FILTER_TYPES.REGION);
          }
        }}
      >
        <>
          <div
            className={classNames({
              hidden: locationFilterType !== LOCATION_FILTER_TYPES.SPECIFIC,
            })}
          >
            <SpecificLocationRefinement />
          </div>
          <div
            className={classNames({
              hidden: locationFilterType !== LOCATION_FILTER_TYPES.REGION,
            })}
          >
            <RefinementList
              attribute="location.countryName"
              translations={{
                placeholder: "Find a country",
              }}
              limit={5}
              searchable
              showMore
            />
          </div>
        </>
      </CustomPanel>

      <div
        className={classNames({
          hidden: locationFilterType !== LOCATION_FILTER_TYPES.REGION,
        })}
      >
        <Panel header="Location (Region)">
          <GeographicalRegion
            geographicalRegions={geographicalRegions}
            searchState={searchState}
            setGeographicalRegions={setGeographicalRegions}
          />
        </Panel>
      </div>
    </div>
  );
};

export default LocationFiltersWrapper;
