import React from "react";
import * as RadixPopover from "@radix-ui/react-popover";
import { Info } from "lucide-react";
import classNames from "classnames";

const Popover = ({
  className,
  icon = (
    <span title="Click to open" className="text-gray-500">
      <Info />
    </span>
  ),
  children,
  contentProps,
  closeIcon,
}) => (
  <RadixPopover.Root>
    <RadixPopover.Trigger asChild>{icon}</RadixPopover.Trigger>
    <RadixPopover.Portal>
      <RadixPopover.Content
        className={classNames(
          "z-50 min-w-[300px] rounded bg-white shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2)] will-change-[transform,opacity] focus:shadow-[0_10px_38px_-10px_hsla(206,22%,7%,.35),0_10px_20px_-15px_hsla(206,22%,7%,.2),0_0_0_2px_theme(colors.violet7)] data-[state=open]:data-[side=bottom]:animate-slideUpAndFade data-[state=open]:data-[side=left]:animate-slideRightAndFade data-[state=open]:data-[side=right]:animate-slideLeftAndFade data-[state=open]:data-[side=top]:animate-slideDownAndFade",
          className
        )}
        sideOffset={5}
        {...contentProps}
      >
        {children}
        {closeIcon && (
          <RadixPopover.Close
            className="absolute right-[5px] top-[5px] inline-flex size-[25px] cursor-default items-center justify-center rounded-full text-violet11 outline-none hover:bg-violet4 focus:shadow-[0_0_0_2px] focus:shadow-violet7"
            aria-label="Close"
          >
            {closeIcon}
          </RadixPopover.Close>
        )}
        <RadixPopover.Arrow className="fill-white" />
      </RadixPopover.Content>
    </RadixPopover.Portal>
  </RadixPopover.Root>
);

export default Popover;
