import { useSuspenseQuery } from "@apollo/client";
import { GET_FIELD_HISTORY } from "api/graphql/common";
import {
  Table,
  TableBody,
  TableHeader,
  TableRow,
  TableHead,
  TableCell,
} from "components/ui/table";
import dayjs from "dayjs";

const sortByDate = (items) =>
  items
    .slice()
    .sort((a, b) => (dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1));

export default function MatchHistory({ applicationId, jobOpportunityId }) {
  const { error, data } = useSuspenseQuery(GET_FIELD_HISTORY, {
    variables: {
      key: { eq: `${applicationId}_${jobOpportunityId}` },
      entity: "Match",
    },
  });

  const sortedMatchHistory = sortByDate(data.getFieldHistory.items || []);

  return (
    <section className="max-h-96 h-96 overflow-y-auto">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Old Value</TableHead>
            <TableHead>New Value</TableHead>
            <TableHead>Changed By</TableHead>
            <TableHead>Date</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {sortedMatchHistory.map((item) => (
            <TableRow key={item.id}>
              <TableCell>{item.oldValue}</TableCell>
              <TableCell>{item.newValue}</TableCell>
              <TableCell>{item.creator}</TableCell>
              <TableCell>{item.createdAt}</TableCell>
            </TableRow>
          ))}
          {sortedMatchHistory.length === 0 && (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                This match has no history
              </TableCell>
            </TableRow>
          )}
          {error && (
            <TableRow>
              <TableCell colSpan={4} className="text-center">
                An error occurred fetching the match history. Try refreshing the
                page. If error persists, contact Torc Support.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </section>
  );
}
