import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import dayjs from "dayjs";
import { cloneDeep, isArray } from "lodash";

import { validateNote } from "utils/validation";

import RichTextEditor from "components/base/RichTextEditor";
import Button from "components/Button";
import Checkbox from "components/base/Checkbox";
import Collapsible from "components/Collapsible";
import Note from "./components/Note";
import { NOTE_TYPES_MAPPED_ICON } from "lookup";
import { purifyMarkDown } from "utils/markdown";

const Notes = ({
  notes,
  notesTarget,
  notesCount,
  isAllowedPublicNote,
  showGenerateAnalysisBasedOnNote,
  borderTop,
  isLoading,
  loggedInUser,
  editorLabel,
  editorSubLabel,
  resetState,
  onSubmit,
  onDelete,
  isCalibration,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [expanded, setExpanded] = useState({ [notesTarget]: false });
  const [note, setNote] = useState({
    content: "",
    isPublic: isCalibration ? true : false,
    generateAnalysis: false,
    successMessage: "",
    errorMessage: "",
  });

  const resetNoteState = () => {
    setNote((prev) => ({
      ...prev,
      content: "",
      successMessage: "",
      errorMessage: "",
    }));
  };

  const handleChange = (value, valueKey) => {
    setNote((prev) => ({
      ...prev,
      [valueKey]: value,
      errorMessage: "",
      successMessage: "",
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (submitting) {
      return;
    }

    setSubmitting(true);

    validateNote({
      data: note,
      onSuccess: async (validNote) => {
        validNote.content = purifyMarkDown(validNote.content);
        const res = await onSubmit(validNote);

        if (res.isSuccess) {
          setNote((prev) => ({
            ...prev,
            successMessage: validNote.generateAnalysis
              ? `Note successfully added (The ${notesTarget} analysis will be available shortly)`
              : "Note successfully added",
            errorMessage: "",
            content: "",
            generateAnalysis: false,
          }));

          setExpanded((prev) => ({ ...prev, [notesTarget]: true }));
        } else {
          setNote((prev) => ({
            ...prev,
            errorMessage: "Something went wrong",
            successMessage: "",
            generateAnalysis: false,
          }));
        }

        setSubmitting(false);
      },
      onError: (errors) => {
        setNote((prev) => ({
          ...prev,
          errorMessage: Object.values(errors)[0],
        }));

        setSubmitting(false);
      },
    });
  };

  const handleDelete = async (noteId, rest) => {
    await onDelete(noteId, rest);
    resetNoteState();
  };

  const renderNote = (item, index, items, rest) => {
    const { preferred_username } = loggedInUser.attributes;
    const { creator } = item;
    const isAllowToDelete = preferred_username === creator;

    return (
      <Note
        key={item.createdAt}
        shouldRenderSeparator={index < items.length - 1}
        creator={creator}
        isAllowToDelete={isAllowToDelete}
        onDelete={(id) => handleDelete(id, rest)}
        noteTypeIcon={NOTE_TYPES_MAPPED_ICON[item.noteType]}
        {...item}
      />
    );
  };

  const getShouldRenderSeparator = (notes = {}, currentIndex) => {
    const withItems = Object.values(notes).filter((el) => !!el.items.length);

    return withItems.length > 1 && currentIndex > 0;
  };

  const renderNotes = () => {
    const clonedNotes = cloneDeep(notes);

    if (isArray(clonedNotes)) {
      const sortedNotes = clonedNotes.sort((a, b) =>
        dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
      );

      return (
        <Collapsible
          label={`Notes${!!notesCount ? ` (${notesCount})` : ""}`}
          isVisible={expanded[notesTarget]}
        >
          <div className="bg-grey-700 px-4 py-2">
            {sortedNotes.map((note, index) =>
              renderNote(note, index, sortedNotes)
            )}
          </div>
        </Collapsible>
      );
    }

    return Object.values(clonedNotes).map((item, index) => {
      const { label, items, ...rest } = item;
      const sortedItems = items.sort((a, b) =>
        dayjs(a.createdAt).isAfter(dayjs(b.createdAt)) ? -1 : 1
      );

      const shouldRenderSeparator = getShouldRenderSeparator(
        clonedNotes,
        index
      );

      return (
        !!items.length && (
          <Fragment key={label}>
            {shouldRenderSeparator && (
              <hr className="w-full border-t border-gray-300 mb-4" />
            )}

            <Collapsible
              label={`${label} (${items.length})`}
              isVisible={expanded[notesTarget]}
            >
              <div className="bg-grey-700 px-4 py-2">
                {sortedItems.map((note, index) =>
                  renderNote(note, index, sortedItems, rest)
                )}
              </div>
            </Collapsible>
          </Fragment>
        )
      );
    });
  };

  const shouldRenderNotes = () => {
    if (isArray(notes)) {
      return !!notes.length;
    }

    return !!Object.values(notes).filter((el) => !!el.items?.length).length;
  };

  useEffect(() => {
    if (resetState) {
      resetNoteState();
    }
  }, [resetState]);

  return (
    <div className="flex flex-col">
      {borderTop && <hr className="w-full border-t-2 border-gray-300 mb-4" />}

      <form onSubmit={handleSubmit}>
        {isLoading ? (
          <div className="loader !w-6 !h-6 mb-4" />
        ) : shouldRenderNotes() ? (
          renderNotes()
        ) : (
          <p className="mb-4 text-gray-500 text-xs">
            No notes have been created{notesTarget && ` for the ${notesTarget}`}
          </p>
        )}

        {editorLabel && <p className="text-2xl font-bold">{editorLabel}</p>}

        {editorSubLabel && (
          <p className="font-normal text-base mb-4">{editorSubLabel}</p>
        )}

        <RichTextEditor
          value={note.content}
          valueKey="content"
          onChange={handleChange}
        />

        <div className="flex gap-x-4">
          {isAllowedPublicNote && (
            <Checkbox
              label="Customer Visible"
              checked={note.isPublic}
              helpText="Make this note visible to the customer. Customer Notes will have a yellow background on the timeline"
              onChange={(checked) => handleChange(checked, "isPublic")}
            />
          )}

          {showGenerateAnalysisBasedOnNote && (
            <Checkbox
              label="Use Note Content for Analysis"
              checked={note.generateAnalysis}
              helpText={`A new ${notesTarget} analysis will be generated, incorporating the note content as additional context. (The existing analysis will be overwritten.)`}
              onChange={(checked) => handleChange(checked, "generateAnalysis")}
            />
          )}
        </div>

        <div className="flex flex-col items-start">
          {note.successMessage && (
            <span className="text-green-600 text-sm font-bold mt-1">
              {note.successMessage}
            </span>
          )}

          {note.errorMessage && (
            <span className="text-red-600 text-sm font-bold mt-1">
              {note.errorMessage}
            </span>
          )}

          <Button
            type="submit"
            className={classNames("mt-4", {
              "animate-pulse cursor-not-allowed": submitting,
            })}
          >
            Save note
          </Button>
        </div>
      </form>
    </div>
  );
};

Notes.propTypes = {
  notes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  notesTarget: PropTypes.string,
  notesCount: PropTypes.number,
  title: PropTypes.string,
  helpText: PropTypes.string,
  isLoading: PropTypes.bool,
  isAllowedPublicNote: PropTypes.bool,
  showGenerateAnalysisBasedOnNote: PropTypes.bool,
  borderTop: PropTypes.bool,
  isCalibration: PropTypes.bool,
  loggedInUser: PropTypes.object,
  editorLabel: PropTypes.string,
  editorSubLabel: PropTypes.string,
  resetState: PropTypes.bool,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
};

Notes.defaultProps = {
  notes: [],
  notesTarget: "",
  title: "",
  helpText: "",
  notesCount: 0,
  isAllowedPublicNote: false,
  showGenerateAnalysisBasedOnNote: false,
  borderTop: false,
  isCalibration: false,
  isLoading: false,
  loggedInUser: { attributes: { preferred_username: "" } },
  editorLabel: "",
  editorSubLabel: "",
  resetState: false,
  onSubmit: () => {},
  onDelete: () => {},
};

export default Notes;
