import React, { useEffect } from "react";
import classNames from "classnames";

import styles from "./index.module.css";

const Modal = ({ children, isOpen, onClose }) => {
  const handleKeyPress = ({ keyCode }) => {
    if (keyCode === 27) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyPress);

    return () => {
      document.body.classList.remove("overflow-hidden");
      document.removeEventListener("keyup", handleKeyPress);
    };
  });

  const handleClose = (event) => {
    if (event.target.id !== "modalBackground") {
      return;
    }

    closeModal();
  };

  const closeModal = () => {
    document.body.classList.remove("overflow-hidden");
    onClose();
  };

  return (
    <div
      className={classNames(
        "transition-[background-color]",
        { "fixed inset-0 bg-[#000000B3] z-50": isOpen },
        { "bg-000": !isOpen }
      )}
      onClick={handleClose}
      id="modalBackground"
    >
      <div
        className={classNames(
          "fixed inset-x-[10vw] inset-y-[10vh] bg-white border-2 rounded-lg transition-transform z-10 overflow-auto shadow-lg",
          styles.customScrollBar,
          { "translate-x-0 overflow-y-scroll": isOpen },
          { "inset-y-[95px] translate-x-[80vw] cursor-pointer": !isOpen }
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
