import { useContext, useEffect, useRef, useState } from "react";
import classNames from "classnames";

import api from "apiSingleton";
import SvgButton from "components/base/SvgButton";
import TextArea from "components/base/TextArea";
import ActionButton from "components/UserCardList/components/UserCardResult/molecules/ActionButton";
import {
  AlertContext,
  JobsContext,
  MatchContext,
  SearchContext,
} from "context/providers";
import { getPurifiedParsedMarkDown } from "utils/markdown";
import { PencilLine } from "lucide-react";

const MatchAnalysis = ({
  match = {},
  hit,
  matchAnalysis,
  fetchData,
  actionButtonType,
  actionButtonIcon,
  argsToUpdate,
  hideInternalUse,
  hideCustomerSummary,
  updated,
  defaultEditValue = true,
  hideEdit,
  loaderClassName,
}) => {
  const { jobOpp } = useContext(JobsContext);
  const {
    updateHitsMatchAnalysisHit,
    removeHitsMatchAnalysisHit,
    hitsMatchAnalysis,
  } = useContext(SearchContext);
  const { addGraphQLAlert } = useContext(AlertContext);
  const {
    updateMatch,
    updateMatch1LevelFieldLocally,
    updateBestFitFieldLocally,
    updateIdealCandidateFieldLocally,
  } = useContext(MatchContext);
  const [gettingMatchAnalysis, setGettingMatchAnalysis] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [matchSummaryForCustomer, setMatchSummaryForCustomer] = useState(
    matchAnalysis?.customerSummary ?? ""
  );
  const [internalMatchAnalysis, setInternalMatchAnalysis] = useState({
    score: matchAnalysis?.analysis?.score,
    report: matchAnalysis?.analysis?.report,
  });
  const [edit, setEdit] = useState(defaultEditValue);

  const ANALYSIS_KEY_TYPES = {
    ANALYSIS: "analysis",
    LOCAL_ANALYSIS: "localAnalysis",
    CUSTOMER_SUMMARY: "customerSummary",
    LOCAL_CUSTOMER_SUMMARY: "localCustomerSummary",
  };

  const fetchingData = useRef(
    fetchData && matchAnalysis.analysis?.report
      ? "Loading..."
      : (!match.applicationId && !matchAnalysis.analysis?.report) ||
        (match.applicationId && !matchAnalysis.analysis?.report)
      ? "Generating Report..."
      : ""
  );

  useEffect(() => {
    if (
      (!match.applicationId && !matchAnalysis.analysis?.report) ||
      (match.applicationId && !matchAnalysis.analysis?.report)
    ) {
      (async () => {
        const generatedMatchAnalysis = await generateMatchAnalysis();

        if (!generatedMatchAnalysis) {
          return;
        }

        const matchAnalysis = {
          analysis: {
            score: generatedMatchAnalysis.score ?? internalMatchAnalysis.score,
            report:
              generatedMatchAnalysis.report || internalMatchAnalysis.report,
          },
          customerSummary:
            generatedMatchAnalysis.summary || matchSummaryForCustomer,
        };

        if (match?.applicationId) {
          updateMatch1LevelFieldLocally(
            match.applicationId,
            ANALYSIS_KEY_TYPES.LOCAL_CUSTOMER_SUMMARY,
            matchAnalysis.customerSummary
          );

          updateMatch1LevelFieldLocally(
            match.applicationId,
            ANALYSIS_KEY_TYPES.LOCAL_ANALYSIS,
            {
              ...matchAnalysis.analysis,
            }
          );
        } else if (hit?.id) {
          updateHitsMatchAnalysisHit(hit.id, matchAnalysis);
          updateBestFitFieldLocally(hit.id, "matchAnalysis", matchAnalysis);
          updateIdealCandidateFieldLocally(
            hit.id,
            "matchAnalysis",
            matchAnalysis
          );
        }
      })();
    } else if (fetchData) {
      (async () => {
        const { data } = await api.match.get({
          applicationId: match.applicationId,
          jobOpportunityId: match.jobOpportunityId,
        });

        const matchAnalysis = {
          analysis: {
            report:
              data.getMatch?.analysis?.report || internalMatchAnalysis.report,
            score:
              data.getMatch?.analysis?.score ?? internalMatchAnalysis.score,
          },
          customerSummary:
            data.getMatch?.customerSummary || matchSummaryForCustomer,
        };

        fetchingData.current = "";

        setMatchSummaryForCustomer(matchAnalysis.customerSummary);

        updateMatch1LevelFieldLocally(
          match.applicationId,
          !match[ANALYSIS_KEY_TYPES.CUSTOMER_SUMMARY]
            ? ANALYSIS_KEY_TYPES.LOCAL_CUSTOMER_SUMMARY
            : ANALYSIS_KEY_TYPES.CUSTOMER_SUMMARY,
          matchAnalysis.customerSummary
        );

        setInternalMatchAnalysis({ ...matchAnalysis.analysis });

        updateMatch1LevelFieldLocally(
          match.applicationId,
          !match[ANALYSIS_KEY_TYPES.ANALYSIS]
            ? ANALYSIS_KEY_TYPES.LOCAL_ANALYSIS
            : ANALYSIS_KEY_TYPES.ANALYSIS,
          { ...matchAnalysis.analysis }
        );
      })();
    }

    // ones the match has the stored field no need to keep it within the user as a local field
    if (match?.applicationId) {
      if (hitsMatchAnalysis[hit?.id]) {
        removeHitsMatchAnalysisHit(hit?.id);
      }

      if (
        match[ANALYSIS_KEY_TYPES.CUSTOMER_SUMMARY] &&
        match[ANALYSIS_KEY_TYPES.LOCAL_CUSTOMER_SUMMARY]
      ) {
        updateMatch1LevelFieldLocally(
          match.applicationId,
          ANALYSIS_KEY_TYPES.LOCAL_CUSTOMER_SUMMARY,
          null
        );
      }

      if (
        match[ANALYSIS_KEY_TYPES.ANALYSIS]?.report &&
        match[ANALYSIS_KEY_TYPES.LOCAL_ANALYSIS]
      ) {
        updateMatch1LevelFieldLocally(
          match.applicationId,
          ANALYSIS_KEY_TYPES.LOCAL_ANALYSIS,
          null
        );
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData]);

  const handleSave = async () => {
    const params = { ...argsToUpdate };
    setIsLoading(true);

    if (!hideCustomerSummary && matchSummaryForCustomer.trim()) {
      params.customerSummary = matchSummaryForCustomer.trim();
    }

    if (!hideInternalUse && internalMatchAnalysis?.report?.trim()) {
      params.analysis = { ...internalMatchAnalysis };
    }

    if (
      hideInternalUse &&
      !match[ANALYSIS_KEY_TYPES.ANALYSIS] &&
      internalMatchAnalysis?.report?.trim()
    ) {
      params.analysis = { ...internalMatchAnalysis };
    }

    if (Object.keys(params).length === 0) {
      setIsLoading(false);
      return;
    }

    await updateMatch(match.applicationId, match.jobOpportunityId, params);

    if (!hideCustomerSummary) {
      updateMatch1LevelFieldLocally(
        match.applicationId,
        ANALYSIS_KEY_TYPES.CUSTOMER_SUMMARY,
        params.customerSummary
      );
    }

    if (!hideInternalUse) {
      updateMatch1LevelFieldLocally(
        match.applicationId,
        ANALYSIS_KEY_TYPES.ANALYSIS,
        { ...params.analysis }
      );
    }

    setIsLoading(false);

    setEdit(defaultEditValue);

    if (updated) {
      updated(params);
    }
  };

  const generateMatchAnalysis = async (key, noCache) => {
    setGettingMatchAnalysis(key);

    let response;

    try {
      const { data } = await api.match.generateMatchAnalysis({
        jobOpportunityId: match.jobOpportunityId ?? jobOpp.id,
        userId: match?.userId ?? hit?.id,
        noCache,
      });

      response = data.generateMatchAnalysis;

      if (!key || key === ANALYSIS_KEY_TYPES.ANALYSIS) {
        setInternalMatchAnalysis((prev) => ({
          ...prev,
          report: data.generateMatchAnalysis.report,
          score: data.generateMatchAnalysis.score,
        }));
      }

      if (!key || key === ANALYSIS_KEY_TYPES.CUSTOMER_SUMMARY) {
        setMatchSummaryForCustomer(data.generateMatchAnalysis.summary);
      }
    } catch (err) {
      console.log("something went wrong in generateMatchAnalysis");
      addGraphQLAlert(err);
    }
    fetchingData.current = "";
    setGettingMatchAnalysis("");

    return response;
  };

  const renderOpenAIButton = (key, noCache) => {
    return (
      <div className="absolute -top-2 -right-4">
        <SvgButton
          tooltip="get analysis"
          icon="openAIICon"
          className={classNames("!min-w-[55px] hover:bg-transparent", {
            "animate-spin": gettingMatchAnalysis === key,
            "hover:scale-110": !gettingMatchAnalysis,
          })}
          disabled={!!gettingMatchAnalysis || isLoading}
          onClick={() => generateMatchAnalysis(key, noCache)}
        />
      </div>
    );
  };

  if (fetchingData.current)
    return <p className={loaderClassName}>{fetchingData.current}</p>;

  if (hideCustomerSummary && !internalMatchAnalysis.report) {
    return <p>-</p>;
  }

  return (
    <>
      <div className="flex flex-col gap-2 mt-2">
        {match?.applicationId && !hideEdit && (
          <div title="edit">
            <PencilLine
              className="hover:cursor-pointer w-[20px] h-[20px]"
              onClick={() => {
                if (
                  edit &&
                  ((!hideCustomerSummary &&
                    matchAnalysis.customerSummary &&
                    matchSummaryForCustomer !==
                      matchAnalysis.customerSummary) ||
                    (!hideInternalUse &&
                      matchAnalysis.analysis?.report &&
                      internalMatchAnalysis.report !==
                        matchAnalysis.analysis?.report))
                ) {
                  if (
                    window.confirm(
                      "You have unsaved changes are you sure you want to continue? Current changes will be lost."
                    )
                  ) {
                    setMatchSummaryForCustomer(
                      matchAnalysis.customerSummary ?? ""
                    );
                    setInternalMatchAnalysis({
                      score: matchAnalysis.analysis?.score,
                      report: matchAnalysis.analysis?.report,
                    });
                    setEdit((prev) => !prev);
                  }
                } else {
                  setEdit((prev) => !prev);
                }
              }}
            />
          </div>
        )}
        <div className={classNames({ "overflow-y-auto max-h-[350px]": !edit })}>
          {!hideCustomerSummary && (
            <>
              <p className="font-bold">Summary for customer</p>
              {edit ? (
                <div className="w-full relative">
                  {renderOpenAIButton(
                    ANALYSIS_KEY_TYPES.CUSTOMER_SUMMARY,
                    true
                  )}

                  <TextArea
                    value={matchSummaryForCustomer}
                    onChange={({ target }) =>
                      setMatchSummaryForCustomer(target.value)
                    }
                  />
                </div>
              ) : (
                matchSummaryForCustomer && (
                  <p
                    className="prose  pl-2"
                    dangerouslySetInnerHTML={{
                      __html: getPurifiedParsedMarkDown(
                        matchSummaryForCustomer
                      ),
                    }}
                  />
                )
              )}
            </>
          )}
          {!hideInternalUse && (
            <>
              <p className="font-bold">Report</p>
              {edit ? (
                <div className="w-full relative">
                  {renderOpenAIButton(ANALYSIS_KEY_TYPES.ANALYSIS, true)}

                  <TextArea
                    value={internalMatchAnalysis.report}
                    onChange={({ target }) =>
                      setInternalMatchAnalysis((prev) => ({
                        ...prev,
                        report: target.value,
                      }))
                    }
                  />
                </div>
              ) : (
                internalMatchAnalysis.report && (
                  <p
                    className="prose break-words pl-2"
                    dangerouslySetInnerHTML={{
                      __html: getPurifiedParsedMarkDown(
                        internalMatchAnalysis.report
                      ),
                    }}
                  />
                )
              )}
            </>
          )}
        </div>
        {edit && (
          <div className="flex justify-end mt-2">
            <ActionButton
              className={classNames(
                "text-gray-600 border-b-2 shadow-lg hover:!fill-sky-500 hover:text-sky-500",
                {
                  "animate-pulse opacity-50": isLoading,
                }
              )}
              isReadOnly={isLoading || !!gettingMatchAnalysis}
              isDisabled={isLoading || !!gettingMatchAnalysis}
              action={handleSave}
              type={actionButtonType}
              iconType={actionButtonIcon}
              iconFill="transparent"
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MatchAnalysis;
