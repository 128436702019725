import { Skeleton } from "components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/ui/table";

/**
 * Displays a loading table component
 * @param {string[]} param0 The header text for each column
 */
export default function SkeletonTables({ headers }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {headers.map((h) => (
            <TableHead key={h}>{h}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {new Array(5).fill(0).map((_, index) => (
          <TableRow key={index}>
            {headers.map((h) => (
              <TableCell key={h}>
                <Skeleton className="h-6" />
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
