import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import SvgIcon from "components/base/SvgIcon";

const Collapsible = ({
  children,
  label,
  isVisible,
  onToggleCollapse,
  unMountChildrenWhenNotVisible,
  actionComponent,
  className,
  highLightLabel,
}) => {
  const [visible, setVisible] = useState(isVisible);

  useEffect(() => setVisible(isVisible), [isVisible]);

  const toggleCollapse = () => {
    setVisible((prev) => !prev);
    onToggleCollapse(visible);
  };

  return (
    <div className={classNames(`font-rubik ${className}`)}>
      <div className="w-fit relative">
        <div
          title={visible ? "close" : "open"}
          className={classNames(
            "flex items-center cursor-pointer opacity-80 transition-all select-none hover:opacity-100 w-min",
            { "!opacity-100": visible }
          )}
          onClick={toggleCollapse}
        >
          <SvgIcon
            type="arrowDown"
            className={classNames(
              "transition-all h-3 fill-gray-400",
              {
                "fill-gray-600 ": visible,
              },
              {
                "-rotate-90": !visible,
              }
            )}
          />
          <p
            className={classNames(
              "font-semibold text-lg whitespace-nowrap text-gray-400 uppercase",
              { "bg-[#ffff00] text-gray-900": highLightLabel }
            )}
          >
            {label}
          </p>
        </div>

        {actionComponent}
      </div>

      <div
        className={classNames("mt-2", {
          "h-0 overflow-hidden": !visible,
          "h-auto": visible,
        })}
      >
        {unMountChildrenWhenNotVisible ? visible && children : children}
      </div>
    </div>
  );
};

Collapsible.propTypes = {
  label: PropTypes.string,
  isVisible: PropTypes.bool,
  onToggleCollapse: PropTypes.func,
  className: PropTypes.string,
};

Collapsible.defaultProps = {
  label: "",
  isVisible: false,
  onToggleCollapse: () => {},
  className: "",
};

export default Collapsible;
