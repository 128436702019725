import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import api from "apiSingleton";
import { JobsContext, MatchContext } from "context/providers";

import SvgIcon from "components/base/SvgIcon";

const CognitoGroups = ({ hit, collectionKey }) => {
  const { updateMatchUserFieldLocally } = useContext(MatchContext);
  const { jobOpp } = useContext(JobsContext);

  const [isLoading, setIsLoading] = useState(true);
  const userCognitoGroups = useRef([]);

  useEffect(() => {
    (async () => {
      if (hit.cognitoGroups) {
        userCognitoGroups.current = hit.cognitoGroups || [];
      } else if (jobOpp?.id && collectionKey === "match") {
        const res = await api.user.getCognitoGroupsByUsername({
          username: hit.username || hit.identity_username,
        });
        userCognitoGroups.current = res.data.getCognitoGroupsForUser.groups;
        updateMatchUserFieldLocally(
          hit.match?.applicationId,
          "cognitoGroups",
          userCognitoGroups.current
        );
      }
      setIsLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userCognitoGroups.current?.length === 0 || isLoading) {
    return null;
  }

  return (
    <>
      {userCognitoGroups.current.includes(
        process.env.REACT_APP_COGNITO_GROUP_PROFESSIONAL_COMMUNITY
      ) && (
        <p>
          <SvgIcon
            type="community"
            width="35"
            title="Professional Community"
            heigth="35"
          />
        </p>
      )}
    </>
  );
};

CognitoGroups.propTypes = {
  hit: PropTypes.object,
  collectionKey: PropTypes.string,
};

CognitoGroups.defaultProps = {
  hit: {},
  collectionKey: "",
};

export default CognitoGroups;
