import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Highlight, connectRefinementList } from "react-instantsearch-dom";
import classNames from "classnames";

import Chip from "./Chip";

const StaticSkillOptions = ({
  tittle,
  options,
  items,
  currentRefinement,
  handleChange,
  renderSubOptions,
  showCount,
  isFromSearch,
  subRefinementSkillsExpOptions,
}) => {
  return (
    <>
      {tittle && (
        <p className="tracking-wider text-sm font-semibold my-2">{tittle}</p>
      )}
      {options?.map(({ name }) => {
        const item = {
          ...items.find((e) => e.label === name),
          label: name,
        };
        return (
          <li
            key={item.label}
            className={classNames("mt-1 ais-RefinementList-item", {
              "ais-RefinementList-item--selected":
                item.isRefined || currentRefinement.includes(item.label),
            })}
          >
            <label className="flex items-center text-sm whitespace-nowrap ais-RefinementList-label">
              <input
                type="checkbox"
                className="ais-RefinementList-checkbox"
                checked={
                  item.isRefined || currentRefinement.includes(item.label)
                }
                onChange={(event) =>
                  handleChange(item.label, event.target.checked)
                }
              />
              <span
                className={classNames(
                  "mx-2 overflow-hidden text-ellipsis capitalize",
                  {
                    "font-bold": item.isRefined,
                  }
                )}
              >
                {isFromSearch && item._highlightResult ? (
                  <Highlight attribute="label" hit={item} />
                ) : (
                  item.label
                )}
              </span>
              {showCount && (
                <span className="px-1 border rounded-full bg-gray-100">
                  {item.count}
                </span>
              )}
            </label>
            {subRefinementSkillsExpOptions &&
              renderSubOptions(item, subRefinementSkillsExpOptions)}
          </li>
        );
      })}
    </>
  );
};

const SkillsRefinementList = ({
  items,
  refine,
  searchable,
  isFromSearch,
  searchForItems,
  currentRefinement,
  placeholder,
  limit,
  showChip,
  showCount,
  getSubRefinementSkillsExpCount,
  subRefinementSkillsExpOptions,
  subRefinementSkillsExp,
  subRefinementSkillsExpChange,
  currentRefinementChange,
  skills,
  optionalSkills,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [chips, setChips] = useState(currentRefinement);

  useEffect(() => {
    setChips(
      currentRefinement.filter(
        (e) =>
          !skills.some(({ name }) => name === e) &&
          !optionalSkills.some(({ name }) => name === e)
      )
    );
    currentRefinementChange(currentRefinement);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRefinement.length]);

  const finalItems = useMemo(() => {
    let filteredItems;
    if (expanded && items.length > limit) {
      filteredItems = items;
    }

    filteredItems = items.slice(0, limit + 1);

    return filteredItems.filter(
      ({ label }) =>
        !skills.some(({ name }) => name === label) &&
        !optionalSkills.some(({ name }) => name === label)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded, items, limit]);

  const updateExpanded = () => setExpanded((prev) => !prev);

  const handleChange = (newItem, checked) => {
    let newRefinement;

    if (checked) {
      newRefinement = [...currentRefinement, newItem];
    } else {
      newRefinement = currentRefinement.filter((item) => item !== newItem);
    }

    refine(newRefinement);
  };

  const handleDeleteAll = () => {
    refine([]);
  };

  const formatSkillsExp = (item, option) => {
    return `${item.label}_${option}`;
  };

  const getSubRefinementSkillsExpLabels = (sbElement) => {
    let subRefinementLabels = "";
    if (!!subRefinementSkillsExp?.length) {
      subRefinementLabels = subRefinementSkillsExp
        .filter((subRef) => subRef.startsWith(sbElement))
        .map(
          (subRef) =>
            subRefinementSkillsExpOptions.find(
              (op) => op.value === subRef.split("_")[1]
            ).label
        )
        .join(", ");
    }
    if (subRefinementLabels) {
      return `(${subRefinementLabels})`;
    }
    return subRefinementLabels;
  };

  const renderSubOptions = (item, options, isOptional) => {
    return (
      <div className="flex gap-x-2 ml-8 mt-1 pt-1 pb-2">
        {options.map(({ value, label, className }) => {
          const formattedOptionValue = formatSkillsExp(item, value);
          const itemCount = getSubRefinementSkillsExpCount
            ? getSubRefinementSkillsExpCount(formattedOptionValue)
            : null;
          const isChecked = subRefinementSkillsExp.some((e) =>
            e.includes(formattedOptionValue)
          );
          return (
            <label
              key={formattedOptionValue}
              className="flex items-center text-sm whitespace-nowrap"
            >
              <input
                type="checkbox"
                className=""
                checked={isChecked}
                onChange={(event) =>
                  subRefinementSkillsExpChange(
                    formattedOptionValue,
                    event.target.checked,
                    isOptional
                  )
                }
              />
              <span
                className={classNames(
                  "mx-2 overflow-hidden text-ellipsis !font-normal",
                  {
                    "!font-bold": isChecked,
                  },
                  className
                )}
                title={value}
              >
                {label}
              </span>
              {itemCount && (
                <span className="px-1 border rounded-full bg-gray-100">
                  {itemCount}
                </span>
              )}
            </label>
          );
        })}
      </div>
    );
  };

  const [searchTerm, setSearchTerm] = useState("");

  const searchSkill = (skills) => {
    return skills?.filter(({ name }) => {
      const normalizedItem = name.toLowerCase();
      const normalizedSearchTerm = searchTerm.toLowerCase();

      // Escaping special characters in the search term for the regular expression
      const escapedSearchTerm = normalizedSearchTerm.replace(
        /[.*+?^${}()|[\]\\]/g,
        "\\$&"
      );

      // Creating a regular expression for a case-insensitive search
      const regex = new RegExp(escapedSearchTerm, "gi");

      return regex.test(normalizedItem);
    });
  };

  const filteredReqSkills = searchSkill(skills);

  const filteredOptSkills = searchSkill(optionalSkills);

  return (
    <>
      {searchable && (
        <input
          className="border block w-full px-2 py-1 text-sm"
          type="search"
          placeholder={placeholder}
          onChange={(event) => {
            setSearchTerm(event.target.value);
            searchForItems(event.currentTarget.value);
          }}
        />
      )}
      <div className="flex flex-wrap gap-1 my-3">
        {showChip &&
          chips.map((el) => (
            <Chip
              key={el}
              value={`${el} ${getSubRefinementSkillsExpLabels(el)}`}
              onClose={() => handleChange(el, false)}
              isActive
              className="!bg-sky-600"
            />
          ))}

        {showChip && chips.length > 2 && (
          <Chip
            value="Clear all"
            onClick={handleDeleteAll}
            className="!bg-sky-600"
            isActive
          />
        )}
      </div>
      <ul className="ais-RefinementList-list">
        {filteredReqSkills?.length > 0 && (
          <StaticSkillOptions
            tittle={"Required Skills:"}
            options={filteredReqSkills}
            items={items}
            currentRefinement={currentRefinement}
            handleChange={handleChange}
            renderSubOptions={renderSubOptions}
            showCount={showCount}
            isFromSearch={isFromSearch}
            subRefinementSkillsExpOptions={subRefinementSkillsExpOptions}
          />
        )}

        {filteredOptSkills?.length > 0 && (
          <StaticSkillOptions
            tittle={"Optional Skills:"}
            options={filteredOptSkills}
            items={items}
            currentRefinement={currentRefinement}
            handleChange={handleChange}
            renderSubOptions={renderSubOptions}
            showCount={showCount}
            isFromSearch={isFromSearch}
            subRefinementSkillsExpOptions={subRefinementSkillsExpOptions}
          />
        )}
      </ul>
      {(skills?.length > 0 || optionalSkills?.length > 0) && (
        <div className="border-b-2 w-full my-2 mb-4"> </div>
      )}
      <ul className="ais-RefinementList-list">
        {finalItems.map((item) => (
          <li
            key={item.label}
            className={classNames("mt-1 ais-RefinementList-item", {
              "ais-RefinementList-item--selected": item.isRefined,
            })}
          >
            <label className="flex items-center text-sm whitespace-nowrap ais-RefinementList-label">
              <input
                type="checkbox"
                className="ais-RefinementList-checkbox"
                checked={item.isRefined}
                onChange={(event) =>
                  handleChange(item.label, event.target.checked)
                }
              />
              <span
                className={classNames(
                  "mx-2 overflow-hidden text-ellipsis capitalize",
                  {
                    "font-bold": item.isRefined,
                  }
                )}
              >
                {!isFromSearch ? (
                  item.label
                ) : (
                  <Highlight attribute="label" hit={item} />
                )}
              </span>
              {showCount && (
                <span className="px-1 border rounded-full bg-gray-100">
                  {item.count}
                </span>
              )}
            </label>
            {subRefinementSkillsExpOptions &&
              renderSubOptions(item, subRefinementSkillsExpOptions)}
          </li>
        ))}
      </ul>

      {items.length > limit && (
        <button
          onClick={updateExpanded}
          className="ais-RefinementList-showMore"
        >
          {expanded ? "Show less" : "Show more"}
        </button>
      )}
    </>
  );
};

SkillsRefinementList.propTypes = {
  items: PropTypes.array,
  refine: PropTypes.func,
  isFromSearch: PropTypes.bool,
  searchable: PropTypes.bool,
  searchForItems: PropTypes.func,
  skills: PropTypes.array,
  optionalSkills: PropTypes.array,
  predefinedValue: PropTypes.array,
  currentRefinement: PropTypes.array,
  placeholder: PropTypes.string,
  limit: PropTypes.number,
  showChip: PropTypes.bool,
  showCount: PropTypes.bool,
  subRefinementSkillsExpOptions: PropTypes.array,
  subRefinementSkillsExp: PropTypes.array,
  subRefinementSkillsExpChange: PropTypes.func,
  currentRefinementChange: PropTypes.func,
};

SkillsRefinementList.defaultProps = {
  items: [],
  refine: () => {},
  isFromSearch: false,
  searchable: false,
  searchForItems: () => {},
  skills: [],
  optionalSkills: [],
  predefinedValue: [],
  currentRefinement: [],
  placeholder: "",
  limit: 5,
  showChip: false,
  showCount: true,
  subRefinementSkillsExp: [],
  subRefinementSkillsExpChange: () => {},
  currentRefinementChange: () => {},
};

export default connectRefinementList(SkillsRefinementList);
