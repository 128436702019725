import produce from "immer";

import { contextConstants } from "context/constants";

export const initialState = {
  user: null,
};

export const reducer = produce((draft, action) => {
  const { type, payload } = action;

  switch (type) {
    case contextConstants.auth.USER_UPDATED: {
      draft.user = payload.user;
      break;
    }

    default:
      break;
  }
});
