import React, { useContext, useState } from "react";
import classNames from "classnames";

import api from "apiSingleton";
import { Chip } from "components/SearchFilters/components";
import Button from "components/Button";
import { getCustomerName } from "utils/helpers/jobOppDetails";
import { AlertContext } from "context/providers";
import {
  EMPLOYMENT_TYPES,
  JOB_APPLICATION_MATCH_STATUS,
  JOB_OPPORTUNITY_STATUSES,
} from "lookup";
import SvgIcon from "components/base/SvgIcon";
import DetailsModal from "./DetailsModal";

const SimilarJobCard = ({
  isLoading,
  jobOpp,
  similarJob,
  chipScoreTitle,
  callback,
}) => {
  const { addGraphQLAlert } = useContext(AlertContext);

  const {
    id,
    title,
    vectorSearchScore,
    matches,
    status,
    optionalSkills,
    organization,
    customer,
    minRate,
    maxRate,
    minSalary,
    maxSalary,
    skills,
    employmentType,
  } = similarJob;

  const [disabledButtons, setDisabledButtons] = useState([]);
  const [renderModal, setRenderModal] = useState(false);

  const handlePullCandidates = async (matches, jobId) => {
    const pulledMatches = [];
    setDisabledButtons((prevDisabledButtons) => [
      ...prevDisabledButtons,
      jobId,
    ]);

    for (const match of matches) {
      const { applicationId, userId } = match;
      try {
        await api.match.create({
          input: {
            applicationId,
            jobOpportunityId: jobOpp.id,
            userId,
            status: JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
          },
        });
        pulledMatches.push(match);
      } catch (err) {
        if (
          !err.errors?.[0]?.message.startsWith("The conditional request failed")
        ) {
          addGraphQLAlert(err);
        }
      }
    }

    await callback();

    setDisabledButtons((prevDisabledButtons) => {
      return prevDisabledButtons.filter((id) => id !== jobId);
    });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case JOB_OPPORTUNITY_STATUSES.ACTIVE:
        return `#5523a2`;
      case JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL:
        return `#e7b73c`;
      default:
        return `black`;
    }
  };

  const renderSkills = (skills, jobOppSkills, title) => {
    return (
      <div className="flex gap-1 w-4/5 flex-wrap">
        {title && <p className="font-semibold text-sm mr-2">{title}</p>}

        {skills?.map(({ name }) => {
          const hasJobOppSkill = jobOppSkills?.some((sk) => sk.name === name);
          return (
            <div
              key={`${id}_${name}`}
              title={!hasJobOppSkill ? "Missing skill" : ""}
            >
              <Chip
                className={classNames(
                  "!border",
                  {
                    "!border-red-600 !text-red-600": !hasJobOppSkill,
                  },
                  { "!text-sky-600 !border-sky-600": hasJobOppSkill }
                )}
                key={name}
                value={name}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div
        className={classNames(
          "relative flex flex-col p-4 gap-y-1 bg-white border-2  shadow-lg rounded-md",
          {
            "animate-pulse": disabledButtons.includes(id) || isLoading,
          }
        )}
      >
        <div className="absolute top-0 left-0 flex justify-center items-center gap-x-2">
          <div
            className="w-40 h-8 text-center  rounded-tl-md"
            style={{ background: getStatusColor(status) }}
          >
            <p
              className={classNames("uppercase font-bold text-white", {
                "text-black":
                  status === JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVA,
              })}
            >
              {status}
            </p>
          </div>
          <div>
            <button
              className="flex items-center gap-2 hover:underline disabled:opacity-75"
              onClick={() => setRenderModal(true)}
              disabled={isLoading}
            >
              <SvgIcon
                type="eyeVisible"
                className="w-[20px] hover:!fill-sky-500"
              />
            </button>
          </div>
        </div>

        <div className="flex justify-end items-center">
          <div title="Available candidates">
            <Chip
              value={`Candidates: ${matches.items?.length}`}
              className="text-slate-600 !border-sky-500 font-medium mr-2"
            />
          </div>

          <div title={chipScoreTitle}>
            <Chip
              value={`Similarity score: ${Math.trunc(vectorSearchScore)}%`}
              className="text-slate-600 !border-amber-600  font-medium mr-2"
            />
          </div>
        </div>

        <div className="flex flex-col items-start sm:flex-row sm:items-center justify-start sm:justify-between gap-2">
          <div className="flex flex-col gap-y-1 w-full font-nexa tracking-wider">
            <div className="flex items-end gap-x-2">
              <a
                target="_blank"
                rel="noreferrer"
                href={`/#/jobOpps/${id}/details`}
              >
                <p className="font-bold text-base text-sky-500 underline">
                  {title}
                </p>
              </a>
              -
              {minRate ? (
                <p className="text-base">
                  Hourly Rate: ${minRate?.value}
                  &ndash;${maxRate?.value}
                </p>
              ) : (
                <p className="text-base">
                  Yearly Salary: ${minSalary?.value}
                  &ndash;${maxSalary?.value}
                </p>
              )}
            </div>
            {employmentType && (
              <p className="font-semibold text-sm">
                Employment:{" "}
                <span className="font-normal">
                  {EMPLOYMENT_TYPES[employmentType]}
                </span>
              </p>
            )}
            <p className="font-semibold text-sm">
              Customer Name:{" "}
              <span className="font-normal">
                {getCustomerName({ organization, customer })}
              </span>
            </p>

            <div className="flex flex-col gap-y-2">
              {renderSkills(skills, jobOpp.skills, "Required Skills: ")}

              {optionalSkills?.length > 0 &&
                renderSkills(
                  optionalSkills,
                  jobOpp.optionalSkills,
                  "Optional Skills: "
                )}
            </div>
          </div>

          <div
            className={classNames({
              "animate-pulse": disabledButtons.includes(id),
            })}
          >
            <Button
              isReadOnly={
                (status !== JOB_OPPORTUNITY_STATUSES.ACTIVE &&
                  status !== JOB_OPPORTUNITY_STATUSES.FULFILLED) ||
                !matches.items?.length ||
                disabledButtons.includes(id) ||
                isLoading
              }
              onClick={() => {
                handlePullCandidates(matches.items, id);
              }}
              alt="Go to Job"
            >
              {disabledButtons.includes(id)
                ? "Pulling Candidates..."
                : "Pull Candidates"}
            </Button>
          </div>
        </div>
      </div>
      {renderModal && (
        <DetailsModal
          similarJob={similarJob}
          jobOpp={jobOpp}
          onClose={() => setRenderModal(false)}
          actionButtonCallBack={() => handlePullCandidates(matches.items, id)}
          actionButtonTitle="Pull Candidates"
          actionButtonIsReadOnly={
            (status !== JOB_OPPORTUNITY_STATUSES.ACTIVE &&
              status !== JOB_OPPORTUNITY_STATUSES.FULFILLED) ||
            !matches.items?.length ||
            disabledButtons.includes(id) ||
            isLoading
          }
        />
      )}
    </>
  );
};

export default SimilarJobCard;
