import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom-v5-compat";

import {
  JobsContext,
  MatchContext,
  NotesContext,
  SearchContext,
} from "context/providers";
import { contextActions } from "context/actions";

import Applications from "components/Applications/index";

import JobDetailsPageHeader from "components/JobDetailsPageHeader";
import { DEFAULT_RATE_RANGE, PLACEMENT_FILTERS, USER_TYPES } from "lookup";
import { convertSalaryToRatePerHour } from "utils/helpers/users";

const JobOppDetails = ({ user }) => {
  const params = useParams();

  const {
    searchState,
    setSearchState,
    clearSearchState,
    geographicalRegions,
    setGeographicalRegions,
    clearHitsMatchAnalysis,
    clearAISkillsSearchConfig,
  } = useContext(SearchContext);

  const { loadingJobOpp, jobOpp, initJob, initJobCalendarEvents, clearJob } =
    useContext(JobsContext);

  const { jobOppNotes, job: initJobNotes } = useContext(NotesContext);

  const { clearMatches } = useContext(MatchContext);

  const initNotes = async () => {
    try {
      await initJobNotes(jobOpp);
    } catch (error) {
      console.error("initJobNotes error: ", error);
    }
  };

  const [error, setError] = useState(false);

  const initJobOpp = async () => {
    try {
      await initJob(params.id);
    } catch (error) {
      console.error("initJobOpportunity error: ", error);
    }
  };

  useEffect(() => {
    (async () => {
      await initJobOpp();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!jobOpp && !loadingJobOpp) setError(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobOpp]);

  const prepareInitialJobState = () => {
    const definedRate = {
      min:
        jobOpp.minRate?.value.toFixed(0) ??
        (jobOpp.minSalary
          ? convertSalaryToRatePerHour(jobOpp.minSalary.value)
          : DEFAULT_RATE_RANGE.min),
      max:
        jobOpp.maxRate?.value.toFixed(0) ??
        (jobOpp.maxSalary
          ? convertSalaryToRatePerHour(jobOpp.maxSalary.value)
          : DEFAULT_RATE_RANGE.max),
    };

    const finalState = {
      ...searchState,
      range: {
        "ratePerHour.value": definedRate,
      },
      refinementList: {
        userType: [USER_TYPES.FREELANCER],
        availability: [jobOpp.timeCommitment, "OPENTOOFFERS"],
        "skills.name": jobOpp.skills?.map((skill) => skill.name) || [],
        activeJobTypeTitles: jobOpp.jobType?.title
          ? [jobOpp.jobType.title]
          : [],
      },
      locationFilterStr: "",
      locationFilter: {},
      placementFilterStr: PLACEMENT_FILTERS.notHasActivePlacements,
    };

    // If the job has geographical regions defined, use it
    if (jobOpp.geographicalRegions?.length > 0) {
      // Create a new array based on geographicalRegions with the added 'checked' attribute
      const updatedGeographicalRegions = geographicalRegions.map((region) => {
        const existsInJobOpp = jobOpp.geographicalRegions.some(
          (jobRegion) =>
            jobRegion.regionName === region.regionName &&
            jobRegion.countryNames.length === 0
        );
        return { ...region, checked: existsInJobOpp };
      });

      // Consider only those countries that are mentioned, not all countries in the region
      // Unless, only region is specified, in which case consider all countries in the region
      const selectedCountries = jobOpp.geographicalRegions
        .map((g) => {
          if (g.countryNames.length === 0) {
            const selectedRegion = geographicalRegions.find(
              (gr) => gr.regionName === g.regionName
            );

            if (selectedRegion) {
              return selectedRegion.countryNames;
            }
          }
          return g.countryNames;
        })
        .flat();

      setGeographicalRegions(updatedGeographicalRegions, false);

      finalState.refinementList = {
        ...finalState.refinementList,
        "location.countryName": selectedCountries,
      };
    }

    if (jobOpp.location) {
      finalState.locationFilter = {
        countryName: jobOpp.location.countryName,
        stateName: jobOpp.location.stateName,
        cityName: jobOpp.location.cityName,
        aroundLatLng:
          jobOpp.location.latitude && jobOpp.location.longitude
            ? `${jobOpp.location.latitude}, ${jobOpp.location.longitude}`
            : undefined,
        aroundRadius: 50,
      };

      finalState.locationFilterStr = contextActions.search.getLocationFilterStr(
        finalState.locationFilter
      );

      if (finalState.refinementList?.["location.countryName"]) {
        if (
          !finalState.refinementList["location.countryName"].includes(
            jobOpp.location.countryName
          )
        ) {
          finalState.refinementList?.["location.countryName"].push(
            jobOpp.location.countryName
          );
        }
      } else {
        finalState.refinementList = {
          ...finalState.refinementList,
          "location.countryName": [jobOpp.location.countryName],
        };
      }
    }

    setSearchState(finalState);
  };

  useEffect(() => {
    return () => {
      clearJob();
      clearMatches();
      clearSearchState({ isUnMounting: true });
      clearHitsMatchAnalysis();
      clearAISkillsSearchConfig();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (!loadingJobOpp) {
        document.title = `Torc Search Tool - ${jobOpp.title}`;

        await initJobCalendarEvents(params.id);

        prepareInitialJobState();

        if (!jobOppNotes[jobOpp.id]) {
          initNotes();
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingJobOpp]);

  if (loadingJobOpp) {
    return (
      <div className="flex justify-center pt-8">
        <span className="loader"></span>
      </div>
    );
  }

  return (
    <>
      {error || !jobOpp ? (
        <div className="flex justify-center pt-8">
          <p className="text-red-400 text-xl">
            The job opportunity could not be fetched successfully. Please try
            again. If problem persists, contact administrator about this issue.
          </p>
        </div>
      ) : (
        <>
          <JobDetailsPageHeader
            jobOpp={jobOpp}
            user={user}
            searchState={searchState}
            setSearchState={setSearchState}
          />
          <Applications user={user} />
        </>
      )}
    </>
  );
};

export default JobOppDetails;
