export const MATCHES_LOADED = "MATCHES_LOADED";
export const MATCH_1_LEVEL_FIELD_UPDATED = "MATCH_1_LEVEL_FIELD_UPDATED";
export const MATCH_USER_FIELD_UPDATED = "MATCH_USER_FIELD_UPDATED";
export const BEST_FIT_LOADED = "BEST_FIT_LOADED";
export const BEST_FIT_FIELD_UPDATED = "BEST_FIT_FIELD_UPDATED";
export const IDEAL_CANDIDATE_FIELD_UPDATED = "IDEAL_CANDIDATE_FIELD_UPDATED";
export const MAPPED_HITS_MATCHES_BEST_FIT_UPDATED =
  "MAPPED_HITS_MATCHES_BEST_FIT_UPDATED";
export const IDEAL_CANDIDATES_LOADED = "IDEAL_CANDIDATES_LOADED";
export const LOADING_IDEAL_CANDIDATES = "LOADING_IDEAL_CANDIDATES";
