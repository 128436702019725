import { gql } from "@apollo/client";

export const GET_FIELD_HISTORY = gql`
  query GetFieldHistory(
    $entity: String
    $key: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFieldHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getFieldHistory(
      entity: $entity
      key: $key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        oldValue
        newValue
        creator
        createdAt
      }
    }
  }
`;
