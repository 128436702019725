import { useEffect, useMemo, useState } from "react";
import Autosuggest from "react-autosuggest";
import {
  Configure,
  connectAutoComplete,
  InstantSearch,
} from "react-instantsearch-dom";
import algoliasearch from "algoliasearch";
import classNames from "classnames";
import PropTypes from "prop-types";

import styles from "./SearchLocationWidget.module.css";
import HelpText from "components/HelpText";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_API_KEY
);

function Autocomplete({
  currentRefinement,
  hits,
  refine,
  helpText,
  onSuggestionSelected,
}) {
  const [searchValue, setSearchValue] = useState(currentRefinement);

  useEffect(() => {
    setSearchValue(currentRefinement);
  }, [currentRefinement]);

  const onChange = (event, { newValue }) => {
    setSearchValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    refine(value);
  };

  const onSuggestionsClearRequested = () => {
    // onSuggestionsClearRequested needs to be implemented
    // but calling the function below in the body results
    // in the input value being lost (due to InstantSearch sending back the empty value)
    // Hence this is empty function for now
    // refine();
  };

  const getSuggestionValue = (hit) => {
    return `${hit.country_name}, ${hit.state_name}, ${hit.name}`;
  };

  const renderSuggestion = (hit) => {
    return (
      <span className="cursor-pointer">
        {hit.country_name}, {hit.state_name}, {hit.name}
      </span>
    );
  };

  const renderInputComponent = (inputProps, helpText) => {
    return (
      <div className="flex flex-col gap-y-2">
        <div className={classNames("flex justify-end w-full")}>
          {helpText && (
            <HelpText
              text={helpText}
              className="ml-2"
              toolTipClassName="!-translate-x-full ml-4"
            />
          )}
        </div>
        <input
          {...inputProps}
          onChange={({ target }) => onSuggestionsFetchRequested(target)}
          id="locationSearch"
          placeholder="City Search..."
          type="search"
          className="border block w-full px-2 py-1 text-sm"
        />
      </div>
    );
  };

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    if (!children) {
      return <div {...containerProps}>{children}</div>;
    }

    return (
      <div
        {...containerProps}
        className={classNames(containerProps.className, styles.searchBorder)}
      >
        {children}
      </div>
    );
  };

  const inputProps = {
    onChange,
    value: searchValue,
  };

  return (
    <Autosuggest
      suggestions={hits}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
      renderInputComponent={(e) => renderInputComponent(e, helpText)}
      renderSuggestionsContainer={renderSuggestionsContainer}
      onSuggestionSelected={onSuggestionSelected}
      focusInputOnSuggestionClick={false}
    />
  );
}

const CustomAutocomplete = connectAutoComplete(Autocomplete);

export default function SearchLocationsWidget({ locationChange, helpText }) {
  const [search, setSearch] = useState({ query: "", page: 1 });

  const indexName = useMemo(() => {
    return process.env.REACT_APP_ALGOLIA_INDEX_NAME;
  }, []);

  const onSuggestionSelected = (e, { suggestion }) => {
    const selection = JSON.parse(JSON.stringify(suggestion));
    delete selection.__position;
    delete selection.objectID;
    delete selection._highlightResult;

    locationChange({
      aroundLatLng: `${selection.latitude}, ${selection.longitude}`,
      countryName: selection.country_name,
      stateName: selection.state_name,
      cityName: selection.name,
    });
    setSearch({ query: "", page: 1 });
  };

  const bannedCountriesFilter = useMemo(() => {
    try {
      return (
        JSON.parse(process.env.REACT_APP_BANNED_COUNTRIES)
          ?.map((e) => `(NOT country_name:"${e}")`)
          .join(" AND ") || ""
      );
    } catch (err) {
      return "";
    }
  }, []);

  return (
    <div className="relative">
      <InstantSearch
        searchClient={searchClient}
        indexName={indexName}
        searchState={search}
        onSearchStateChange={setSearch}
      >
        <CustomAutocomplete
          onSuggestionSelected={onSuggestionSelected}
          helpText={helpText}
        />
        <Configure filters={bannedCountriesFilter} />
      </InstantSearch>
    </div>
  );
}

SearchLocationsWidget.propTypes = {
  locationChange: PropTypes.func,
  helpText: PropTypes.string,
};
