import classNames from "classnames";

export function JobField({ label, value }) {
  return (
    <div className="flex flex-col gap-y-2 w-1/2 sm:w-min">
      <p className="uppercase text-sm text-gray-400 font-bold">{label}</p>
      <p
        className={classNames(
          {
            "inline-block lowercase first-letter:uppercase": label === "Status",
          },
          "text-sm"
        )}
      >
        {value}
      </p>
    </div>
  );
}
