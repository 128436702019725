import React, { useCallback, useContext, useState } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";

import { CUSTOM_LINK_TYPES } from "lookup";
import { validateOtherLink } from "utils/validation";
import { MatchContext, SearchContext } from "context/providers";

import Modal, { FormModal } from "components/Modals";

const LINK_SUCCESS_MESSAGE = "Link successfully added";

const LINK_DEFAULT_INPUTS = {
  name: {
    type: "input",
    label: "Name",
    value: "",
    placeholder: "Name of link",
    isRequired: true,
    errorMessage: "",
  },
  value: {
    type: "input",
    label: "Value",
    value: "",
    placeholder: "Valid URL",
    isRequired: true,
    errorMessage: "",
  },
  description: {
    type: "input",
    label: "Description",
    value: "",
    placeholder: "Describe link",
    isRequired: false,
    errorMessage: "",
  },
  type: {
    type: "select",
    label: "Link type",
    value: CUSTOM_LINK_TYPES.EXTERNALLINK,
    options: [
      { label: "External", value: CUSTOM_LINK_TYPES.EXTERNALLINK },
      { label: "General", value: CUSTOM_LINK_TYPES.GENERAL },
      { label: "Result", value: CUSTOM_LINK_TYPES.RESULT },
      { label: "Resume", value: CUSTOM_LINK_TYPES.RESUME },
      { label: "Other", value: CUSTOM_LINK_TYPES.OTHER },
    ],
    isRequired: false,
  },
  visibility: {
    type: "select",
    label: "Link visibility",
    value: "CUSTOMER",
    options: [
      { label: "Customer", value: "CUSTOMER" },
      { label: "Public", value: "PUBLIC" },
      { label: "Private", value: "PRIVATE" },
    ],
    isRequired: false,
  },
};

const CustomLinkModal = ({
  user,
  modalTarget,
  applicationId,
  collectionKey,
  onClose,
}) => {
  const { updateHit } = useContext(SearchContext);
  const { updateMatchFieldLocally, updateFitFieldLocally } =
    useContext(MatchContext);

  const [customLinkData, setCustomLinkData] = useState({
    successMessage: "",
    errorMessage: "",
    isSubmitting: false,
    inputs: cloneDeep(LINK_DEFAULT_INPUTS),
  });

  const clearOtherLinkData = () => {
    onClose();
    setCustomLinkData((prev) => ({ ...prev, inputs: LINK_DEFAULT_INPUTS }));
  };

  const handleOtherLinkInputChange = useCallback(({ value, valueKey }) => {
    setCustomLinkData((prev) => {
      const inputs = {
        ...prev.inputs,
        [valueKey]: { ...prev.inputs[valueKey], value, errorMessage: "" },
      };

      return { ...prev, inputs };
    });
  }, []);

  const prepareOtherLinksInputs = useCallback(() => {
    const output = {};

    Object.keys(customLinkData.inputs).forEach(
      (inputKey) => (output[inputKey] = customLinkData.inputs[inputKey].value)
    );

    return output;
  }, [customLinkData.inputs]);

  const updateOtherLinksByKey = (key, value) => {
    setCustomLinkData((prev) => ({ ...prev, [key]: value }));
  };

  const addOtherLink = useCallback(
    async (linkData) => {
      try {
        const { id } = user;
        const otherLinks = user.otherLinks || [];
        const newOtherLinks = [...otherLinks, linkData];

        updateOtherLinksByKey("isSubmitting", true);
        updateOtherLinksByKey("errorMessage", "");

        await updateHit({
          input: {
            id,
            otherLinks: newOtherLinks,
          },
        });

        updateOtherLinksByKey("successMessage", LINK_SUCCESS_MESSAGE);

        if (modalTarget === "Match") {
          updateMatchFieldLocally(
            applicationId,
            "user.otherLinks",
            newOtherLinks
          );
        }

        if (collectionKey === "bestFit") {
          updateFitFieldLocally(id, "otherLinks", newOtherLinks);
        }
      } catch (error) {
        console.warn("addOtherLink error", error);

        updateOtherLinksByKey(
          "errorMessage",
          error?.errors?.[0]?.message || "Something went wrong"
        );
      }

      updateOtherLinksByKey("isSubmitting", false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user]
  );

  const setOtherLinksErrors = useCallback((errors) => {
    setCustomLinkData((prev) => {
      const inputs = {
        ...prev.inputs,
      };

      Object.keys(errors).forEach(
        (errorKey) => (inputs[errorKey].errorMessage = errors[errorKey])
      );

      return { ...prev, inputs };
    });
  }, []);
  const submitOtherLinksModal = useCallback(() => {
    const data = prepareOtherLinksInputs();

    validateOtherLink({
      data,
      onSuccess: (validData) => addOtherLink(validData),
      onError: (errors) => setOtherLinksErrors(errors),
    });
  }, [prepareOtherLinksInputs, setOtherLinksErrors, addOtherLink]);

  return (
    <Modal
      isVisible={user}
      title="Fill the form to add other link"
      className="!w-[80vw] lg:!w-[40vw] !h-[85vh] max-h-[75vh]"
      onClose={clearOtherLinkData}
    >
      <FormModal
        successMessage={customLinkData.successMessage}
        errorMessage={customLinkData.errorMessage}
        isSubmitting={customLinkData.isSubmitting}
        inputs={customLinkData.inputs}
        onInputChange={handleOtherLinkInputChange}
        onSubmit={submitOtherLinksModal}
        onCancel={clearOtherLinkData}
      />
    </Modal>
  );
};

CustomLinkModal.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  modalTarget: PropTypes.string.isRequired,
  applicationId: PropTypes.string,
  collectionKey: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

CustomLinkModal.defaultProps = {
  user: null,
  applicationId: "",
  collectionKey: "hits",
};

export default CustomLinkModal;
