import React, { useContext, useMemo } from "react";
import classNames from "classnames";

import { JobsContext, ModalContext, SearchContext } from "context/providers";

import ActionButtonsPanel from "components/ActionButtonsPanel";
import SearchFilters from "components/SearchFilters";
import ApplicationsTabs from "./components/ApplicationsTabs";
import SavedSearches from "components/SavedSearches";
import UserCardList from "components/UserCardList";

import algoliaLogo from "images/algolia-logo.png";

import { useLocation } from "react-router-dom-v5-compat";
import IdealFitFilter from "components/SearchFilters/idealFitFilters";
import clsx from "clsx";
import { TABS } from "context/constants/common";
import { X } from "lucide-react";

import "./index.css";

const CustomSearchBox = ({ onChange, onClear, value, className }) => {
  return (
    <div className={classNames("ais-SearchBox w-full", className)}>
      <div className="ais-SearchBox-form">
        <input
          type="search"
          placeholder="Search..."
          className="ais-SearchBox-input"
          onChange={onChange}
          value={value}
        />
        {value && (
          <button
            type="reset"
            title="Clear the search query"
            className="ais-SearchBox-reset !right-0.5 w-[18px]"
            onClick={onClear}
          >
            <X color="#484c7a" />
          </button>
        )}
      </div>
    </div>
  );
};

// https://stackoverflow.com/a/65050864/2104976
// Each widget is responsibile for its own state
// We cannot set the default value for job title
// if the refinement list does not exist
// But we don't want to show the refinement list for job title
// So we are creating this "virtual" refinement list

const Applications = ({ user }) => {
  const { showModal } = useContext(ModalContext);
  const { jobOpp, jobCalendarEvents } = useContext(JobsContext);
  const {
    searchState,
    setSearchState,
    clearSearchState,
    AISkillSearchConfig,
    handleSkillSearchEnabledChange,
  } = useContext(SearchContext);

  const isJobPage = useMemo(() => !!jobOpp.id, [jobOpp]);
  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const currentTab = params.get("tab");

  const clearOptionalWords = () => {
    const finalState = {
      ...searchState,
      configProps: {
        ...searchState.configProps,
        optionalWords: [],
      },
    };
    setSearchState(finalState);
  };

  return (
    <div
      className={classNames("w-full flex flex-column", {
        "!border-t-2": isJobPage,
      })}
    >
      <div className="w-1/4 border-r-2 pt-2">
        <div className="sticky top-0 pt-3 z-10">
          <div
            className={clsx({
              hidden: currentTab !== TABS.IDEALFIT,
            })}
          >
            <IdealFitFilter />
          </div>
          <div
            className={clsx({
              hidden: currentTab === TABS.IDEALFIT,
            })}
          >
            <div className="px-4 mb-3.5">
              <img
                className="h-[18.86px] w-[82.8px] mb-2"
                src={algoliaLogo}
                alt="algolia logo"
              />
              <div className="flex justify-end">
                <span
                  className="mb-4 pl-2 text-sky-500 block py-0 w-min whitespace-nowrap cursor-pointer opacity-70 transition-all select-none hover:opacity-100 underline hover:no-underline"
                  onClick={clearSearchState}
                >
                  Clear Filters
                </span>
              </div>

              {AISkillSearchConfig.enabled && !AISkillSearchConfig.loading && (
                <span
                  className="text-sky-500 text-sm capitalize whitespace-nowrap cursor-pointer opacity-70 transition-all select-none hover:opacity-100 underline hover:no-underline"
                  onClick={() => {
                    handleSkillSearchEnabledChange(true, true);
                  }}
                >
                  AI Reset
                </span>
              )}

              <div>
                {AISkillSearchConfig.enabled && (
                  <label className="text-sm mt-2 text-gray-600">Required</label>
                )}
                <CustomSearchBox
                  onChange={({ target }) => {
                    setSearchState({
                      ...searchState,
                      customSearchQuery: target.value,
                    });
                  }}
                  onClear={() => {
                    setSearchState({
                      ...searchState,
                      customSearchQuery: "",
                    });
                  }}
                  value={searchState.customSearchQuery || ""}
                />
              </div>

              {AISkillSearchConfig.enabled && (
                <div className="mt-1">
                  <label className="text-sm mt-2 text-gray-600">Optional</label>
                  <CustomSearchBox
                    value={searchState.configProps?.optionalWords || ""}
                    onChange={({ target }) => {
                      setSearchState({
                        ...searchState,
                        configProps: {
                          ...searchState.configProps,
                          optionalWords: target.value,
                        },
                      });
                    }}
                    onClear={clearOptionalWords}
                  />
                </div>
              )}
            </div>

            <div className="px-4 border-b-2">
              <SavedSearches />
            </div>

            <div className="overflow-y-scroll h-[72vh] lg:h-[75vh] overflow-x-hidden px-2 customScrollBar mt-2">
              <SearchFilters />
            </div>
          </div>
        </div>
      </div>

      <div className="w-[70%]">
        {isJobPage ? (
          <ApplicationsTabs />
        ) : (
          <UserCardList collectionKey="hits" pagination stats />
        )}
      </div>

      <div className="w-[5%] border-l">
        <ActionButtonsPanel
          isJobPage={isJobPage}
          user={user}
          jobCalendarEvents={jobCalendarEvents}
          showModal={showModal}
        />
      </div>
    </div>
  );
};

export default Applications;
