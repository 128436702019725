import React, { useContext, useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { cloneDeep } from "lodash";
import classNames from "classnames";

import api from "apiSingleton";
import { JobsContext, AlertContext } from "context/providers";

import Modal from "components/Modals";
import ActionButton from "components/base/ActionButton";
import TableWithPagination from "components/base/TableWithPagination";
import styles from "./index.module.css";
import SvgIcon from "components/base/SvgIcon";
import { getUserName } from "utils/helpers/users";

const AssessmentModal = ({ user, onClose }) => {
  const { jobOpp } = useContext(JobsContext);
  const { addGraphQLAlert } = useContext(AlertContext);
  const [assessmentsRows, setAssessmentsRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const assessmentsData = useRef([]);
  const userSharedAssessments = useRef([]);

  const clonedUser = cloneDeep(user);

  const mapAssessments = (assessmentsData) => {
    return assessmentsData
      .filter(({ isClosed }) => !isClosed)
      .map((a) => ({
        ...a,
        isShared: userSharedAssessments.current.some(
          ({ testId }) => testId === a.testId
        ),
        takenA: clonedUser.assessments?.find(
          ({ testId }) => testId === a.testId
        ),
      }));
  };

  const prepareScore = (item) => {
    return `${item.finalScore || 0} (Code - ${item.codeScore || 0}, MCQ - ${
      item.multipleChoiceScore || 0
    })`;
  };

  const renderAssessments = (assessment) => {
    return (
      <div
        className="flex flex-col font-rubik-medium text-sm w-full"
        key={assessment.id}
      >
        <div className="flex gap-2 justify-between">
          <p className="w-auto whitespace-nowrap">{assessment.status}</p>

          <p className="w-auto whitespace-nowrap">{prepareScore(assessment)}</p>
        </div>
      </div>
    );
  };

  const formatTestNameCell = ({ testName, takenA }) => {
    return !takenA
      ? {
          value: testName,
          content: <p title={testName}>{testName}</p>,
        }
      : {
          value: testName,
          content: (
            <div className={classNames(styles.tooltip)}>
              <a
                href={takenA.reportLink}
                target="_blank"
                rel="noreferrer"
                title={takenA.testName}
                className={classNames(
                  "whitespace-nowrap overflow-ellipsis overflow-hidden",
                  { "text-blue-500": !!takenA.reportLink }
                )}
              >
                {takenA.testName}
              </a>
              <div
                className={classNames(
                  styles["tooltip-content"],
                  "bg-white p-4 rounded-md border-2 absolute mt -ml-1 shadow-lg z-50"
                )}
              >
                <div className="flex flex-wrap">
                  {renderAssessments(takenA)}
                </div>
              </div>
            </div>
          ),
        };
  };

  const formatActionCell = ({ isShared, takenA, link, testId, testName }) => {
    return jobOpp.id ? (
      <div className="w-full flex gap-x-4 items-center justify-center">
        <ActionButton
          action={() => handleShareAssessmentAction({ testId, link, testName })}
          title={`Send to: ${getUserName(user)}`}
          className={classNames(
            "text-gray-600 hover:text-sky-500 shadow-lg border-b-2 hover:border-sky-500  w-[87px] text-center",
            { "opacity-70": isShared || !!takenA }
          )}
          text={isShared || !!takenA ? "Resend" : "Send"}
          loadingText={"Sending..."}
        />
        <a
          href={link}
          title={link}
          target="_blank"
          rel="noreferrer"
          className="text-blue-500"
        >
          <SvgIcon type={"GENERAL"} className="w-[20px] h-[20px]" />
        </a>
      </div>
    ) : (
      <p className="text-center">-</p>
    );
  };

  const mapAssessmentRows = (assessments) => {
    return assessments.map((item) => {
      return {
        testName: formatTestNameCell(item),
        action: formatActionCell(item),
        isShared: {
          value: item.isShared,
          hidden: true,
        },
        isTaken: {
          value: !!item.takenA,
          hidden: true,
        },
      };
    });
  };

  const handleShareAssessmentAction = async ({ testId, link, testName }) => {
    let response;
    try {
      const { data } = await api.user.shareAssessmentMutation({
        input: {
          userId: clonedUser.id,
          testName,
          testId,
          link,
          jobOpportunityId: jobOpp.id,
        },
      });
      response = data.createSharedAssessment.message === "SUCCESS";
    } catch (err) {
      console.log("Something went wrong on send assessment", err);
      addGraphQLAlert(err);
    }

    if (!response) return;
    const assessmentsDataCopy = [...assessmentsData.current];
    const index = assessmentsDataCopy.findIndex(
      (assessment) => assessment.testId === testId
    );

    if (index !== -1) {
      assessmentsDataCopy[index] = {
        ...assessmentsDataCopy[index],
        isShared: true,
      };
      assessmentsData.current = assessmentsDataCopy;

      const mappedRowAssessments = mapAssessmentRows(assessmentsDataCopy);
      setAssessmentsRows(mappedRowAssessments);
    }
  };

  const getUserSharedAssessments = async (userId) => {
    try {
      const { data } = await api.user.getSharedAssessments({
        userId,
      });
      return data.listSharedAssessmentsByUser.items;
    } catch (err) {
      console.log("Something went wrong getting user shared assessments", err);
      addGraphQLAlert(err);
    }
  };

  const getAvailableAssessments = async () => {
    try {
      return (await api.common.listCoderbyteAssessments()).data
        .listCoderbyteAssessments;
    } catch (err) {
      console.log("Something went wrong listCoderbyteAssessments", err);
      addGraphQLAlert(err);
    }
  };

  const initContent = async () => {
    assessmentsData.current = await getAvailableAssessments();

    userSharedAssessments.current = await getUserSharedAssessments(
      clonedUser.id
    );
    assessmentsData.current = mapAssessments(assessmentsData.current);

    const mappedRowAssessments = mapAssessmentRows(
      assessmentsData.current,
      userSharedAssessments
    );

    setAssessmentsRows(mappedRowAssessments);
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await initContent();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isVisible={clonedUser}
      title="Send Assessments"
      subTitle={`User: ${getUserName(clonedUser)}`}
      onClose={onClose}
      className="min-h-[75vh] h-fit max-h-fit border-2 overflow-x-hidden xl:!w-7/12 md:!w-10/12 !w-[95%]"
    >
      <div>
        {isLoading && (
          <div className="flex items-center justify-center p-4">
            <div className="loader" />
          </div>
        )}

        {!isLoading && (
          <div className="overflow-scroll-auto overflow-x-hidden">
            <TableWithPagination
              title="Send Assessments"
              searchPlaceHolder="Search by name"
              filters={[
                { label: "Taken", key: "isTaken" },
                { label: "Sent", key: "isShared" },
              ]}
              type="table"
              rowsPerPage={5}
              currentPage={0}
              header={["Name", "Action"]}
              searchField="testName"
              rows={assessmentsRows}
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

AssessmentModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

AssessmentModal.defaultProps = {
  user: null,
};

export default AssessmentModal;
