import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Editor, { Plugins } from "react-markdown-editor-lite";

import "react-markdown-editor-lite/lib/index.css";
import { getPurifiedParsedMarkDown } from "utils/markdown";

Editor.unuse(Plugins.Header);
Editor.unuse(Plugins.FontStrikethrough);
Editor.unuse(Plugins.BlockQuote);
Editor.unuse(Plugins.BlockWrap);
Editor.unuse(Plugins.BlockCodeInline);
Editor.unuse(Plugins.BlockCodeBlock);
Editor.unuse(Plugins.Image);
Editor.unuse(Plugins.FullScreen);
Editor.unuse(Plugins.ListOrdered);
Editor.unuse(Plugins.ListUnordered);
Editor.unuse(Plugins.Table);
Editor.unuse(Plugins.Link);

const RichTextEditor = ({ value, valueKey, onChange }) => {
  const [content, setContent] = useState("");

  useEffect(() => setContent(value), [value]);

  const handleChange = ({ text }) => {
    setContent(text);
    onChange(text, valueKey);
  };

  return (
    <Editor
      style={{ width: "100%", height: "150px" }}
      renderHTML={(text) => getPurifiedParsedMarkDown(text)}
      value={value || content}
      onChange={handleChange}
      view={{ menu: true, md: true, html: false }}
    />
  );
};

RichTextEditor.propTypes = {
  value: PropTypes.string,
  valueKey: PropTypes.string,
  onChange: PropTypes.func,
};

RichTextEditor.defaultProps = {
  value: "",
  valueKey: "",
  onChange: () => {},
};

export default RichTextEditor;
