import React, { useContext, useMemo } from "react";
import ResumeUpload from "../Resume";
import SvgButton from "components/base/SvgButton";
import { APPLICATION_TAB_NAMES, JOB_APPLICATION_MATCH_STATUS } from "lookup";
import DropDownMenu from "components/DropDownMenu";
import { Dialog, DialogTrigger } from "components/ui/dialog";
import { AuthContext, MatchContext } from "context/providers";
import UserInfoModalV2 from "containers/ModalContainer/modals/UserInfoModalv2";
import SvgIcon from "components/base/SvgIcon";

const UserActionIcons = ({
  hit,
  collectionKey,
  activeTabName,
  updateHit,
  showModal,
}) => {
  const { user } = useContext(AuthContext);

  const isAdmin = useMemo(
    () =>
      user.attributes.groups.includes(
        process.env.REACT_APP_COGNITO_ADMIN_GROUP
      ),
    [user]
  );

  const {
    updateMatchUserFieldLocally,
    matches,
    updateFitFieldLocally,
    bestFit,
  } = useContext(MatchContext);

  const saveAttributes = async (attributes) => {
    const attributesToUpdate = {
      input: {
        id: hit.id,
        ...attributes,
      },
    };
    try {
      await updateHit(attributesToUpdate);

      if (bestFit.find(({ id }) => id === hit.id)) {
        updateFitFieldLocally(
          hit.id,
          "resumeLocation",
          attributes.resumeLocation
        );
      }

      if (matches.length > 0) {
        const applicationId =
          hit.match?.applicationId ||
          matches.find(({ userId }) => userId === hit.id)?.applicationId;

        if (!applicationId) {
          return;
        }

        updateMatchUserFieldLocally(
          applicationId,
          "resumeLocation",
          attributes.resumeLocation
        );
      }
    } catch (error) {
      console.log("Error when updating profile info", error);
    }
  };

  const showUserInfoModal = () => {
    showModal({ type: "userInfo", userId: hit.id });
  };

  const CustomLink = ({ url, label }) => (
    <a target="_blank" rel="noreferrer" href={url}>
      <span className="w-full text-center">{label}</span>
    </a>
  );

  const menuOptions = [
    collectionKey === "match" &&
      hit.match?.status !== JOB_APPLICATION_MATCH_STATUS.SHORTLISTED &&
      hit.match?.status !== JOB_APPLICATION_MATCH_STATUS.SKIPPED && (
        <p
          onClick={() =>
            showModal({
              type: "match",
              applicationId: hit.match?.applicationId,
              jobOpportunityId: hit.match?.jobOpportunityId,
              freelancerPitchPrefill: hit.match?.freelancerPitchPrefill,
              hitStatus: hit.match?.status,
              freelancerPitch: hit.match?.freelancerPitch,
              isUpdate: true,
              hideMatchAnalysis: true,
            })
          }
        >
          Candidate Questions
        </p>
      ),
    <p
      onClick={() =>
        showModal({
          type: "link",
          userId: hit.id,
          applicationId: hit?.match?.applicationId,
          collectionKey,
        })
      }
    >
      Add Link
    </p>,
    isAdmin && hit.username && (
      <CustomLink
        url={`https://admin.torc.dev/users/${hit.username}`}
        label={"Admin Tool"}
      />
    ),
    <CustomLink
      url={`${process.env.REACT_APP_SFDC_BASE_URL}/torcUserRedirect?id=${hit.id}`}
      label={"SFDC User"}
    />,
    activeTabName === APPLICATION_TAB_NAMES.MATCHED && (
      <CustomLink
        url={`${process.env.REACT_APP_SFDC_BASE_URL}/customFlowOperation?platformmatchid=${hit?.match?.id}`}
        label={"SFDC Match"}
      />
    ),
  ];

  return (
    <div className="flex items-center gap-x  md:gap-x-2">
      <a
        className="flex justify-center text-sm transform transition duration-500 hover:scale-110"
        href={`mailto:${hit.email}`}
        target="_blank"
        rel="noreferrer"
      >
        <SvgButton icon="email" />
      </a>

      {hit?.phone?.number ? (
        <a
          className="flex justify-center text-sm transform transition duration-500 hover:scale-110"
          href={`tel:${hit.phone.number}`}
          target="_blank"
          rel="noreferrer"
        >
          <SvgButton icon="phone" />
        </a>
      ) : null}

      {hit?.phone?.whatsAppAllowed ? (
        <a
          className="flex justify-center text-sm transform transition duration-500 hover:scale-110"
          href={`whatsapp://send?phone=${hit.phone.number}`}
          target="_blank"
          rel="noreferrer"
        >
          <SvgButton icon="whatsapp" />
        </a>
      ) : null}
      <SvgButton
        icon="openBook"
        tooltip="assessments"
        onClick={() => showModal({ type: "assessment", userId: hit.id })}
      />
      <ResumeUpload user={hit} save={saveAttributes} />
      {hit.match?.applicationId ? (
        <Dialog>
          <DialogTrigger>
            <SvgIcon
              type="info"
              className="fill-gray-500 min-w-[30px] min-h-[30px] max-w-[30px] max-h-[30px] lg:min-w-[35px] lg:min-h-[35px] lg:max-w-[35px] lg:max-h-[35px] p-[5px] box-border hover:bg-gray-300 transition-all rounded-full flex justify-center items-center relative"
            />
          </DialogTrigger>

          <UserInfoModalV2 match={hit.match} user={hit} />
        </Dialog>
      ) : (
        <SvgButton icon="info" onClick={showUserInfoModal} />
      )}
      <div className="bg-gray-400 !w-[2px] h-[18px]"></div>

      <DropDownMenu options={menuOptions.filter((e) => e)} />
    </div>
  );
};

export default UserActionIcons;
