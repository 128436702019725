import { JOB_OPPORTUNITY_STATUSES } from "lookup";

export const getCustomerName = (jobOpp = {}) => {
  const organizationName = jobOpp?.organization;
  const customerName =
    jobOpp?.customer?.companyDetails?.name || jobOpp?.customer?.company;

  return organizationName || customerName;
};

export const formatJobStatus = (status) => {
  let txt = status;

  switch (status) {
    case JOB_OPPORTUNITY_STATUSES.PENDINGAPPROVAL:
      txt = "PENDING";
      break;
    default:
      txt = status;
      break;
  }

  return txt;
};
