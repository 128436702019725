/**
 * Wrapper over <MatchAnalysis /> component - to fetch latest data on a Match record
 */
import { useSuspenseQuery } from "@apollo/client";
import { GET_MATCH_ANALYSIS } from "api/graphql/match";
import MatchAnalysis from "components/MatchAnalysis";
import { MatchContext } from "context/providers";
import { useContext, useEffect, useRef, useState } from "react";

export default function MatchAnalysisWrapper({
  applicationId,
  jobOpportunityId,
}) {
  const { matches } = useContext(MatchContext);
  const [match, setMatch] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const exitingMatch = useRef();

  const { error, data } = useSuspenseQuery(GET_MATCH_ANALYSIS, {
    variables: {
      applicationId,
      jobOpportunityId,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    exitingMatch.current = matches?.find(
      (m) => m.applicationId === applicationId
    );

    if (!data.getMatch?.analysis?.report) {
      setMatch({
        ...exitingMatch.current,
      });
    } else {
      setMatch({
        ...data.getMatch,
      });
    }

    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, applicationId]);

  if (error) {
    return (
      <p>
        An error occurred fetching match analysis data for the Match. Refresh
        the page and if the error persists, contact Torc Support
      </p>
    );
  }

  if (!match.applicationId || isLoading) {
    return;
  }

  return (
    <MatchAnalysis
      loaderClassName="flex justify-center items-center w-full min-h-[325px]"
      match={match}
      updated={(attrs) => {
        setMatch((prev) => ({ ...prev, ...attrs }));
      }}
      useSuspenseQuery={useSuspenseQuery}
      matchAnalysis={{
        customerSummary: match?.customerSummary || match?.localCustomerSummary,
        analysis: {
          score: match?.analysis?.score ?? match?.localAnalysis?.score,
          report: match?.analysis?.report || match?.localAnalysis?.report,
        },
      }}
    />
  );
}
