/**
 * Component that loads the cloudinary widget
 */

import React, { useRef, useState } from "react";
import Modal from "components/Modals";

import { ReactComponent as Trash } from "images/trash.svg";
import ActionButton from "components/base/ActionButton";
import UploadButton from "components/base/UploadButton";

/**
 * Widget to handle uploading files to Cloudinary CDN.
 *
 * @param {Object} props - Component props
 */
function CloudinaryWidget(props) {
  const cloudinaryWidget = useRef();
  const [loadingWidget, setLoadingWidget] = useState(false);
  const [warningModal, setWarningModal] = useState(false);

  const modalSubtitle = useRef();
  const modalType = useRef();

  const loadWidget = () => {
    cloudinaryWidget.current = window.cloudinary.createUploadWidget(
      {
        cloudName: process.env.REACT_APP_CLOUDINARY_CLOUDNAME,
        uploadPreset: process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET,
        sources:
          props.resourceType === "image" ? ["local", "camera"] : ["local"],
        multiple: false,
        cropping: true,
        resourceType: props.resourceType,
        clientAllowedFormats:
          props.resourceType === "image" ? ["png", "jpeg", "jpg"] : [],
        maxImageFileSize: 6291456,
        croppingAspectRatio: props.minImageHeight
          ? props.minImageWidth / props.minImageHeight
          : undefined,
        croppingShowDimensions: true,
        language: "en",
        text: {
          en: {
            local: {
              dd_title_single: `Drag and drop ${
                props.resourceType === "image" ? "an image" : "a file"
              } here. Ideal dimensions would be ${props.imageDimension}`,
            },
            crop: {
              title: "Crop your image",
              skip_btn: "Continue without cropping",
            },
          },
        },
      },
      (err, result) => {
        if (err) {
          console.log("Error loading upload widget", err);
          if (err.statusText) {
            alert(err.statusText);
          }

          return;
        }

        if (result.info === "shown") {
          setLoadingWidget(false);
        }

        if (result.info === "hidden") {
          setWarningModal(false);
          document.body.style.overflow = "visible";
        }

        if (result.event === "success") {
          props.onFinishAction(result.info.public_id);
        } else if (result.event === "queues-end") {
          // Did the upload succeed?
          if (result.info.files && result.info.files[0]?.failed) {
            // No, upload failed
            // Reset state
            cloudinaryWidget.current.close();
            cloudinaryWidget.current.open();
          }
        }

        return;
      }
    );
  };

  const showUploadWidget = (showModal) => {
    if (showModal) {
      modalSubtitle.current = props.warningModalMessage;
      modalType.current = "upload";
      setWarningModal(true);
      return;
    }
    loadWidget();
    if (cloudinaryWidget.current) {
      setLoadingWidget(true);
      cloudinaryWidget.current.open();
    }
  };

  const handleDeletePhoto = () => {
    modalSubtitle.current = props.deleteModalMessage;
    modalType.current = "delete";
    setWarningModal(true);
  };

  return (
    <>
      {props.allowDelete && (
        <Trash
          className="absolute -top-2 -right-1.5 cursor-pointer w-[25px] h-[25px] p-1 rounded-full !bg-sky-500"
          onClick={handleDeletePhoto}
        />
      )}

      <UploadButton
        onClick={() => {
          showUploadWidget(props.showWarningModal);
        }}
        loadingWidget={loadingWidget}
        {...props}
      />
      {warningModal && (
        <Modal
          isVisible
          title={<span className="text-red-500">Warning!</span>}
          onClose={() => setWarningModal(false)}
          className="max-h-[90vh] border-2 overflow-x-hidden xl:!w-7/12 md:!w-10/12 !w-[95%]"
          subTitle={
            modalSubtitle.current || (
              <>
                <br />
                <span>Are you sure you want to proceed with this action?</span>
              </>
            )
          }
        >
          <div className="w-full flex justify-center items-center">
            {modalType.current === "upload" ? (
              <UploadButton
                onClick={() => showUploadWidget()}
                loadingWidget={loadingWidget}
                {...props}
                className="text-gray-600 hover:text-sky-500 shadow-lg border-b-2 hover:border-sky-500 py-2 px-3 rounded-lg text-center"
              />
            ) : (
              <ActionButton
                action={() => {
                  setWarningModal(false);
                  props.onFinishAction(null, true);
                }}
                title="Delete"
                className="text-gray-600 hover:text-red-500 shadow-lg border-b-2 hover:border-red-500  w-[87px] text-center"
                text="Delete"
                loadingText="Deleting..."
              />
            )}
          </div>
        </Modal>
      )}
    </>
  );
}

export default CloudinaryWidget;
