export const createMatch = /* GraphQL */ `
  mutation CreateMatch($input: CreateMatchInput!) {
    createMatch(input: $input) {
      applicationId
      jobOpportunityId
      isAccepted
      status
      rate {
        value
      }
    }
  }
`;
export const updateMatch = /* GraphQL */ `
  mutation UpdateMatch($input: UpdateMatchInput!) {
    updateMatch(input: $input) {
      applicationId
      jobOpportunityId
      rejectionByCustomer
      isAccepted
      status
      subStatus
      rate {
        value
      }
      customerRate {
        value
      }
    }
  }
`;
export const deleteMatch = /* GraphQL */ `
  mutation DeleteMatch($input: DeleteMatchInput!) {
    deleteMatch(input: $input) {
      applicationId
      jobOpportunityId
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      metadata
      headshotKey
      otherLinks {
        description
        name
        type
        value
        visibility
      }
      resumeLocation
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote($input: CreateNoteInput!) {
    createNote(input: $input) {
      applicationId
      content
      isPublic
      jobOpportunityId
      userId
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
    }
  }
`;
export const getAutoMatches = /* GraphQL */ `
  mutation GetAutoMatches($input: GetAutoMatchesInput!) {
    getAutoMatches(input: $input) {
      hits
    }
  }
`;
export const updateJobOpportunity = /* GraphQL */ `
  mutation UpdateJobOpportunity($input: UpdateJobOpportunityInput!) {
    updateJobOpportunity(input: $input) {
      id
      torcOwner {
        id
        username
      }
    }
  }
`;

export const createSharedAssessment = /* GraphQL */ `
  mutation CreateSharedAssessment($input: createSharedAssessmentInput!) {
    createSharedAssessment(input: $input) {
      message
    }
  }
`;
