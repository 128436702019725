import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";

import { formatTalentSource } from "components/UserCardList/helpers/userCard";

const MatchedDetails = ({ hit, match }) => {
  if (!match?.applicationId) {
    return null;
  }

  const matchCustomerRateValue = match?.customerRate?.value;
  const matchRateValue = match?.rate?.value;
  return (
    <div className="grid grid-cols-2 gap-3 text-sm">
      <p>
        <span className="font-bold ">By: </span>
        {match.updater}
      </p>

      <p>
        <span className="font-bold">Date: </span>
        {`${dayjs(match.updatedAt).format("MM/DD/YYYY")}`}
      </p>

      {hit.talentSource && (
        <p>
          <span className="font-bold">Talent Source: </span>
          <span title="Talent markup rounded to 2 decimal places">
            {formatTalentSource(hit)}
          </span>
        </p>
      )}

      {match.availableStartDate && (
        <p>
          <span className="font-bold">Available start date:</span>{" "}
          {`${dayjs(match.availableStartDate).format("MM/DD/YYYY")}`}
        </p>
      )}

      {matchRateValue && (
        <p>
          <span className="font-bold">Desired rate: </span>${matchRateValue}
        </p>
      )}

      {matchCustomerRateValue > 0 && (
        <p>
          <span className="font-bold">Customer rate: </span>$
          {matchCustomerRateValue}
        </p>
      )}

      {matchCustomerRateValue > 0 && matchRateValue && (
        <p>
          <span className="font-bold">Margin: </span>
          {Math.round(
            ((matchCustomerRateValue - matchRateValue) /
              matchCustomerRateValue) *
              100
          ).toFixed(2)}
          %
        </p>
      )}
    </div>
  );
};

MatchedDetails.propTypes = {
  hit: PropTypes.object,
  match: PropTypes.object,
};

MatchedDetails.defaultProps = {
  hit: {},
  match: {},
};

export default MatchedDetails;
