import React, { useContext, useMemo, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useLocation } from "react-router-dom-v5-compat";

import {
  JOB_APPLICATION_MATCH_STATUS,
  JOB_APPLICATION_MATCH_SUB_STATUS,
  JOB_OPPORTUNITY_STATUSES,
} from "lookup";
import { getCardClassName } from "components/UserCardList/helpers/userCard";

import {
  JobsContext,
  MatchContext,
  ModalContext,
  SearchContext,
} from "context/providers";

import {
  HitActionButtons,
  CollapsibleSection,
  MatchActionButtons,
  MatchedDetails,
  Status,
  UserContacts,
  UserInfo,
} from "./molecules";
import { TABS } from "context/constants/common";
import { getUserName } from "utils/helpers/users";

const UserCardResult = ({
  activeTabName,
  hit,
  showActions,
  showStatusColor,
  collectionKey,
}) => {
  const { hitsMatchAnalysis } = useContext(SearchContext);
  const { showModal } = useContext(ModalContext);
  const { createMatch, updateMatch, removeMatch } = useContext(MatchContext);
  const { jobOpp } = useContext(JobsContext);

  const location = useLocation();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const currentTab = params.get("tab");

  const [isLoading, setIsLoading] = useState({
    [JOB_APPLICATION_MATCH_STATUS.MATCHED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SHORTLISTED]: false,
    [JOB_APPLICATION_MATCH_STATUS.SKIPPED]: false,
    [JOB_APPLICATION_MATCH_STATUS.DELETED]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST]: false,
    [JOB_APPLICATION_MATCH_SUB_STATUS.NULL]: false,
    [JOB_APPLICATION_MATCH_STATUS.REJECTEDBYMATCHER]: false,
    [JOB_APPLICATION_MATCH_STATUS.APPLIED]: false,
    isCalibration: false,
  });

  const { applicationId, jobOpportunityId } = useMemo(() => {
    const app = hit.applications?.find(
      (a) => a.jobTypeId === jobOpp.jobType?.id
    );

    return {
      applicationId:
        hit?.match?.applicationId ||
        hit?.associatedMatch?.applicationId ||
        app?.id ||
        `User#${hit.id}`,
      jobOpportunityId: jobOpp?.id,
    };
  }, [hit, jobOpp]);

  const handleMatchAction = async (args, isUpdate) => {
    let attrs = {};

    if (hit.associatedMatch) {
      if (hit.associatedMatch.localCustomerSummary) {
        attrs.customerSummary = hit.associatedMatch.localCustomerSummary;
      }

      if (hit.associatedMatch.localAnalysis) {
        attrs.analysis = { ...hit.associatedMatch.localAnalysis };
      }
    } else if (hitsMatchAnalysis[hit?.id]) {
      attrs = {
        ...attrs,
        ...hitsMatchAnalysis[hit?.id],
      };
    }

    if (args.status === JOB_APPLICATION_MATCH_STATUS.MATCHED) {
      // passing the value to the modal so it can be viewed in the modal
      if (hit.associatedMatch) {
        if (
          hit.associatedMatch.customerSummary ||
          hit.associatedMatch.localCustomerSummary
        ) {
          attrs.customerSummary =
            hit.associatedMatch.customerSummary ||
            hit.associatedMatch.localCustomerSummary;
        }

        if (hit.associatedMatch.analysis || hit.associatedMatch.localAnalysis) {
          attrs.analysis = {
            score:
              hit.associatedMatch?.analysis?.score ??
              hit.associatedMatch?.localAnalysis?.score,
            report:
              hit.associatedMatch?.analysis?.report ||
              hit.associatedMatch?.localAnalysis?.report,
          };
        }
      }

      showModal({
        type: "match",
        applicationId,
        jobOpportunityId,
        userId: hit.id,
        userIsUnclaimed: hit.isUnclaimed,
        collectionKey,
        status: args.status,
        isUpdate,
        args: { ...attrs },
      });
      return;
    }

    const matchAttrs = { ...args, ...attrs };

    if (args.isCalibration) {
      showModal({
        type: "calibration",
        applicationId,
        jobOpportunityId,
        userId: hit.id,
        userIsUnclaimed: hit.isUnclaimed,
        collectionKey,
        status: args.status,
        isCalibration: args.isCalibration,
        isUpdate,
        args: { ...attrs },
      });
      return;
    }
    setIsLoading((prev) => ({ ...prev, [args.status]: true }));

    if (hit.isUnclaimed) {
      args.unclaimedWhenMatched = true;
    }

    try {
      if (isUpdate) {
        await updateMatch(applicationId, jobOpportunityId, matchAttrs);
      } else {
        await createMatch(applicationId, jobOpportunityId, hit.id, matchAttrs);
      }
    } catch (error) {
      console.log("matchAction error: ", error);
    } finally {
      setIsLoading((prev) => ({ ...prev, [args.status]: false }));
    }
  };

  const updateExistingMatch = async (args) => {
    let attrs = {};

    if (hit.match) {
      if (hit.match.localCustomerSummary) {
        attrs.customerSummary = hit.match.localCustomerSummary;
      }

      if (hit.match.localAnalysis) {
        attrs.analysis = { ...hit.match.localAnalysis };
      }
    }

    if (args?.status === JOB_APPLICATION_MATCH_STATUS.MATCHED) {
      // passing the value to the modal so it can be viewed in the modal
      if (hit.match) {
        if (hit.match.customerSummary || hit.match.localCustomerSummary) {
          attrs.customerSummary =
            hit.match.customerSummary || hit.match.localCustomerSummary;
        }

        if (hit.match.analysis?.report || hit.match.localAnalysis) {
          attrs.analysis = {
            score:
              hit.match?.analysis?.score ?? hit.match?.localAnalysis?.score,
            report:
              hit.match?.analysis?.report || hit.match?.localAnalysis?.report,
          };
        }
      }

      showModal({
        type: "match",
        applicationId,
        jobOpportunityId,
        collectionKey,
        status: args?.status,
        hitStatus: hit.match?.status,
        userId: hit.match?.userId || hit.id,
        isUpdate: true,
        args: { ...attrs },
      });
      return;
    }

    if (args?.subStatus === JOB_APPLICATION_MATCH_SUB_STATUS.FINALIST) {
      showModal({
        type: "matchAnalysis",
        applicationId,
        jobOpportunityId,
        collectionKey,
        subStatus: args?.subStatus,
        isUpdate: true,
      });
      return;
    }

    if (args.isCalibration) {
      showModal({
        type: "calibration",
        applicationId,
        jobOpportunityId,
        collectionKey,
        isCalibration: args.isCalibration,
        isUpdate: true,
        args: { ...attrs },
      });
      return;
    }
    setIsLoading((prev) => ({
      ...prev,
      [args.status ||
      (args.subStatus !== undefined ? args.subStatus : "isCalibration")]: true,
    }));

    try {
      await updateMatch(applicationId, jobOpportunityId, args);
    } catch (error) {
      console.log("matchAction error: ", error);
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        [args.status ||
        (args.subStatus !== undefined
          ? args.subStatus
          : "isCalibration")]: false,
      }));
    }
  };

  const removeExistingMatch = async () => {
    const confirmRemove = window.confirm(
      `Are you sure you want to delete ${getUserName(hit)} match?`
    );

    if (confirmRemove) {
      setIsLoading((prev) => ({
        ...prev,
        [JOB_APPLICATION_MATCH_STATUS.DELETED]: true,
      }));

      try {
        await removeMatch(applicationId, jobOpportunityId);
      } catch (error) {
        console.log("removeExistingMatch error: ", error);
      } finally {
        setIsLoading((prev) => ({
          ...prev,
          [JOB_APPLICATION_MATCH_STATUS.DELETED]: false,
        }));
      }
    }
  };

  const isPreShortListed =
    hit?.associatedMatch?.status ===
      JOB_APPLICATION_MATCH_STATUS.PRESHORTLISTED ||
    hit?.match?.status === JOB_APPLICATION_MATCH_STATUS.PRESHORTLISTED;

  return (
    <div
      className={classNames("rounded-t-lg flex flex-col bg-white font-rubik", {
        ...getCardClassName(hit, showStatusColor),
      })}
    >
      <div className="rounded-t-lg flex flex-wrap md:flex-nowrap border border-[#E5E7EB] p-4 box-border w-full gap-x-2">
        <div>
          <UserContacts hit={hit} />
        </div>

        <div className="w-full lg:w-[85%] md:w-3/4 xl:w-full">
          <UserInfo
            hit={hit}
            collectionKey={collectionKey}
            activeTabName={activeTabName}
          />
          <div className="max-w-fit lg:max-w-[85%] xl:max-w-2xl 2xl:max-w-4xl">
            {jobOpp && (
              <CollapsibleSection
                activeTabName={activeTabName}
                hit={hit}
                allowLocalHightLight={
                  !currentTab || currentTab === TABS.TOPCANDIDATES
                }
              />
            )}
          </div>
        </div>
      </div>

      <div className="rounded-b-lg border-t border-grey-900 py-3 bg-gray-50">
        {(hit.associatedMatch || hit?.match?.applicationId) &&
          !isPreShortListed && (
            <>
              <div className="pl-[148px]">
                {hit?.match?.applicationId && (
                  <p className="mb-3">Matched Details</p>
                )}
                <div className="flex items-start mb-3 gap-3">
                  <div className="grid grid-cols-1 gap-3">
                    {(hit.match?.isCalibration ||
                      hit.associatedMatch?.isCalibration) && (
                      <div
                        className={classNames(
                          "flex justify-center w-min px-2 items-center text-sm font-bold font-rubik text-grey-900 whitespace-nowrap border-l-4",
                          "border-l-4 border-blue-300"
                        )}
                      >
                        <p>Calibration</p>
                      </div>
                    )}
                  </div>
                  {hit?.match?.applicationId &&
                    hit.match?.calibrationRate?.value > 0 && (
                      <p className="text-sm">
                        <span className="font-bold">Calibration rate: </span>$
                        {hit.match?.calibrationRate?.value}
                      </p>
                    )}
                </div>
                <div className="flex items-start gap-3">
                  <div className="grid grid-cols-1 gap-3">
                    <Status
                      matchStatus={
                        hit?.match?.applicationId
                          ? hit.match?.status
                          : hit.associatedMatch.status
                      }
                    />
                    <Status
                      matchStatus={
                        hit?.match?.applicationId
                          ? hit.match?.subStatus
                          : hit.associatedMatch.subStatus
                      }
                    />
                  </div>
                  {hit?.match?.applicationId && (
                    <MatchedDetails hit={hit} match={hit.match} />
                  )}
                </div>
              </div>

              <hr className="border-t border-grey-900 my-6" />
            </>
          )}

        <div className="flex justify-center min-h-[45px]">
          {hit?.match?.applicationId ? (
            <MatchActionButtons
              match={hit.match}
              isLoading={isLoading}
              isJobStatusActive={
                jobOpp.status === JOB_OPPORTUNITY_STATUSES.ACTIVE
              }
              activeTabName={activeTabName}
              updateMatch={updateExistingMatch}
              removeMatch={removeExistingMatch}
              hasPrimaryMatcher={jobOpp.torcOwner?.username}
            />
          ) : (
            <HitActionButtons
              hit={hit}
              isLoading={isLoading}
              showActions={showActions}
              matchAction={handleMatchAction}
              jobType={jobOpp.jobType}
              isJobStatusActive={
                jobOpp.status === JOB_OPPORTUNITY_STATUSES.ACTIVE
              }
              hasPrimaryMatcher={jobOpp.torcOwner?.username}
            />
          )}
        </div>
      </div>
    </div>
  );
};

UserCardResult.propTypes = {
  hit: PropTypes.object.isRequired,
  showActions: PropTypes.bool,
  showStatusColor: PropTypes.bool,
  collectionKey: PropTypes.string,
  activeTabName: PropTypes.string,
};

UserCardResult.defaultProps = {
  showActions: false,
  showStatusColor: false,
  collectionKey: "hits",
  activeTabName: "TOPCANDIDATES",
};

export default UserCardResult;
