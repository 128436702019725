import React, { useEffect, useState } from "react";

const TextContainer = ({ text, maxLength, dangerously }) => {
  const [showFullText, setShowFullText] = useState(dangerously || false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  useEffect(() => {
    if (dangerously && !showFullText) {
      setShowFullText((prev) => !prev);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dangerously]);

  const displayText = showFullText
    ? text
    : text.length > maxLength
    ? text.slice(0, maxLength) + "..."
    : text;

  return (
    <div>
      {dangerously ? (
        <p
          className="prose"
          dangerouslySetInnerHTML={{
            __html: displayText,
          }}
        />
      ) : (
        <p className="whitespace-pre-line">{displayText}</p>
      )}

      {text.length > maxLength && (
        <div
          onClick={toggleText}
          className="cursor-pointer text-blue-600 underline"
        >
          {showFullText ? "Less" : "More"}
        </div>
      )}
    </div>
  );
};

export default TextContainer;
