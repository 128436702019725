import React, { useContext, useEffect, useState } from "react";

import { JobsContext } from "context/providers";

import Modal from "components/Modal";
import Tabs from "components/base/Tabs";
import JobOppDetailsCard from "components/JobOppDetailsCard";
import SimilarJobs from "components/SimilarJobs";

const JobModal = ({ user, initialTabIndex, onClose }) => {
  const {
    jobOpp,
    loadingJobOpp,
    resetSimilarJobOppsFilters,
    clearJob,
    initJob,
  } = useContext(JobsContext);

  const [editJobLinkClicked, setEditJobLinkClicked] = useState(false);

  useEffect(() => {
    if (!loadingJobOpp && jobOpp) {
      resetSimilarJobOppsFilters();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingJobOpp]);

  const callOnClose = () => {
    clearJob(jobOpp.id);
    initJob(jobOpp.id);
  };

  return (
    <div>
      <Modal
        onClose={() => {
          if (editJobLinkClicked) {
            callOnClose();
            // This is unecessary but adds clarity to the behavior
            setEditJobLinkClicked(false);
          }
          onClose();
        }}
        isOpen
      >
        <Tabs
          initialIndex={initialTabIndex}
          headerClassName="!justify-start gap-x-2 px-2 pt-1"
          items={[
            {
              title: "Overview",
              type: "custom",
              renderCustomContent: (activeTabName) => (
                <JobOppDetailsCard
                  key={activeTabName}
                  user={user}
                  setEditJobLinkClicked={setEditJobLinkClicked}
                />
              ),
            },
            {
              title: "AI Assistant",
              type: "custom",
              renderCustomContent: (activeTabName) => (
                <SimilarJobs key={activeTabName} />
              ),
            },
          ]}
        />
      </Modal>
    </div>
  );
};

export default JobModal;
