/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import qs from "query-string";

import { APPLICATION_TAB_NAMES, JOB_APPLICATION_MATCH_STATUS } from "lookup";
import { AuthContext, MatchContext, SearchContext } from "context/providers";
import { mapHits } from "utils/helpers/search";
import { mapMatchesToHit } from "utils/helpers/match";

import UserCardList from "components/UserCardList";
import Tabs from "components/base/Tabs";
import { TABS } from "context/constants/common";

const ApplicationsTabs = () => {
  const { user } = useContext(AuthContext);
  const { matches, bestFit } = useContext(MatchContext);

  const location = useLocation();
  const navigate = useNavigate();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const RenderCustomNoResults = ({ collectionKey }) => {
    const { clearSearchState } = useContext(SearchContext);

    switch (collectionKey) {
      case "hits": {
        return (
          <p>
            There are no applicants to display. Please{" "}
            <span
              className="text-sky-500 cursor-pointer opacity-70 transition-all select-none hover:opacity-100 underline hover:no-underline"
              onClick={clearSearchState}
            >
              Clear Filters
            </span>{" "}
            or change filter criteria.
            <br />
            (start by changing the skills filter to an OR)
          </p>
        );
      }

      default: {
        return <></>;
      }
    }
  };

  const filtersConfig = {
    Matched: {
      excludedMatchStatuses: [
        JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
        JOB_APPLICATION_MATCH_STATUS.PRESHORTLISTED,
        JOB_APPLICATION_MATCH_STATUS.SKIPPED,
        JOB_APPLICATION_MATCH_STATUS.INTERESTED,
        JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK,
      ],
    },
    Shortlisted: {
      matchStatus: [
        JOB_APPLICATION_MATCH_STATUS.SHORTLISTED,
        JOB_APPLICATION_MATCH_STATUS.INTERESTED,
        JOB_APPLICATION_MATCH_STATUS.INTERESTEDFASTTRACK,
      ],
    },
    Skipped: {
      matchStatus: [JOB_APPLICATION_MATCH_STATUS.SKIPPED],
    },
  };

  const hasRejectedValue = (array) => {
    const rejectedValues = [
      "REJECTEDBYCUSTOMER",
      "REJECTEDBYMATCHER",
      "REJECTEDBYMEMBER",
    ];

    return array?.some((item) => rejectedValues?.includes(item?.newValue));
  };

  const getItemsCount = (matchStatus, excludedMatchStatuses, tabName) => {
    switch (tabName) {
      case "Best Fit": {
        return mapHits(bestFit, matches)?.length;
      }
      case "Matched": {
        const matchedMatches = matches
          .filter((match) => match.status === "MATCHED")
          .filter((match) => !hasRejectedValue(match.matchHistory));

        return matchedMatches.length;
      }
      case "Shortlisted":
      case "Skipped": {
        return mapMatchesToHit(
          matches,
          matchStatus,
          false,
          excludedMatchStatuses
        )?.length;
      }

      case "Calibration": {
        return mapMatchesToHit(
          matches,
          matchStatus,
          true,
          excludedMatchStatuses
        )?.length;
      }

      default: {
        return 0;
      }
    }
  };

  const setCount = (item) => {
    const count = getItemsCount(
      item.matchStatus,
      item.excludedMatchStatuses,
      item.title
    );

    return { ...item, count };
  };

  const updateQs = (uniqueId) => {
    const queryString = qs.stringify({
      ...qs.parse(location.search),
      tab: uniqueId,
    });

    navigate(`${location.pathname}?${queryString}`, { replace: true });
  };

  const tabsContent = useMemo(() => {
    const tabs = [
      {
        title: APPLICATION_TAB_NAMES.TOPCANDIDATES,
        icon: "users",
        type: "custom",
        uniqueId: TABS.TOPCANDIDATES,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="hits"
            activeTabName={activeTabName}
            renderCustomNoResults={
              <RenderCustomNoResults collectionKey="hits" />
            }
            customFilter={[
              "hideSkipped",
              "hideRejected",
              "hideMatchedAndApplied",
            ]}
            showGeographicalFilter
            showStatusColor
            showActions
            pagination
            stats
          />
        ),
      },
      {
        title: APPLICATION_TAB_NAMES.MATCHED,
        icon: "heart",
        type: "custom",
        excludedMatchStatuses: filtersConfig["Matched"].excludedMatchStatuses,
        uniqueId: TABS.MATCHED,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="match"
            activeTabName={activeTabName}
            noResultsMessage="There are no Matched applicants to display"
            filter={["status", "subStatus"]}
            excludedMatchStatuses={
              filtersConfig["Matched"].excludedMatchStatuses
            }
            allowSorting
          />
        ),
      },
      {
        title: APPLICATION_TAB_NAMES.CALIBRATION,
        icon: "calibration",
        type: "custom",
        uniqueId: TABS.CALIBRATION,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="match"
            activeTabName={activeTabName}
            noResultsMessage="There are no Calibrations to display"
          />
        ),
      },
      {
        title: APPLICATION_TAB_NAMES.SHORTLISTED,
        icon: "focus",
        type: "custom",
        uniqueId: TABS.SHORTLISTED,
        matchStatus: filtersConfig["Shortlisted"].matchStatus,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="match"
            activeTabName={activeTabName}
            filter={["status"]}
            noResultsMessage="There are no Shortlisted applicants to display"
            matchStatus={filtersConfig["Shortlisted"].matchStatus}
            allowSorting
          />
        ),
      },
      {
        title: APPLICATION_TAB_NAMES.SKIPPED,
        icon: "skip",
        type: "custom",
        matchStatus: filtersConfig["Skipped"].matchStatus,
        uniqueId: TABS.SKIPPED,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="match"
            activeTabName={activeTabName}
            noResultsMessage="There are no Skipped applicants to display"
            matchStatus={filtersConfig["Skipped"].matchStatus}
            allowSorting
          />
        ),
      },
    ];

    if (process.env.REACT_APP_SEARCH_TOOL_BEST_FIT_ENABLED === "true") {
      tabs.push({
        title: APPLICATION_TAB_NAMES.BESTFIT,
        icon: "calculator",
        type: "custom",
        uniqueId: TABS.BESTFIT,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="bestFit"
            activeTabName={activeTabName}
            noResultsMessage="The system is unable to find a best fit candidate for this job"
            showStatusColor
            showActions
            filterOutHitByMatchStatus={[JOB_APPLICATION_MATCH_STATUS.SKIPPED]}
          />
        ),
      });
    }

    if (user.canFetchIdealCandidates) {
      tabs.push({
        title: APPLICATION_TAB_NAMES.IDEAL,
        icon: "sparkles",
        type: "custom",
        uniqueId: TABS.IDEALFIT,
        renderCustomContent: (activeTabName) => (
          <UserCardList
            key={activeTabName}
            collectionKey="ideal"
            activeTabName={activeTabName}
            noResultsMessage="The system is unable to find ideal candidate(s) for this job"
            showStatusColor
            showActions
            filterOutHitByMatchStatus={[JOB_APPLICATION_MATCH_STATUS.SKIPPED]}
          />
        ),
      });
    }

    return tabs;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.canFetchIdealCandidates]);

  const getInitialIndex = () => {
    let currentTab = params.get("tab");

    if (currentTab) {
      const index = tabsContent.findIndex((tc) => tc.uniqueId === currentTab);

      if (index !== -1) {
        return index;
      }
    }
    return 0;
  };

  return (
    <Tabs
      items={tabsContent.map((item) => setCount(item))}
      className="h-full"
      initialIndex={getInitialIndex()}
      updateQs={updateQs}
    />
  );
};

export default ApplicationsTabs;
