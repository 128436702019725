export const JOB_OPP_LOADED = "JOB_OPP_LOADED";
export const JOB_OPP_CLEARED = "JOB_OPP_CLEARED";
export const JOB_OPPS_LOADING = "JOB_OPPS_LOADING";
export const JOB_OPPS_LOADED = "JOB_OPPS_LOADED";
export const JOB_TYPES_LOADED = "JOB_TYPES_LOADED";
export const COMPANY_NAMES_LOADED = "COMPANY_NAMES_LOADED";
export const JOB_SKILLS_LOADED = "JOB_SKILLS_LODADED";
export const JOB_OPTIONAL_SKILLS_LOADED = "JOB_OPTIONAL_SKILLS_LOADED";
export const COMPANY_FILTER_UPDATED = "COMPANY_FILTER_UPDATED";
export const JOB_TYPE_FILTER_UPDATED = "JOB_TYPE_FILTER_UPDATED";
export const JOB_SKILLS_FILTER_UPDATED = "JOB_SKILLS_FILTER_UPDATED";
export const STATUS_FILTER_UPDATED = "STATUS_FILTER_UPDATED";
export const JOB_FILTERS_CLEARED = "JOB_FILTERS_CLEARED";
export const JOB_OPP_CALENDAR_EVENTS_LOADED = "JOB_OPP_CALENDAR_EVENTS_LOADED";
export const SIMILAR_JOB_OPPS_FILTERS_LOADED =
  "SIMILAR_JOB_OPPS_FILTERS_LOADED";
export const SIMILAR_JOB_OPP_FILTER_CHANGE = "SIMILAR_JOB_OPP_FILTER_CHANGE";
export const TORC_OWNERS_LOADED = "TORC_OWNERS_LOADED";
export const TORC_OWNERS_FILTER_UPDATED = "TORC_OWNERS_FILTER_UPDATED";
export const TITLE_SEARCH_FILTER_UPDATED = "TITLE_SEARCH_FILTER_UPDATED";
export const CUSTOMER_OWNER_FILTER_UPDATED = "CUSTOMER_OWNER_FILTER_UPDATED";
export const GEOGRAPHICAL_REGIONS_FILTER_UPDATED =
  "GEOGRAPHICAL_REGIONS_FILTER_UPDATED";
export const JOB_ID_UPDATED = "JOB_ID_UPDATED";
