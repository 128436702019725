import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { USER_TYPES } from "lookup";

import ImageViewer from "components/ImageViewer";
import classNames from "classnames";
import CloudinaryWidget from "components/CloudinaryWidget";
import { MatchContext, SearchContext } from "context/providers";

const DummyPhoto = () => <div className="w-full h-full bg-[#D9D9D9]" />;

const UserContacts = ({ hit }) => {
  const {
    updateMatchUserFieldLocally,
    matches,
    updateFitFieldLocally,
    bestFit,
  } = useContext(MatchContext);
  const { updateHit } = useContext(SearchContext);

  const [updatingProfilePicture, setUpdatingProfilePicture] = useState(false);

  const saveAttributes = async (attributes) => {
    if (attributes.headshotKey) {
      setUpdatingProfilePicture(true);
    }

    const attributesToUpdate = {
      input: {
        id: hit.id,
        ...attributes,
      },
    };

    try {
      await updateHit(attributesToUpdate);

      if (bestFit.find(({ id }) => id === hit.id)) {
        updateFitFieldLocally(hit.id, "headshotKey", attributes.headshotKey);
      }

      if (matches.length > 0) {
        const applicationId =
          hit.match?.applicationId ||
          matches.find(({ userId }) => userId === hit.id)?.applicationId;

        if (!applicationId) {
          return;
        }

        updateMatchUserFieldLocally(
          applicationId,
          "headshotKey",
          attributes.headshotKey
        );
      }
    } catch (error) {
      console.log("Error when updating hit", error);
    }
  };

  useEffect(() => {
    if (updatingProfilePicture === true) {
      setTimeout(() => {
        setUpdatingProfilePicture(false);
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hit]);

  const renderCustomerTag = () => {
    if (hit.userType !== USER_TYPES.CUSTOMER) {
      return null;
    }
    return (
      <div className="w-[90%] mt-4 flex flex-col gap-y-1">
        <p className="text-center bg-[#0495b7] text-white rounded">Customer</p>
        <p className="text-center truncate">{hit.company}</p>
      </div>
    );
  };

  return (
    <div className="flex flex-col flex-none items-center w-full max-w-[125px]">
      <div className="flex flex-col gap-y-2 p-2">
        <div className="relative w-[100px] h-[100px]">
          <div className="w-full h-full overflow-hidden">
            {hit.headshotKey ? (
              <ImageViewer
                objectKey={hit.headshotKey}
                width={100}
                responsive={false}
              />
            ) : (
              <DummyPhoto />
            )}
          </div>
          {updatingProfilePicture ? (
            <div className="flex items-center justify-center h-full w-full absolute top-0 left-0 opacity-60 bg-white pl-2">
              <p className="text-gray-800 text-sm">Updating photo...</p>
            </div>
          ) : (
            <div
              className={classNames(
                "flex justify-center items-center h-full w-full absolute top-0 left-0 transition-all duration-500 bg-white",
                {
                  "opacity-0 hover:opacity-90": hit.headshotKey,
                  "opacity-60 hover:opacity-90": !hit.headshotKey,
                }
              )}
            >
              <CloudinaryWidget
                onFinishAction={(cloudinaryPublicId, remove) => {
                  if (!cloudinaryPublicId && !remove) {
                    return;
                  }
                  saveAttributes({ headshotKey: cloudinaryPublicId });
                }}
                identifier="profile-pic-upload"
                label={<span className="text-gray-800">Upload photo</span>}
                minImageWidth={317}
                minImageHeight={317}
                imageDimension="317 pixels wide by 317 pixels tall"
                resourceType="image"
                showWarningModal={hit.headshotKey}
                warningModalMessage={
                  <>
                    <br />
                    <span>
                      Are you sure you want to upload profile picture for this
                      user?
                    </span>
                    <br />
                    <span>
                      Uploading a photo will replace current profile picture.
                    </span>
                  </>
                }
                allowDelete={hit.headshotKey}
                deleteModalMessage={
                  <>
                    <br />
                    <span>
                      Are you sure you want to remove the profile picture for
                      this user?
                    </span>
                  </>
                }
              />
            </div>
          )}
        </div>
        <div className="w-full bg-[#D9D9D9] rounded-full h-2">
          <div
            className={classNames(
              "h-2 rounded-full",
              {
                "bg-red-500":
                  hit.profileCompletion >= 0 && hit.profileCompletion < 50,
              },
              {
                "bg-sky-500":
                  hit.profileCompletion >= 50 && hit.profileCompletion < 100,
              },
              {
                "bg-green-500": hit.profileCompletion >= 100,
              }
            )}
            style={{ width: `${hit.profileCompletion}%` }}
          ></div>
        </div>
        <p
          className={classNames(
            "text-sm",
            {
              "text-red-500":
                hit.profileCompletion >= 0 && hit.profileCompletion < 50,
            },
            {
              "text-sky-500":
                hit.profileCompletion >= 50 && hit.profileCompletion < 100,
            },
            {
              "text-green-500": hit.profileCompletion >= 100,
            }
          )}
        >
          {hit.profileCompletion || 0}% Complete
        </p>
      </div>

      {renderCustomerTag()}
    </div>
  );
};

UserContacts.propTypes = {
  hit: PropTypes.object,
};

UserContacts.defaultProps = {
  hit: {},
};

export default UserContacts;
