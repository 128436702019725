import ApiClient from "./ApiClient";
import NotesAPI from "./Notes";
import MatchAPI from "./Match";
import JobsAPI from "./Jobs";
import UserAPI from "./User";
import CommonAPI from "./Common";
import ProxyAPI from "./Proxy";

export default function apiConstruct({ onError }) {
  const apiClient = new ApiClient({
    onError,
  });

  return {
    apiClient,
    notes: new NotesAPI({ apiClient }),
    match: new MatchAPI({ apiClient }),
    jobs: new JobsAPI({ apiClient }),
    user: new UserAPI({ apiClient }),
    common: new CommonAPI({ apiClient }),
    proxy: new ProxyAPI({ apiClient }),
  };
}
