import { gql } from "@apollo/client";

export const GET_MATCH_METADATA = gql`
  query GetMatchMetadata($applicationId: ID!, $jobOpportunityId: ID!) {
    getMatch(
      applicationId: $applicationId
      jobOpportunityId: $jobOpportunityId
    ) {
      applicationId
      jobOpportunityId
      metadata
    }
  }
`;

export const GET_MATCH_ANALYSIS = gql`
  query GetMatchAnalysis($applicationId: ID!, $jobOpportunityId: ID!) {
    getMatch(
      applicationId: $applicationId
      jobOpportunityId: $jobOpportunityId
    ) {
      applicationId
      jobOpportunityId
      analysis {
        score
        report
      }
      customerSummary
      userId
    }
  }
`;
