import { Auth } from "aws-amplify";

export async function initUser() {
  const user = await Auth.currentUserInfo();

  const auth = await Auth.currentSession();
  const groups = auth.getAccessToken().payload["cognito:groups"] || [];

  user.attributes.groups = groups;

  return user;
}
