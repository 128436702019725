import React, { useState, useMemo } from "react";

import FileCloudinaryWidget from "components/CloudinaryWidget/FileCloudinaryWidget";
import SvgButton from "components/base/SvgButton";
import UploadButton from "components/base/UploadButton";
import DropDownMenu from "components/DropDownMenu";
import Modal from "components/Modals";
import { getUserName } from "utils/helpers/users";

const ResumeUpload = ({ user, save }) => {
  const [resumeDetails, setResumeDetails] = useState({
    resumeFileName: `${user.resumeLocation?.split("/")[2]}.pdf`,
    resumeLocation: user.resumeLocation,
  });

  const [warningModal, setWarningModal] = useState(false);
  const [loadingWidget, setLoadingWidget] = useState(false);

  const handleResumeUpload = async (resumeStorageLocation) => {
    await save({ resumeLocation: resumeStorageLocation });
    setResumeDetails({
      resumeFileName: `${resumeStorageLocation.split("/")[2]}.pdf`,
      resumeLocation: resumeStorageLocation,
    });
  };

  const resumeIsDocx = useMemo(
    () =>
      resumeDetails?.resumeLocation?.endsWith(".docx") ||
      resumeDetails?.resumeLocation?.endsWith(".doc"),
    [resumeDetails]
  );

  const ResumeUploadLink = ({ resumeDetails }) => (
    <a
      className="flex items-center justify-between w-full"
      href={`https://res.cloudinary.com/${
        process.env.REACT_APP_CLOUDINARY_CLOUDNAME
      }/image/upload/v${Date.now()}/${resumeDetails.resumeLocation}.pdf`}
      target="_blank"
      rel="noreferrer noopener"
    >
      <label>Download Resume</label>
      <SvgButton
        className="hover:bg-transparent cursor-default"
        icon="downloadFile"
        tooltip="download resume"
      />
    </a>
  );

  const OriginalFileDownloadLink = ({ resumeDetails }) => (
    <a
      className="flex items-center justify-between w-full cursor-pointer"
      href={`https://res.cloudinary.com/${
        process.env.REACT_APP_CLOUDINARY_CLOUDNAME
      }/raw/upload/v${Date.now()}/${resumeDetails.resumeLocation}`}
      download={resumeDetails.resumeLocation}
    >
      <label className="cursor-pointer">Download Original File</label>
      <SvgButton
        className="hover:bg-transparent cursor-default cursor-pointer"
        icon="downloadFile"
        tooltip="download resume"
      />
    </a>
  );

  const UploadResumeButton = ({ onClick }) => (
    <div className="flex items-center justify-between" onClick={onClick}>
      <label>Upload Resume</label>
      <SvgButton
        className="hover:bg-transparent cursor-default !min-w-[30px] !min-h-[30px]"
        icon="upload"
        tooltip="Upload Resume"
      />
    </div>
  );

  const menuOptions = useMemo(() => {
    const options = [
      <ResumeUploadLink resumeDetails={resumeDetails} />,
      resumeIsDocx && (
        <OriginalFileDownloadLink resumeDetails={resumeDetails} />
      ),
      <UploadResumeButton onClick={() => setWarningModal(true)} />,
    ];

    return options.filter(Boolean);
  }, [resumeDetails, resumeIsDocx]);

  const renderDropDown = resumeDetails?.resumeLocation && !loadingWidget;

  return (
    <>
      {renderDropDown ? (
        <DropDownMenu icon="resumeIcon" options={menuOptions} />
      ) : (
        <FileCloudinaryWidget
          onUpload={handleResumeUpload}
          identifier="resume-upload"
          minImageHeight={517}
          minImageWidth={1839}
          resourceType="aspose"
          fileName={`${getUserName(user)}_resume`}
          icon="upload"
          tooltip="upload resume"
          openWidget={loadingWidget}
          setLoadingWidget={(value) => {
            setLoadingWidget(value);
            setWarningModal(value);
          }}
        />
      )}

      {warningModal && (
        <Modal
          isVisible
          title={<span className="text-red-500">Warning!</span>}
          onClose={() => setWarningModal(false)}
          className="max-h-[90vh] border-2 overflow-x-hidden xl:!w-7/12 md:!w-10/12 !w-[95%]"
          subTitle={
            <>
              <br />
              <span>Are you sure you want to upload resume for this user?</span>
              <br />
              <span>Uploading a file will replace current resume.</span>
            </>
          }
        >
          <div className="w-full flex justify-center items-center">
            <UploadButton
              onClick={() => setLoadingWidget(true)}
              label="Upload Resume"
              loadingWidget={loadingWidget}
              className="text-gray-600 hover:text-sky-500 shadow-lg border-b-2 hover:border-sky-500 py-2 px-3 rounded-lg text-center"
            />
          </div>
        </Modal>
      )}
    </>
  );
};

export default ResumeUpload;
