import React, { useRef } from "react";

const TorcQualityScore = ({ torcQualityStats, torcQualityScore }) => {
  const qualityScore = useRef();
  const parsedStats = useRef();

  if (
    (!torcQualityStats || torcQualityStats === "{}") &&
    torcQualityScore === undefined
  ) {
    return null;
  }

  try {
    if (torcQualityScore !== undefined) {
      qualityScore.current = torcQualityScore;
    } else {
      parsedStats.current = JSON.parse(torcQualityStats);
      qualityScore.current = Object.values(parsedStats.current).reduce(
        (acc, item) => (acc += item.points !== undefined ? item.points : item),
        0
      );
    }
  } catch (err) {
    // invalid json format return
    return null;
  }

  if (qualityScore.current === undefined) {
    return null;
  }

  return (
    <p>
      Match Ready Score:{" "}
      <span className="font-normal">{qualityScore.current}</span>
    </p>
  );
};

export default TorcQualityScore;
