import Base from "./Base.js";

import {
  getMatch,
  listIdealCandidatesForJob,
  getUserMatches,
  generateMatchAnalysis,
} from "api/graphql/queries.js";

import {
  createMatch,
  deleteMatch,
  updateMatch,
  getAutoMatches,
} from "api/graphql/mutations.js";

export default class MatchAPI extends Base {
  get(payload) {
    return this.apiClient.request({ query: getMatch, payload });
  }

  listBestFit(payload) {
    return this.apiClient.request({ query: getAutoMatches, payload });
  }

  listIdealCandidates(payload) {
    return this.apiClient.request({
      query: listIdealCandidatesForJob,
      payload,
    });
  }

  create(payload) {
    return this.apiClient.request({ query: createMatch, payload });
  }

  update(payload) {
    return this.apiClient.request({ query: updateMatch, payload });
  }

  delete(payload) {
    return this.apiClient.request({ query: deleteMatch, payload });
  }

  async getMatchesForUser(payload) {
    let matches = [];
    let nextToken = null;

    do {
      const response = await this.apiClient.request({
        query: getUserMatches,
        payload: {
          userId: payload.userId,
          nextToken,
        },
      });

      nextToken = response.data.getUserMatches.nextToken;
      const newMatches = response.data.getUserMatches.items;

      matches.push(...newMatches);
    } while (nextToken);

    return matches;
  }

  generateMatchAnalysis(payload) {
    return this.apiClient.request({ query: generateMatchAnalysis, payload });
  }
}
