/**
 * Wrapper over <Metadata /> component - to fetch latest tags on a Match record
 */
import { useSuspenseQuery } from "@apollo/client";
import { GET_MATCH_METADATA } from "api/graphql/match";
import Metadata from "../../UserInfoModal/Metadata";

export default function MatchMetaData({ applicationId, jobOpportunityId }) {
  const { error, data } = useSuspenseQuery(GET_MATCH_METADATA, {
    variables: {
      applicationId,
      jobOpportunityId,
    },
    fetchPolicy: "network-only",
  });

  if (error) {
    return (
      <p>
        An error occurred fetching meta data for the Match. Refresh the page and
        if the error persists, contact Torc Support
      </p>
    );
  }

  return (
    <Metadata user={data.getMatch} target="Match" updateLocalStates={false} />
  );
}
